var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "terminalParameter" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "operationForm",
          attrs: {
            model: _vm.form,
            "label-width": "210px",
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          _vm.isBatch
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c("h4", [_vm._v("选择车辆")])
              ])
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-col",
                { attrs: { md: 12, lg: 12, xl: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司车队：",
                        prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                      }
                    },
                    [
                      _c("companySelectForSearch", {
                        ref: "companySelectForSearch",
                        attrs: { multiple: true, searchable: true },
                        on: { getValue: _vm.getGroupIds }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-col",
                { attrs: { md: 12, lg: 12, xl: 8 } },
                [
                  _vm.isBatch
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择车辆：",
                            prop:
                              _vm.form.companyIds.length === 0 ? "vehIds" : ""
                          }
                        },
                        [_c("car-tree", { on: { getData: _vm.getVehIds } })],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h4", [_vm._v("主服务器配置")])
          ]),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "主服务器IP地址：", prop: "mainServerIP" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mainServerIP,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "mainServerIP",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.mainServerIP"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "主服务器TCP端口号：", prop: "mainServerTcp" }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mainServerTcp,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "mainServerTcp",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.mainServerTcp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h4", [_vm._v("备份服务器配置")])
          ]),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "备份服务器IP地址：", prop: "backupServerIP" }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.backupServerIP,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "backupServerIP",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.backupServerIP"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备份服务器TCP端口号：",
                    prop: "backupServerTcp"
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.backupServerTcp,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "backupServerTcp",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.backupServerTcp"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.saveInput }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }