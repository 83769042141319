<template>
  <div class="lins">
    <videoList
      ref="flvPlayModule"
      :buttonValue="buttonValue"
      @showTips="showTips"
    ></videoList>
    <div class="mengceng">
      <div>
        <img :src="show?imgPng:imgJpg" alt="">
      </div>
      <div class="tips" v-show="isShow">{{show?'设备链接中，请等待...':'正在监听...'}}</div>
      <div class="btns">
        <el-button type="primary" size="small" @click="open" :disabled="isShow">开始</el-button>
        <el-button type="default" size="small" @click="close">结束</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import videoList from '@/views/monitor/monitoring/realVideo/videoList'

export default {
  components: {
    videoList
  },
  data () {
    return {
      buttonValue: 0,
      show:true,
      isShow:false,
      imgPng:require('../../assets/images/video/listen.png'),
      imgJpg:require('../../assets/images/video/listen.gif')
    }
  },
  props:{
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  methods:{
    showTips(f){
      this.show=f;
    },
    open(){
      this.isShow = true;
      this.$yhsdp.GBRealVideoStreamReq(this.settingValue);
      this.buttonValue = 1;
      this.$refs.flvPlayModule.playVideo([this.settingValue]);
    },
    close(){
      this.isShow = false;
      this.show = true;
      this.$refs.flvPlayModule.flv_destroy(1,true)
    }
  },
  mounted(){
  },
  beforeDestroy(){
    this.$refs.flvPlayModule.flv_destroy(1,true)
    //退出视频播放
  }
}
</script>
<style lang="scss" scoped>
.lins {
  width: 100%;
  height: 25vh;
  margin: 5px 0;
  position: relative;
  .mengceng{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    z-index: 2000;
    text-align: center;
    background:#fff;
  }
  .tips{
    margin-top:20px;
  }
  .btns{
    margin-top: 40px;
  }
}
</style>