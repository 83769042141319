var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "blind"
    },
    [
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 激烈驾驶报警配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.commit }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm,
            inline: true,
            "label-width": "190px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn0,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn0", $$v)
                  },
                  expression: "addForm.turn0"
                }
              }),
              _vm._v("急加速报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "急加速报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.upTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "upTime", $$v)
                  },
                  expression: "addForm.upTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "急加速报警重力加速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.mgSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "mgSpeed", $$v)
                  },
                  expression: "addForm.mgSpeed"
                }
              }),
              _vm._v("1/100g ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn1", $$v)
                  },
                  expression: "addForm.turn1"
                }
              }),
              _vm._v("急减速报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "急减速报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.downTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "downTime", $$v)
                  },
                  expression: "addForm.downTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "急减速报警重力加速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.downSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "downSpeed", $$v)
                  },
                  expression: "addForm.downSpeed"
                }
              }),
              _vm._v("1/100g ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn2", $$v)
                  },
                  expression: "addForm.turn2"
                }
              }),
              _vm._v(" 急转弯报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "急转弯报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.tTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "tTime", $$v)
                  },
                  expression: "addForm.tTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "急转弯报警重力加速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.tSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "tSpeed", $$v)
                  },
                  expression: "addForm.tSpeed"
                }
              }),
              _vm._v("1/100g ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn3,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn3", $$v)
                  },
                  expression: "addForm.turn3"
                }
              }),
              _vm._v(" 怠速报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "怠速报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 600,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.dTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "dTime", $$v)
                  },
                  expression: "addForm.dTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "怠速报警车速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 30,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.dSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "dSpeed", $$v)
                  },
                  expression: "addForm.dSpeed"
                }
              }),
              _vm._v("km/h ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "怠速报警发动机转速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 2000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.dAlarm,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "dAlarm", $$v)
                  },
                  expression: "addForm.dAlarm"
                }
              }),
              _vm._v("RPM ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn4,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn4", $$v)
                  },
                  expression: "addForm.turn4"
                }
              }),
              _vm._v(" 异常熄火报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "异常熄火报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 30,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.xTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "xTime", $$v)
                  },
                  expression: "addForm.xTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "异常熄火报警车速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 200,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.xSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "xSpeed", $$v)
                  },
                  expression: "addForm.xSpeed"
                }
              }),
              _vm._v("km/h ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "异常熄火报警发动机转速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 1000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.xAlarm,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "xAlarm", $$v)
                  },
                  expression: "addForm.xAlarm"
                }
              }),
              _vm._v("RPM ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn5,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn5", $$v)
                  },
                  expression: "addForm.turn5"
                }
              }),
              _vm._v(" 空挡滑行报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "空挡滑行报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 30,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.kTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "kTime", $$v)
                  },
                  expression: "addForm.kTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "空挡滑行报警车速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 200,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.kSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "kSpeed", $$v)
                  },
                  expression: "addForm.kSpeed"
                }
              }),
              _vm._v("km/h ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "空挡滑行报警发动机转速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 2000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.kAlarm,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "kAlarm", $$v)
                  },
                  expression: "addForm.kAlarm"
                }
              }),
              _vm._v("RPM ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.turn6,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "turn6", $$v)
                  },
                  expression: "addForm.turn6"
                }
              }),
              _vm._v(" 发动机超转报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "发动机超转报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.fTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "fTime", $$v)
                  },
                  expression: "addForm.fTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发动机超转报警车速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 200,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.fSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "fSpeed", $$v)
                  },
                  expression: "addForm.fSpeed"
                }
              }),
              _vm._v("km/h ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警发动机转速阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1000,
                  max: 6000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.fAlarm,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "fAlarm", $$v)
                  },
                  expression: "addForm.fAlarm"
                }
              }),
              _vm._v("RPM ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }