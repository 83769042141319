<template>
  <div class="area-alarmSetting">
    <div style="text-align: center">
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="value"
        :titles="['可选配置', '已选配置']"
        :data="optionData"
        :render-content="renderFunc"
      ></el-transfer>
    </div>
    <div class="footer-btn">
      <el-button size="small" @click="cancle()">取消</el-button>
      <el-button type="primary" size="small" @click="save()">保存</el-button>
    </div>
  </div>
</template>
<script>
import {
  getVehicleConfig,
  updateVehicleConfig
} from '@/api/lib/api.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      value: [],
      optionData: [],
    }
  },

  mounted () {
    this.queryConfig();
  },
  methods: {
    renderFunc(h,option){
      return <span title={option.label}>{option.label}</span>
    },
    cancle () {
      this.$emit('close', true)
    },
    save () {
      const data = {
        vehicleId:this.data.id.replace(/v/g, ""),
        selected:this.value
      }
      updateVehicleConfig(data).then(res => {
        if (res.code === 1000) {
          this.$message.success(res.msg)
          this.$emit('close', true)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    queryConfig () {
      const data = {
        vehicleId: this.data.id.replace(/v/g, "")
      }
      getVehicleConfig(data).then(res => {
        if (res.code === 1000) {
          const { optional=[], selected=[] } = res.data;
          this.optionData = optional.map(item=>({
            key: item.id,
            label: item.name
          }));
          this.value = selected.map(item=>item.id);
        }
      }).catch(err => {
        console.log(err)
        this.value = [];
        this.optionData = [];
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.area-alarmSetting {
  max-height: 55vh;
  overflow-y: auto;
}
.footer-btn {
  margin: 16px 20px 0 0;
  text-align: right;
}
</style>
