var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terminal-upgrade" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(" 车牌号： " + _vm._s(_vm.data.name) + " ")
          ]),
          _c("el-col", { attrs: { span: 8 } }, [
            _vm._v(
              " 终端号： " + _vm._s(_vm.formatTelNo(_vm.data.videos)) + " "
            )
          ])
        ],
        1
      ),
      _c("p", { staticClass: "form-title" }, [_vm._v("终端参数")]),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v(
              " 终端型号： " + _vm._s(_vm.settingValue.terminalModel) + " "
            )
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v(" 终端ID： " + _vm._s(_vm.settingValue.terminalID) + " ")
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _vm._v(
              " 终端SIM_ICCID： " + _vm._s(_vm.settingValue.SIM_ICCID) + " "
            )
          ]),
          _c(
            "el-col",
            { attrs: { span: 24, title: _vm.settingValue.hardwareVersion } },
            [
              _vm._v(
                " 硬件版本号： " +
                  _vm._s(_vm.settingValue.hardwareVersion) +
                  " "
              )
            ]
          ),
          _c(
            "el-col",
            { attrs: { span: 24, title: _vm.settingValue.softwareVersion } },
            [
              _vm._v(
                " 固件版本号： " +
                  _vm._s(_vm.settingValue.softwareVersion) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            rules: _vm.formRules,
            "label-width": "96px",
            "label-position": "right"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择升级包：", prop: "path" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.path,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "path", $$v)
                    },
                    expression: "addForm.path"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.path }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生产厂家：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "通立", value: 1 } }),
                  _c("el-option", { attrs: { label: "通联", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", "label-width": "10px" } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "tooltip",
                  attrs: {
                    effect: "dark",
                    content: "只支持通立和通联，其他产品请联系工作人员",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancle } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.save }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }