<template>
  <div class="terminal-ip">
    <el-form
      ref="form"
      :model="form"
      label-width="90px"
      label-position="left"
      :inline="true"
      size="small"
      class="dialog-form terminal-form"
    >
      <el-form-item label="车牌号：">{{ data.name }}</el-form-item>
      <el-form-item label="终端号：">{{ formatTelNo(data.videos) }}</el-form-item>
      <el-form-item label="摄像头：">
        <el-button
          type="text"
          size="small"
          v-for="i of videoNumber"
          :sort="channel === i ? '' : 'grey'"
          :key="i"
          @click="handleChannelClick(i)"
        >
          <i class="iconfont its_shexiangtou"></i>
          0{{ i }}
        </el-button>
      </el-form-item>
      <el-form-item label="图像质量：">
        <el-slider v-model="value1" :max="10" style="width:280px;"></el-slider>
      </el-form-item>
      <el-form-item label="拍摄数量：">
        <el-input-number
          controls-position="right"
          v-model="form.num"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <span>(0-10，0表示停止拍摄)</span>
      </el-form-item>
      <el-form-item label="图像亮度：">
        <el-slider v-model="value2" :max="255" style="width:280px;"></el-slider>
      </el-form-item>
      <el-form-item label="拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="form.interval"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
        <span>秒(0表示按最小间隔)</span>
      </el-form-item>
      <el-form-item label="对比度：">
        <el-slider v-model="value3" :max="127" style="width:280px;"></el-slider>
      </el-form-item>
      <el-form-item label="保存标志：">
        <el-select v-model="form.saveLog" placeholder="请选择">
          <el-option
            v-for="item in saveList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="饱和度：">
        <el-slider v-model="value4" :max="127" style="width:280px;"></el-slider>
      </el-form-item>
      <el-form-item label="图像分辨率：">
        <el-select v-model="form.distinguish" placeholder="请选择">
          <el-option
            v-for="item in reportList"
            :label="item.dictValue"
            :value="item.dictValue"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="色度：">
        <el-slider v-model="value5" :max="255" style="width:280px;"></el-slider>
      </el-form-item>
      <el-form-item label="发送指令：">
        <el-button type="primary" size="mini" @click="saverealTimePhoto">发送指令</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  returnVideoArr
} from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  data () {
    return {
      videoNumber: 0,//摄像头个数
      channel: 0,//通道号
      form: {
        num: 1,//拍照数量
        interval: 0,//拍照间隔
        saveLog: 'true',//保存标志
        distinguish: '最低分辨率',//分辨率
      },
      value1: 1,//图像相关假数据
      value2: 70,
      value3: 87,
      value4: 78,
      value5: 184,
      terminalNos: [],
      deviceType: sessionStorage.getItem('system'),
      reportList: [
        { dictValue: '最低分辨率', dictCode: '最低分辨率' },
        { dictValue: '320*240', dictCode: 0x01 },
        { dictValue: '640*480', dictCode: 0x02 },
        { dictValue: '800*600', dictCode: 0x03 },
        { dictValue: '1024*768', dictCode: 0x04 },
        { dictValue: '176*144[Qcif]', dictCode: 0x05 },
        { dictValue: '352*288[Cif]', dictCode: 0x06 },
        { dictValue: '704*288[HALF DI]', dictCode: 0x07 },
        { dictValue: '704*576[DI]', dictCode: 0x08 },
        { dictValue: '最高分辨率', dictCode: 0xff }
      ],
      saveList: [
        { dictValue: '实时上传', dictCode: 'true' },
        { dictValue: '保存', dictCode: 'false' }
      ],
      vehicleInfos: [],
    }
  },

  methods: {
    handleChannelClick (index) {
      this.channel = index;
    },
    saverealTimePhoto () {
      if (!this.channel) {
        this.$message.warning('请选择拍照的摄像头！')
        return
      }
      const { num, interval, saveLog, distinguish } = this.form
      const params = [this.channel, num, interval, saveLog, distinguish,this.value1];
      const terminalNos = []
      const vehicleInfos = []
      let ids = this.data.id;
      queryDeviceByIds({
        ids,
        system: this.deviceType
      }).then(res => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.vehicleInfos = vehicleInfos
          this.$yhsdp.GBRealTimeTakePictures(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          this.$emit('close')
        }else{
          this.$message.warning('车辆没有绑定终端!')
        }
      })

    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.terminalNos = [this.formatTelNo(this.data.videos)]
      this.videoNumber = Number(returnVideoArr(this.data.videos)) || 0 ///摄像头个数
    }
  }
}
</script>
<style lang="scss" scoped>
.terminal-ip {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
