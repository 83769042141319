<template>
  <div class="terminal-ip">
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      size="small"
      :rules="formRules"
      class="dialog-form terminal-form"
    >
      <el-form-item label="车牌号：" v-if="!isBatch">
        {{ data.name }}
      </el-form-item>
      <el-form-item label="终端号：" v-if="!isBatch">
        {{ formatTelNo(data.videos) }}
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <!-- 选择车辆： -->
      <!--  -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
    </el-form>
    <!--  服务器配置-->
    <p class="form-title form-title2">
      服务器配置
      <el-button type="primary" size="mini" @click="saveInput"
        >发送指令</el-button
      >
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      ref="addForm2"
      :rules="formRules"
      label-width="120px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="心跳间隔(秒)：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.intervalTime"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="主服务器APN：">
        <el-input v-model="addForm2.apnSet" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="主服务器IP地址：">
        <el-input v-model="addForm2.serverIp" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="主服务器端口：">
        <el-input
          v-model="addForm2.serverTcpPort"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 运营汇报策略配置 -->
    <p class="form-title form-title2">
      运营汇报策略配置
      <el-button type="primary" size="mini" @click="saveConfiguration"
        >发送指令</el-button
      >
    </p>
    <el-form
      :model="addForm3"
      :inline="true"
      ref="addForm3"
      :rules="formRules2"
      label-width="190px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="位置汇报策略：">
        <el-select v-model="addForm3.report" clearable placeholder="请选择">
            <el-option
              v-for="item in reportList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="位置汇报方案：">
        <el-select v-model="addForm3.plan" clearable placeholder="请选择">
            <el-option
              v-for="item in planList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="缺省汇报时间间隔（秒）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.noReportTime"
          :min="0"
          :max="120"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="休眠汇报时间间隔（秒）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.dormancyReportTime"
          :min="0"
          :max="120"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="缺省汇报距离间隔（米）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.noReportDistance"
          :min="0"
          :max="10000"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="休眠汇报距离间隔（米）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.dormancyReportDistance"
          :min="0"
          :max="10000"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="司机未登签汇报间隔（秒）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.noSignTime"
          :min="0"
          :max="120"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="司机未登签距离间隔（米）：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.noSignDistance"
          :min="0"
          :max="10000"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import {
  returnVideoArr,
  checkString,
  checkIpAddress,
  checkPositiveInteger
} from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    //数据回显
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // }
  },

  data () {
    return {
      terminalNos: [],
      vehicleInfos: [],

      form: {
        vehIds: [],
        companyIds: []
      },
      addForm2: {
        intervalTime: '',
        serverIp: '',
        apnSet: '',
        serverTcpPort: ''
      },
      addForm3:{
        report:'',//位置汇报策略
        plan:'',//位置汇报方案
        noReportTime:'',//缺省汇报时间间隔（秒）
        dormancyReportTime:'',//休眠汇报时间间隔（秒）
        noReportDistance:'',//缺省汇报距离间隔（米）
        dormancyReportDistance:'',//休眠汇报距离间隔（米）
        noSignTime:'',//司机未登签汇报间隔（秒）
        noSignDistance:'',//司机未登签距离间隔（米）
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
        apnSet: [{ validator: checkString, trigger: 'blur' }], // 主服务器APN设置
        serverIp: [{ validator: checkIpAddress, trigger: 'blur' }], // 主IP或域名验证
        serverTcpPort: [{ validator: checkPositiveInteger, trigger: 'blur' }] // 主服务器TCP端口设置
      },
      formRules2:{
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
      },
      reportList:[
        {dictValue:'定时',dictCode:0},
        {dictValue:'定距',dictCode:1},
        {dictValue:'定时+定距',dictCode:2}
      ],
      planList:[
        {dictValue:'ACC状态',dictCode:0},
        {dictValue:'登录+ACC',dictCode:1}
      ],
      AlarmPromptOption: ['intervalTime', 'serverIp', 'apnSet', 'serverTcpPort'],
      OperatingOption: ['report', 'plan', 'noReportTime', 'dormancyReportTime','noReportDistance','dormancyReportDistance','noSignTime','noSignDistance']
    }
  },

  methods: {
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.vehicleInfos = []
      this.form.vehIds = vehStrIds
      if (vehStrIds.length > 0)
        this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 获取已存的提示内容 */
    queryDeviceParams (data) {
      queryDeviceParams(data).then((res) => {
        if (res.code === 1000) {
          this.addForm2 = { ...this.addForm2, ...res.data }
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.addForm2.validate((valid) => {
            if (valid) {
              let parameter = 0
              this.AlarmPromptOption.forEach((item) => {
                if (
                  this.addForm2[item] === null ||
                  this.addForm2[item] === undefined ||
                  this.addForm2[item] === 'undefined' ||
                  this.addForm2[item] === ''
                ) {
                  parameter = parameter + 1
                }
              })
              if (parameter === this.AlarmPromptOption.length) {
                this.$message.warning('请至少下发一个指令')
                return
              }
              const terminalNos = []
              const vehicleInfos = []
              let ids = this.form.companyIds.concat(this.form.vehIds)

              queryDeviceByIds({
                ids: ids.join(','),
                system: this.deviceType
              }).then((res) => {
                if (res.code === 1000) {
                  if (res.data.length === 0) {
                    this.$message.warning('车辆没有绑定终端')
                    return
                  }
                  res.data.forEach((element) => {
                    let deviceNo = returnVideoArr(element.videos, true)
                    terminalNos.push(deviceNo)
                    vehicleInfos.push(`${element.cph}#${element.companyName}`)
                  })
                  this.terminalNos = terminalNos
                  this.vehicleInfos = vehicleInfos

                  // if (this.terminalNos.length > this.number) {
                  //   this.$message.warning(
                  //     `最多只能对${this.number}个终端批量下发指令`
                  //   )
                  // }
                  const params = []
                  const reqData = {
                    deviceNos: terminalNos, // 下发的终端
                    terminalParams: { ...this.addForm2 } // 报警提示类型
                  }
                  const {
                    intervalTime,
                    serverIp,
                    apnSet,
                    serverTcpPort
                  } = this.addForm2
                  setDeviceParams(reqData).then((res) => { })
                  if (apnSet) params.push(0x0010, apnSet)
                  if (serverIp) params.push(0x0013, serverIp)
                  if (serverTcpPort) params.push(0x0018, serverTcpPort)

                  if (intervalTime === 0 || intervalTime) {
                    params.push(0x0001, intervalTime)
                  }
                  this.$yhsdp.GBSetParameters(
                    [terminalNos, this.vehicleInfos],
                    params,
                    this.deviceType
                  )
                  this.$emit('close')
                }
              })
            }
          })
        }
      })
    },
    /**运营汇报策略配置 */
    saveConfiguration(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.addForm3.validate((valid) => {
            if (valid) {
              let parameter = 0
              this.OperatingOption.forEach((item) => {
                if (
                  this.addForm3[item] === null ||
                  this.addForm3[item] === undefined ||
                  this.addForm3[item] === 'undefined' ||
                  this.addForm3[item] === ''
                ) {
                  parameter = parameter + 1
                }
              })
              if (parameter === this.OperatingOption.length) {
                this.$message.warning('请至少下发一个指令')
                return
              }
              const terminalNos = []
              const vehicleInfos = []
              let ids = this.form.companyIds.concat(this.form.vehIds)
              queryDeviceByIds({
                ids: ids.join(','),
                system: this.deviceType
              }).then((res) => {
                if (res.code === 1000) {
                  if (res.data.length === 0) {
                    this.$message.warning('车辆没有绑定终端')
                    return
                  }
                  res.data.forEach((element) => {
                    let deviceNo = returnVideoArr(element.videos, true)
                    terminalNos.push(deviceNo)
                    vehicleInfos.push(`${element.cph}#${element.companyName}`)
                  })
                  this.terminalNos = terminalNos
                  this.vehicleInfos = vehicleInfos

                  // if (this.terminalNos.length > this.number) {
                  //   this.$message.warning(
                  //     `最多只能对${this.number}个终端批量下发指令`
                  //   )
                  // }
                  const params = []
                  const reqData = {
                    deviceNos: terminalNos, // 下发的终端
                    terminalParams: { ...this.addForm3 } // 报警提示类型
                  }
                  const {
                    report,
                    plan,
                    noReportTime,
                    dormancyReportTime,
                    noReportDistance,
                    dormancyReportDistance,
                    noSignTime,
                    noSignDistance,
                  } = this.addForm3
                  setDeviceParams(reqData).then((res) => { })
                  if(report === 0 || report) params.push(0x0020, report);
                  if(plan === 0 || plan) params.push(0x0021, plan);
                  if(noReportTime === 0 || noReportTime) params.push(0x0029, noReportTime);
                  if(dormancyReportTime === 0 || dormancyReportTime) params.push(0x0027, dormancyReportTime);
                  if(noReportDistance === 0 || noReportDistance) params.push(0x002c, noReportDistance);
                  if(dormancyReportDistance === 0 || dormancyReportDistance) params.push(0x002e, dormancyReportDistance);
                  if(noSignTime === 0 || noSignTime) params.push(0x0022, noSignTime);
                  if(noSignDistance === 0 || noSignDistance) params.push(0x002d, noSignDistance);
                  
                  this.$yhsdp.GBSetParametersConfig(
                    [terminalNos, this.vehicleInfos],
                    params,
                    this.deviceType
                  )
                  this.$emit('close')
                }
              })
            }
          })
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
    }
    if(this.settingValue){
      const {
        report,//运营汇报策略
        plan,
        noReportTime,
        dormancyReportTime,
        noReportDistance,
        dormancyReportDistance,
        noSignTime,
        noSignDistance
      } = this.settingValue
      this.addForm3 = {
        report,//位置汇报策略
        plan,//位置汇报方案
        noReportTime,//缺省汇报时间间隔（秒）
        dormancyReportTime,//休眠汇报时间间隔（秒）
        noReportDistance,//缺省汇报距离间隔（米）
        dormancyReportDistance,//休眠汇报距离间隔（米）
        noSignTime,//司机未登签汇报间隔（秒）
        noSignDistance,//司机未登签距离间隔（米）
      }
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminal-ip {
  height: 55vh;
  overflow-y: auto;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
