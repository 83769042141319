<template>
  <div class="holder-control">
    <!-- 云台控制 -->
    <el-form
      ref="form"
      :model="form"
      label-width="100px"
      label-position="left"
      size="small"
      class="dialog-form"
    >
      <el-form-item label="车牌号：">{{ data.name }}</el-form-item>
      <el-form-item label="终端号：">{{ formatTelNo(data.videos) }}</el-form-item>
      <el-form-item label="摄像头：">
        <el-button
          type="text"
          size="small"
          v-for="i of videoNumber"
          :sort="channel === i ? '' : 'grey'"
          :key="i"
          @click="handleChannelClick(i)"
        >
          <i class="iconfont its_shexiangtou"></i>
          0{{ i }}
        </el-button>
      </el-form-item>
      <el-form-item label="旋转：">
        <div class="rotate">
          <i class="iconfont its_shang" :class="chooseActive===1?'active':''" @click="Rotate(1)"></i>
          <i class="iconfont its_youshang" :class="chooseActive===2?'active':''" @click="Rotate(2)"></i>
          <i class="iconfont its_you1" :class="chooseActive===3?'active':''" @click="Rotate(3)"></i>
          <i class="iconfont its_youxia" :class="chooseActive===4?'active':''" @click="Rotate(4)"></i>
          <i class="iconfont its_xia" :class="chooseActive===5?'active':''" @click="Rotate(5)"></i>
          <i class="iconfont its_zuoxia" :class="chooseActive===6?'active':''" @click="Rotate(6)"></i>
          <i class="iconfont its_zuo1" :class="chooseActive===7?'active':''" @click="Rotate(7)"></i>
          <i class="iconfont its_zuoshang" :class="chooseActive===8?'active':''" @click="Rotate(8)"></i>
          <div class="i-box">
            <i class="iconfont its_xuanzhuan"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="速度值(0-255):">
        <el-input-number
          controls-position="right"
          v-model="speed"
          :min="0"
          :max="255"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="更多操作:">
        <div class="more">
          <div class="item">
            <i class="iconfont its_zengjia" @click="MoreAction('焦距',1)"></i>
            <span>焦距</span>
            <i class="iconfont its_jianshao" @click="MoreAction('焦距',2)"></i>
          </div>
          <div class="item">
            <i class="iconfont its_zengjia" @click="MoreAction('光圈',1)"></i>
            <span>光圈</span>
            <i class="iconfont its_jianshao" @click="MoreAction('光圈',2)"></i>
          </div>
          <div class="item">
            <i class="iconfont its_zengjia" @click="MoreAction('变倍',1)"></i>
            <span>变倍</span>
            <i class="iconfont its_jianshao" @click="MoreAction('变倍',2)"></i>
          </div>
          <div class="item">
            <i class="iconfont its_zuo" @click="MoreAction2('雨刷',3)"></i>
            <span>雨刷</span>
            <i class="iconfont its_1hua" @click="MoreAction2('雨刷',4)"></i>
          </div>
          <div class="item">
            <i class="iconfont its_zuo" @click="MoreAction('红外补光',3)"></i>
            <span>红外补光</span>
            <i class="iconfont its_1hua" @click="MoreAction('红外补光',4)"></i>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  returnVideoArr, formatDate
} from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  data () {
    return {
      speed: 0,
      videoNumber: 0,//摄像头个数
      channel: 0,//通道号
      form: {
        num: 1,//拍照数量
        interval: 2,//拍照间隔
        saveLog: 'true',//保存标志
        distinguish: '最低分辨率',//分辨率
      },
      terminalNos: [],
      deviceType: sessionStorage.getItem('system'),
      reportList: [
        { dictValue: '最低分辨率', dictCode: '最低分辨率' },
        { dictValue: '320*240', dictCode: 0x01 },
        { dictValue: '640*480', dictCode: 0x02 },
        { dictValue: '800*600', dictCode: 0x03 },
        { dictValue: '1024*768', dictCode: 0x04 },
        { dictValue: '176*144[Qcif]', dictCode: 0x05 },
        { dictValue: '352*288[Cif]', dictCode: 0x06 },
        { dictValue: '704*288[HALF DI]', dictCode: 0x07 },
        { dictValue: '704*576[DI]', dictCode: 0x08 },
        { dictValue: '最高分辨率', dictCode: 0xff }
      ],
      saveList: [
        { dictValue: '实时上传', dictCode: 'true' },
        { dictValue: '保存', dictCode: 'false' }
      ],
      vehicleInfos: [],
      chooseActive: 0,
      companyName: '',
    }
  },

  methods: {
    Rotate (index) {
      let title = '云台旋转';
      this.chooseActive = index;
      switch (index) {
        case 1:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：上,速度：${this.speed}`)
          break;
        case 2:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：右,速度：${this.speed}`)
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：上,速度：${this.speed}`)
          break;
        case 3:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：右,速度：${this.speed}`)
          break;
        case 4:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：右,速度：${this.speed}`)
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：下,速度：${this.speed}`)
          break;
        case 5:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：下,速度：${this.speed}`)
          break;
        case 6:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：左,速度：${this.speed}`)
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：下,速度：${this.speed}`)
          break;
        case 7:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：左,速度：${this.speed}`)
          break;
        case 8:
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：左,速度：${this.speed}`)
          this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},旋转方向：上,速度：${this.speed}`)
          break;
      }
    },
    MoreAction(text,index){
      let title =index===3||index===4? `${text}控制`: `云台调整${text}控制`;
      let value = index===1?'调大':index===2?'调小':index===3?'开':'关'
      this.saverealTimePhoto(0, title, `逻辑通道号：${this.channel},${text}：${value}`)
    },
    MoreAction2(text,index){
      let title =index===3||index===4? `${text}控制`: `云台调整${text}控制`;
      let value = index===1?'调大':index===2?'调小':index===3?'开':'关'
      this.saverealTimePhoto(0, title, `${text}：${value}`,true)
    },
    handleChannelClick (index) {
      this.channel = index;
    },
    queryDevice () {
      let ids = this.data.id;
      queryDeviceByIds({
        ids,
        system: this.deviceType
      }).then(res => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            this.companyName = element.companyName
          })
        } else {
          this.$message.warning('车辆没有绑定终端!')
        }
      })
    },
    saverealTimePhoto (type, sCmdTxt, sNote,flag) {
      if(!flag){
        if (!this.channel) {
          this.$message.warning('请选择拍照的摄像头！')
          return
        }
      }
      
      const logData = {
        type,
        param: {
          sCmdTxt:`云台控制_${sCmdTxt},${sNote},`,
          sTime: formatDate(new Date()),
          sVehNo: this.data.name,
          terminalNo: this.formatTelNo(this.data.videos),
          vehGroupName: this.companyName
        }
      }
      this.getInteractionDataOut(logData);
      setTimeout(() => {
        const logData = {
          type: 1,
          param: {
            sCmdTxt:`云台控制_${sCmdTxt},${sNote},`,
            sTime: formatDate(new Date()),
            sVehNo: this.data.name,
            sNote:'应答成功',
            terminalNo: this.formatTelNo(this.data.videos),
            vehGroupName: this.companyName
          }
        }
        this.getInteractionDataOut(logData)
      }, 1500)
      this.$emit('close')
    },
    /** 指令信息 */
    getInteractionDataOut (data) {
      switch (data.type) {
        case 0:
          this.$store.commit('add_interactiveArr', {
            status: 0,
            ...data.param,
            sNote: ''
          })
          break
        case 1:
          this.$store.commit('change_interactiveArr', data.param)
          break

        default:
          break
      }
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.terminalNos = [this.formatTelNo(this.data.videos)]
      this.videoNumber = Number(returnVideoArr(this.data.videos)) || 0 ///摄像头个数
      this.queryDevice();
    }
  }
}
</script>
<style lang="scss" scoped>
.holder-control {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
    .rotate {
      padding: 18px;
      display: inline-block;
      position: relative;
      margin: 8px 0 12px;
      .i-box {
        width: 42px;
        height: 42px;
        background: #e1ecff;
        border-radius: 50%;
        text-align: center;
        line-height: 42px;
      }
      .its_xuanzhuan {
        color: themed('b4');
        font-size: 24px;
      }
      // 旋转点击图标定位
      .its_shang {
        position: absolute;
        top: -22px;
        left: 34%;
        font-size: 24px;
        cursor: pointer;
      }
      .its_youshang {
        position: absolute;
        top: -10px;
        right: -8px;
        font-size: 26px;
        cursor: pointer;
      }
      .its_you1 {
        position: absolute;
        font-size: 24px;
        right: -19px;
        top: 30%;
        cursor: pointer;
      }
      .its_youxia {
        position: absolute;
        bottom: -10px;
        right: -8px;
        font-size: 26px;
        cursor: pointer;
      }
      .its_xia {
        position: absolute;
        bottom: -22px;
        left: 34%;
        font-size: 24px;
        cursor: pointer;
      }
      .its_zuoxia {
        position: absolute;
        bottom: -10px;
        left: -8px;
        font-size: 24px;
        cursor: pointer;
      }
      .its_zuo1 {
        position: absolute;
        font-size: 24px;
        left: -19px;
        top: 30%;
        cursor: pointer;
      }
      .its_zuoshang {
        position: absolute;
        top: -10px;
        left: -8px;
        font-size: 26px;
        cursor: pointer;
      }
      // 旋转点击图标定位
      .active {
        color: themed('b4');
      }
    }
    .more {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 40%;
        display: flex;
        justify-content: space-between;
        .iconfont {
          cursor: pointer;
        }
        .its_1hua {
          font-size: 14px;
        }
      }
    }
  }
  /deep/.dialog-form.el-form .el-form-item {
    margin-bottom: 12px;
  }
}
</style>
