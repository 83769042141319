var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "blind"
    },
    [
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 盲区监测报警配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.commit }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm,
            inline: true,
            "label-width": "170px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "后方接近报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.rearTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "rearTime", $$v)
                  },
                  expression: "addForm.rearTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "侧后方接近报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.sideTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "sideTime", $$v)
                  },
                  expression: "addForm.sideTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }