var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terminal-upgrade-bath" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form dialog-form-inline",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "公司车队：",
                prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
              }
            },
            [
              _c("companySelectForSearch", {
                ref: "companySelectForSearch",
                attrs: { multiple: true, searchable: true },
                on: { getValue: _vm.getGroupIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "选择车辆：",
                prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
              }
            },
            [
              _c("car-tree", {
                attrs: { label: "id" },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.queryAttributes }
                },
                [_vm._v("查询终端属性")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "table",
          staticClass: "no-blank",
          attrs: { id: "outputTable", data: _vm.tableData, height: "30vh" },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vehicleNo",
              label: "车牌号",
              width: 90 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "device",
              label: "终端号",
              width: 100 * _vm.screenCoefficient
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "terminalID",
              label: "终端ID",
              width: 80 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "terminalModel",
              label: "终端型号",
              width: 100 * _vm.screenCoefficient,
              "show-overflow-tooltip": true,
              filters: _vm.terminalFilters,
              "filter-method": _vm.filterTag,
              "filter-placement": "bottom-end"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hardwareVersion",
              label: "硬件版本号",
              width: 120 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "softwareVersion",
              label: "固件版本号",
              width: 160 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "SIM_ICCID",
              label: "终端SIM_ICCID",
              width: 160 * _vm.screenCoefficient,
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            "label-width": "102px",
            "label-position": "right"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "选择升级包：", prop: "path" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.path,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "path", $$v)
                    },
                    expression: "addForm.path"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.path }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生产厂家：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "通立", value: 1 } }),
                  _c("el-option", { attrs: { label: "通联", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " ", "label-width": "10px" } },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "只支持通立和通联，其他产品请联系工作人员",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                disabled: _vm.tableData.length === 0
              },
              on: { click: _vm.getXLS }
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.onSave }
            },
            [_vm._v("确认")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }