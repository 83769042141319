<template>
  <div class="adas" v-loading="loading">
    <p class="form-title form-title2">
      adas报警配置
      <el-button type="primary" size="mini" @click="commit">发送指令</el-button>
    </p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="160px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="报警判断速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmSpeed"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip
          class="item"
          effect="dark"
          content="仅适用于道路偏离、前向碰撞、车距过近和频繁变更车道报警"
          placement="top-start"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警提示音量：">
        <el-input-number
          controls-position="right"
          v-model="addForm.tipsVoice"
          :min="0"
          :max="8"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0-8，数值越大，音量越大" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="主动拍照策略：">
        <el-select v-model="addForm.takePicture" placeholder="请选择">
          <el-option
            v-for="item in takeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="主动定时拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeTime"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="主动拍照策略定时拍照时需要填写" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="主动定距拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeDistance"
          :min="0"
          :max="60000"
          placeholder="请输入"
        ></el-input-number>米
        <el-tooltip class="item" effect="dark" content="主动拍照策略定距拍照时需要填写" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="单次主动拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="单次主动拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeinterval"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="拍照分辨率：">
        <el-select v-model="addForm.takeRatio" placeholder="请选择">
          <el-option
            v-for="item in ratioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark" content="该参数也适用于报警触发拍照分辨率" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="视频录制分辨率：">
        <el-select v-model="addForm.takevideoRatio" placeholder="请选择">
          <el-option
            v-for="item in resolutionRatioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark" content="该参数也适用于报警触发视频分辨率" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.blockLeve1"></el-switch>障碍物一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.blockLeve2"></el-switch>障碍物二级报警
      </el-form-item>

      <el-form-item label="障碍物报警距离阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.distance"
          :min="10"
          :max="50"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.levelSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.videoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="障碍物报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="障碍物报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmPictrueTime"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.freLeve1"></el-switch>频繁变更车道一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.freLeve2"></el-switch>频繁变更车道二级报警
      </el-form-item>

      <el-form-item label="频繁变道报警判断周期：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePeriod"
          :min="30"
          :max="120"
          placeholder="请输入"
        ></el-input-number>秒
      </el-form-item>

      <el-form-item label="频繁变道报警判断次数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.freNum"
          :min="3"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.freSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.freTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="频繁变道报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="频繁变道报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.awayFromLeve1"></el-switch>车道偏离一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.awayFromLeve2"></el-switch>车道偏离二级报警
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.awayFromSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.awayFromVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="车道偏离报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.afPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="障碍物报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.afPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.baLeve1"></el-switch>前向碰撞一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.baLeve2"></el-switch>前向碰撞二级报警
      </el-form-item>

      <el-form-item label="前向碰撞报警时间阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abTime"
          :min="10"
          :max="50"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label=" 前向碰撞报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="前向碰撞报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.pLeve1"></el-switch>行人碰撞一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.pLeve2"></el-switch>行人碰撞二级报警
      </el-form-item>

      <el-form-item label="行人碰撞报警时间阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pTime"
          :min="10"
          :max="50"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="行人碰撞报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="行人碰撞报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.carLeve1"></el-switch>车距过近一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.carLeve2"></el-switch>车距过近二级报警
      </el-form-item>

      <el-form-item label="车距监控报警距离阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.carDistance"
          :min="10"
          :max="50"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.carSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.carVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="车距过近报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.carPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="车距过近报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.carPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.roadLeve"></el-switch>道路标识超速报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="道路标志识别拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.roadPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="道路标志识别拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.roadPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <!-- 2019新增 -->
      <template v-if="arr&&arr.length>45">
        <el-form-item label=" ">
          <el-switch v-model="addForm.turnLeve"></el-switch>碰撞侧翻报警
        </el-form-item>
        <el-form-item label=" "></el-form-item>
        <el-form-item label="碰撞侧翻报警时间阈值：">
          <el-input-number
            controls-position="right"
            v-model="addForm.turnTime"
            :min="10"
            :max="50"
            placeholder="请输入"
          ></el-input-number>100ms
        </el-form-item>

        <el-form-item label="侧翻报警使能速度阈值：">
          <el-input-number
            controls-position="right"
            v-model="addForm.turnSpeed"
            :min="0"
            :max="220"
            placeholder="请输入"
          ></el-input-number>km/h
          <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item>

        <el-form-item label="侧翻报警前后视频时间：">
          <el-input-number
            controls-position="right"
            v-model="addForm.turnVideoTime"
            :min="0"
            :max="60"
            placeholder="请输入"
          ></el-input-number>秒
          <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item>

        <el-form-item label="碰撞侧翻报警拍照张数：">
          <el-input-number
            controls-position="right"
            v-model="addForm.turnPictrueNum"
            :min="0"
            :max="10"
            placeholder="请输入"
          ></el-input-number>秒
          <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item>

        <el-form-item label="碰撞侧翻报警拍照间隔：">
          <el-input-number
            controls-position="right"
            v-model="addForm.turnPictrueTime"
            :min="1"
            :max="10"
            placeholder="请输入"
          ></el-input-number>100ms
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
const single2011 = [
  { key: 'alarmSpeed', valueKey: 'arr', valueIndex: 0, defaultValue: 30 },
  { key: 'tipsVoice', valueKey: 'arr', valueIndex: 1, defaultValue: 6 },
  { key: 'takePicture', valueKey: 'arr', valueIndex: 2, defaultValue: 0 },
  { key: 'takeTime', valueKey: 'arr', valueIndex: 3, defaultValue: 60 },
  { key: 'takeDistance', valueKey: 'arr', valueIndex: 4, defaultValue: 200 },
  { key: 'takeNum', valueKey: 'arr', valueIndex: 5, defaultValue: 3 },
  { key: 'takeinterval', valueKey: 'arr', valueIndex: 6, defaultValue: 2 },
  { key: 'takeRatio', valueKey: 'arr', valueIndex: 7, defaultValue: 1 },
  { key: 'takevideoRatio', valueKey: 'arr', valueIndex: 8, defaultValue: 1 },
  { key: 'blockLeve1', valueKey: 'bit32', valueIndex: 0, defaultValue: true },
  { key: 'blockLeve2', valueKey: 'bit32', valueIndex: 1, defaultValue: true },
  { key: 'distance', valueKey: 'arr', valueIndex: 12, defaultValue: 30 },
  { key: 'levelSpeed', valueKey: 'arr', valueIndex: 13, defaultValue: 50 },
  { key: 'videoTime', valueKey: 'arr', valueIndex: 14, defaultValue: 5 },
  { key: 'alarmPictrueNum', valueKey: 'arr', valueIndex: 15, defaultValue: 3 },
  { key: 'alarmPictrueTime', valueKey: 'arr', valueIndex: 16, defaultValue: 2 },
  { key: 'freLeve1', valueKey: 'bit32', valueIndex: 2, defaultValue: true },
  { key: 'freLeve2', valueKey: 'bit32', valueIndex: 3, defaultValue: true },
  { key: 'frePeriod', valueKey: 'arr', valueIndex: 17, defaultValue: 60 },
  { key: 'freNum', valueKey: 'arr', valueIndex: 18, defaultValue: 5 },
  { key: 'freSpeed', valueKey: 'arr', valueIndex: 19, defaultValue: 50 },
  { key: 'freTime', valueKey: 'arr', valueIndex: 20, defaultValue: 5 },
  { key: 'frePictrueNum', valueKey: 'arr', valueIndex: 21, defaultValue: 3 },
  { key: 'frePictrueTime', valueKey: 'arr', valueIndex: 22, defaultValue: 2 },
  { key: 'awayFromLeve1', valueKey: 'bit32', valueIndex: 4, defaultValue: true },
  { key: 'awayFromLeve2', valueKey: 'bit32', valueIndex: 5, defaultValue: true },
  { key: 'awayFromSpeed', valueKey: 'arr', valueIndex: 23, defaultValue: 50 },
  { key: 'awayFromVideoTime', valueKey: 'arr', valueIndex: 24, defaultValue: 5 },
  { key: 'afPictrueNum', valueKey: 'arr', valueIndex: 25, defaultValue: 3 },
  { key: 'afPictrueTime', valueKey: 'arr', valueIndex: 26, defaultValue: 2 },
  { key: 'baLeve1', valueKey: 'bit32', valueIndex: 6, defaultValue: true },
  { key: 'baLeve2', valueKey: 'bit32', valueIndex: 7, defaultValue: true },
  { key: 'abTime', valueKey: 'arr', valueIndex: 27, defaultValue: 27 },
  { key: 'abSpeed', valueKey: 'arr', valueIndex: 28, defaultValue: 50 },
  { key: 'abVideoTime', valueKey: 'arr', valueIndex: 29, defaultValue: 5 },
  { key: 'abPictrueNum', valueKey: 'arr', valueIndex: 30, defaultValue: 3 },
  { key: 'abPictrueTime', valueKey: 'arr', valueIndex: 31, defaultValue: 2 },
  { key: 'pLeve1', valueKey: 'bit32', valueIndex: 8, defaultValue: true },
  { key: 'pLeve2', valueKey: 'bit32', valueIndex: 9, defaultValue: true },
  { key: 'pTime', valueKey: 'arr', valueIndex: 32, defaultValue: 30 },
  { key: 'pSpeed', valueKey: 'arr', valueIndex: 33, defaultValue: 50 },
  { key: 'pVideoTime', valueKey: 'arr', valueIndex: 34, defaultValue: 5 },
  { key: 'pPictrueNum', valueKey: 'arr', valueIndex: 35, defaultValue: 3 },
  { key: 'pPictrueTime', valueKey: 'arr', valueIndex: 36, defaultValue: 2 },
  { key: 'carLeve1', valueKey: 'bit32', valueIndex: 10, defaultValue: true },
  { key: 'carLeve2', valueKey: 'bit32', valueIndex: 11, defaultValue: true },
  { key: 'carDistance', valueKey: 'arr', valueIndex: 37, defaultValue: 10 },
  { key: 'carSpeed', valueKey: 'arr', valueIndex: 38, defaultValue: 50 },
  { key: 'carVideoTime', valueKey: 'arr', valueIndex: 39, defaultValue: 5 },
  { key: 'carPictrueNum', valueKey: 'arr', valueIndex: 40, defaultValue: 3 },
  { key: 'carPictrueTime', valueKey: 'arr', valueIndex: 41, defaultValue: 2 },
  { key: 'roadLeve', valueKey: 'bit32', valueIndex: 16, defaultValue: true },
  { key: 'roadPictrueNum', valueKey: 'arr', valueIndex: 42, defaultValue: 3 },
  { key: 'roadPictrueTime', valueKey: 'arr', valueIndex: 43, defaultValue: 2 },
]
const single2019 = [
  { key: 'turnLeve', valueKey: 'bit32', valueIndex: 17, defaultValue: true },
  { key: 'turnTime', valueKey: 'arr', valueIndex: 44, defaultValue: 10 },
  { key: 'turnSpeed', valueKey: 'arr', valueIndex: 45, defaultValue: 10 },
  { key: 'turnVideoTime', valueKey: 'arr', valueIndex: 46, defaultValue: 5 },
  { key: 'turnPictrueNum', valueKey: 'arr', valueIndex: 47, defaultValue: 3 },
  { key: 'turnPictrueTime', valueKey: 'arr', valueIndex: 48, defaultValue: 2 },
]
export default {
  props: {
    arr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      loading: false,//加载画面
      addForm: null,
      // {
      //   alarmSpeed: 30,//报警判断速度阈值
      //   tipsVoice: 6,//报警提示音量
      //   takePicture: 0,//主动拍照策略
      //   takeTime: 60,//主动拍照时间间隔
      //   takeDistance: 200,//主动拍照距离间隔
      //   takeNum: 3,//单次主动拍照张数
      //   takeinterval: 2,//单次主动拍照间隔
      //   takeRatio: 1,//拍照分辨率
      //   takevideoRatio: 1,//视频录制分辨率

      //   blockLeve1: true,//障碍物一级报警
      //   blockLeve2: true,//障碍物2级报警
      //   distance: 30,//障碍物报警距离阈值
      //   levelSpeed: 50,//障碍物报警分级速度阈值
      //   videoTime: 5,//障碍物报警前后视频路子时间
      //   alarmPictrueNum: 3,//障碍物报警拍照张数
      //   alarmPictrueTime: 2,//障碍物报警拍照间隔

      //   freLeve1: 1,//频繁变更车道一级报警
      //   freLeve2: 1,//频繁变更车道二级报警
      //   frePeriod: 60,//频繁变更车道报警判断时间周期
      //   freNum: 5,//频繁变道报警判断次数
      //   freSpeed: 50,//频繁变道报警分级速度阈值
      //   freTime: 5,//频繁变更车道报警前后视频时间
      //   frePictrueNum: 3,//频繁变道报警拍照张数
      //   frePictrueTime: 2,//频繁变道报警拍照间隔

      //   awayFromLeve1: 1,//车道偏离一级报警
      //   awayFromLeve2: 1,//车道偏离二级报警
      //   awayFromSpeed: 50,//车道偏离报警分级速度阈值
      //   awayFromVideoTime: 5,//车道偏离报警前后视频录制时间
      //   afPictrueNum: 3,//车道偏离报警拍照张数
      //   afPictrueTime: 2,//车道偏离报警拍照间隔

      //   baLeve1: 1,//前向碰撞一级报警
      //   baLeve2: 2,//前向碰撞二级报警
      //   abTime: 27,//前向碰撞报警时间阈值
      //   abSpeed: 50,//前向碰撞报警分级速度阈值
      //   abVideoTime: 5,//前向碰撞报警前后视频录制时间
      //   abPictrueNum: 3,// 前向碰撞报警拍照张数
      //   abPictrueTime: 2,// 前向碰撞报警拍照张数

      //   pLeve1: 1,//行人碰撞一级报警
      //   pLeve2: 2,//行人碰撞二级报警
      //   pTime: 30,//行人碰撞报警时间阈值
      //   pSpeed: 50,//行人碰撞报警分级速度阈值
      //   pVideoTime: 5,//行人碰撞报警前后视频录制时间
      //   pPictrueNum: 3,// 行人碰撞报警拍照张数
      //   pPictrueTime: 2,// 行人碰撞报警拍照间隔

      //   carLeve1: 1,//车距过近一级报警
      //   carLeve2: 2,//车距过近二级报警
      //   carDistance: 10,//车距监控报警距离阈值
      //   carSpeed: 50,//车距监控报警分级速度阈值
      //   carVideoTime: 5,//车距过近报警前后视频录制时间
      //   carPictrueNum: 3,// 车距过近报警拍照张数
      //   carPictrueTime: 2,// 车距过近报警拍照张数

      //   roadLeve: true,//道路标识超速报警
      //   roadPictrueNum: 3,//道路标志识别拍照张数
      //   roadPictrueTime: 2,//道路标志识别拍照间隔

      //   turnLeve: true,//碰撞侧翻报警
      //   turnTime: 10,//碰撞侧翻报警时间阈值
      //   turnSpeed: 10,//碰撞侧翻报警使能速度阈值
      //   turnVideoTime: 5,//碰撞侧翻报警前后视频录制时间
      //   turnPictrueNum: 3,// 碰撞侧翻报警拍照张数
      //   turnPictrueTime: 2,// 碰撞侧翻报警拍照张数
      // },
      takeList: [
        { dictValue: '不开启', dictCode: 0 },
        { dictValue: '定时拍照', dictCode: 1 },
        { dictValue: '定距拍照', dictCode: 2 },
        { dictValue: '保留', dictCode: 3 }
      ],
      ratioList: [
        { dictValue: '352*288', dictCode: 1 },
        { dictValue: '704*288', dictCode: 2 },
        { dictValue: '704*576', dictCode: 3 },
        { dictValue: '640*480', dictCode: 4 },
        { dictValue: '1280*720', dictCode: 5 },
        { dictValue: '1920*1080', dictCode: 6 }
      ],
      resolutionRatioList: [
        { dictValue: "CIF", dictCode: 1 },
        { dictValue: "HD1", dictCode: 2 },
        { dictValue: "D1", dictCode: 3 },
        { dictValue: "WD1", dictCode: 4 },
        { dictValue: "VGA", dictCode: 5 },
        { dictValue: "720P", dictCode: 6 },
        { dictValue: "1080P", dictCode: 7 }
      ],
      temp1: 0,
      temp2: 0,
    }
  },
  methods: {
    commit () {
      //处理成数组返给父组件下发指令
      const {
        alarmSpeed,
        tipsVoice,
        takePicture,
        takeTime,
        takeDistance,
        takeNum,
        takeinterval,
        takeRatio,
        takevideoRatio,

        blockLeve1,
        blockLeve2,
        distance,
        levelSpeed,
        videoTime,
        alarmPictrueNum,
        alarmPictrueTime,

        freLeve1,
        freLeve2,
        frePeriod,
        freNum,
        freSpeed,
        freTime,
        frePictrueNum,
        frePictrueTime,

        awayFromLeve1,
        awayFromLeve2,
        awayFromSpeed,
        awayFromVideoTime,
        afPictrueNum,
        afPictrueTime,

        baLeve1,
        baLeve2,
        abTime,
        abSpeed,
        abVideoTime,
        abPictrueNum,
        abPictrueTime,

        pLeve1,
        pLeve2,
        pTime,
        pSpeed,
        pVideoTime,
        pPictrueNum,
        pPictrueTime,

        carLeve1,
        carLeve2,
        carDistance,
        carSpeed,
        carVideoTime,
        carPictrueNum,
        carPictrueTime,

        roadLeve,
        roadPictrueNum,
        roadPictrueTime,

        turnLeve,
        turnTime,
        turnSpeed,
        turnVideoTime,
        turnPictrueNum,
        turnPictrueTime,
      } = this.addForm
      //二进制转换为十进制
      // let singleArr = new Array(32).fill(0);//格式化为32位二进制格式
      const singleArr = [blockLeve1 ? 1 : 0, blockLeve2 ? 1 : 0, freLeve1 ? 1 : 0, freLeve2 ? 1 : 0, awayFromLeve1 ? 1 : 0, awayFromLeve2 ? 1 : 0, baLeve1 ? 1 : 0, baLeve2 ? 1 : 0, pLeve1 ? 1 : 0, pLeve2 ? 1 : 0, carLeve1 ? 1 : 0, carLeve2 ? 1 : 0, 0, 0, 0, 0, roadLeve ? 1 : 0, turnLeve ? 1 : 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];//32位
      let singleStr = parseInt(singleArr.reverse().join(''), 2);
      //兼容
      const biscData = [
        alarmSpeed,
        tipsVoice,
        takePicture,
        takeTime,
        takeDistance,
        takeNum,
        takeinterval,
        takeRatio,
        takevideoRatio,

        singleStr,//报警使能
        this.temp1,
        this.temp2,//原样返回

        distance,
        levelSpeed,
        videoTime,
        alarmPictrueNum,
        alarmPictrueTime,

        frePeriod,
        freNum,
        freSpeed,
        freTime,
        frePictrueNum,
        frePictrueTime,

        awayFromSpeed,
        awayFromVideoTime,
        afPictrueNum,
        afPictrueTime,

        abTime,
        abSpeed,
        abVideoTime,
        abPictrueNum,
        abPictrueTime,

        pTime,
        pSpeed,
        pVideoTime,
        pPictrueNum,
        pPictrueTime,

        carDistance,
        carSpeed,
        carVideoTime,
        carPictrueNum,
        carPictrueTime,

        roadPictrueNum,
        roadPictrueTime
      ];//长度为45的数组，传回父组件下发指令
      const additional = [
        turnTime,
        turnSpeed,
        turnVideoTime,
        turnPictrueNum,
        turnPictrueTime,
      ]
      let resultData = [];
      if(this.arr&&this.arr.length<46){
        //2011协议
        resultData = [...biscData,0];//最后一位为协议的保留位，传0
      }else{
        //2019协议
        resultData = [...biscData,...additional,0];//最后一位为协议的保留位，传0
      }
      let resultObj = [...this.arr];
      resultData.map((item, index) => {
        resultObj[index].value = item;
      })
      this.$emit('commit', resultObj)
    },
    //数据处理
    computerObj (arr) {
      //报警使能:arr[9],事件使能arr[10],预留字段arr[11]
      this.temp1 = arr[10].value;
      this.temp2 = arr[11].value;
      //二进制转换
      let bfArr = Number(arr[9].value);
      let bit2 = bfArr.toString(2).split('').reverse();
      let l1 = bit2.length;
      bit2.length = 32;
      let bit32 = bit2.fill('0', l1, 32);
      const dataObj = { arr, bit32 };
      let single = []
      //兼容处理
      if(this.arr.length>45){
        single = [...single2011,...single2019];
      }else{
        single = [...single2011];
      }
      const resetObj = {};
      single.forEach(item => {
        if (item.valueKey === 'arr') {
          resetObj[item.key] = dataObj[item.valueKey][item.valueIndex].value;
        } else {
          resetObj[item.key] = dataObj[item.valueKey][item.valueIndex] == 1;
        }
      })
      this.addForm = resetObj;
      // this.addForm = {
        // alarmSpeed: arr[0].value,//报警判断速度阈值
        // tipsVoice: arr[1].value,//报警提示音量
        // takePicture: arr[2].value,//主动拍照策略
        // takeTime: arr[3].value,//主动拍照时间间隔
        // takeDistance: arr[4].value,//主动拍照距离间隔
        // takeNum: arr[5].value,//单次主动拍照张数
        // takeinterval: arr[6].value,//单次主动拍照间隔
        // takeRatio: arr[7].value,//拍照分辨率
        // takevideoRatio: arr[8].value,//视频录制分辨率

        // blockLeve1: bit32[0] == 1 ? true : false,//障碍物一级报警
        // blockLeve2: bit32[1] == 1 ? true : false,//障碍物2级报警
        // distance: arr[12].value,//障碍物报警距离阈值
        // levelSpeed: arr[13].value,//障碍物报警分级速度阈值
        // videoTime: arr[14].value,//障碍物报警前后视频路子时间
        // alarmPictrueNum: arr[15].value,//障碍物报警拍照张数
        // alarmPictrueTime: arr[16].value,//障碍物报警拍照间隔

        // freLeve1: bit32[2] == 1 ? true : false,//频繁变更车道一级报警
        // freLeve2: bit32[3] == 1 ? true : false,//频繁变更车道二级报警
        // frePeriod: arr[17].value,//频繁变更车道报警判断时间周期
        // freNum: arr[18].value,//频繁变道报警判断次数
        // freSpeed: arr[19].value,//频繁变道报警分级速度阈值
        // freTime: arr[20].value,//频繁变更车道报警前后视频时间
        // frePictrueNum: arr[21].value,//频繁变道报警拍照张数
        // frePictrueTime: arr[22].value,//频繁变道报警拍照间隔

        // awayFromLeve1: bit32[4] == 1 ? true : false,//车道偏离一级报警
        // awayFromLeve2: bit32[5] == 1 ? true : false,//车道偏离二级报警
        // awayFromSpeed: arr[23].value,//车道偏离报警分级速度阈值
        // awayFromVideoTime: arr[24].value,//车道偏离报警前后视频录制时间
        // afPictrueNum: arr[25].value,//车道偏离报警拍照张数
        // afPictrueTime: arr[26].value,//车道偏离报警拍照间隔

        // baLeve1: bit32[6] == 1 ? true : false,//前向碰撞一级报警
        // baLeve2: bit32[7] == 1 ? true : false,//前向碰撞二级报警
        // abTime: arr[27].value,//前向碰撞报警时间阈值
        // abSpeed: arr[28].value,//前向碰撞报警分级速度阈值
        // abVideoTime: arr[29].value,//前向碰撞报警前后视频录制时间
        // abPictrueNum: arr[30].value,// 前向碰撞报警拍照张数
        // abPictrueTime: arr[31].value,// 前向碰撞报警拍照张数

        // pLeve1: bit32[8] == 1 ? true : false,//行人碰撞一级报警
        // pLeve2: bit32[9] == 1 ? true : false,//行人碰撞二级报警
        // pTime: arr[32].value,//行人碰撞报警时间阈值
        // pSpeed: arr[33].value,//行人碰撞报警分级速度阈值
        // pVideoTime: arr[34].value,//行人碰撞报警前后视频录制时间
        // pPictrueNum: arr[35].value,// 行人碰撞报警拍照张数
        // pPictrueTime: arr[36].value,// 行人碰撞报警拍照间隔

        // carLeve1: bit32[10] == 1 ? true : false,//车距过近一级报警
        // carLeve2: bit32[11] == 1 ? true : false,//车距过近二级报警
        // carDistance: arr[37].value,//车距监控报警距离阈值
        // carSpeed: arr[38].value,//车距监控报警分级速度阈值
        // carVideoTime: arr[39].value,//车距过近报警前后视频录制时间
        // carPictrueNum: arr[40].value,// 车距过近报警拍照张数
        // carPictrueTime: arr[41].value,// 车距过近报警拍照张数

        // roadLeve: bit32[16] == 1 ? true : false,//道路标识超速报警
        // roadPictrueNum: arr[42].value,//道路标志识别拍照张数
        // roadPictrueTime: arr[43].value,//道路标志识别拍照间隔
        //兼容2019
        // turnLeve: bit32[17] == 1 ? true : false,//碰撞侧翻报警
        // turnTime: arr[44].value,//碰撞侧翻报警时间阈值
        // turnSpeed: arr[45].value,//碰撞侧翻报警使能速度阈值
        // turnVideoTime: arr[46].value,//碰撞侧翻报警前后视频录制时间
        // turnPictrueNum: arr[47].value,// 碰撞侧翻报警拍照张数
        // turnPictrueTime: arr[48].value,// 碰撞侧翻报警拍照间隔
      // }
      this.loading = false;
    }
  },
  created () {
    if (this.arr && this.arr.length > 0) {
      //数组分为50和45，兼容2019新增的碰撞侧翻
      this.loading = true;
      this.computerObj(this.arr)
    }else{
      //赋初始值
      const initialArr = [...single2011,...single2019];
      let initialObj={};
      initialArr.forEach(item=>{
        initialObj[item.key] = item.defaultValue;
      })
      this.addForm = initialObj;
    }
  }
}
</script>
<style lang="scss" scoped>
.adas {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>