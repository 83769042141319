<template>
  <div class="queryCarDrivingRecords">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="82px"
      label-position="left"
      class="demo-form"
    >
      <el-form-item label="功能" prop="functions">
        <el-select v-model="form.functions" clearable placeholder="请选择">
          <el-option
            v-for="item in functionsOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="isShowDatetimerange"
        label="时间范围"
        prop="datetimerange"
      >
        <el-date-picker
          v-model="form.datetimerange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :default-time="['00:00:00', '00:10:00']"
        >
          >
        </el-date-picker>
      </el-form-item>
      <el-form-item v-if="isShowDatetimerange" label="    ">
        <i class="iconfont its_cuowu"></i>
        <span> 查询开始时间之后的数据</span>
      </el-form-item>
      <el-form-item label="    ">
        <el-button type="primary" size="small" @click="save()"
          >确定下发</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { returnVideoArr, formatDate, formatDay } from "@/common/utils/index";
import { queryDeviceByIds } from "@/api/lib/refreshAlarm.js";
export default {
  name: "",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      terminalNos: [],
      vehicleInfos: [],
      deviceType: "", // 协议类型
      functionsOptions: [
        { value: 0, label: "采集记录仪执行标准版本" },
        { value: 1, label: "采集当前驾驶人信息" },
        { value: 2, label: "采集记录仪实时时间" },
        { value: 3, label: "采集累计行驶里程" },
        { value: 4, label: "采集记录仪脉冲系数" },
        { value: 5, label: "采集车辆信息" },
        { value: 6, label: "采集记录仪状态信号配置信息" },
        { value: 7, label: "采集记录仪唯一性编号" },
        { value: 8, label: "采集指定的行驶速度记录" },
        { value: 9, label: "采集指定的位置信息记录" },
        { value: 16, label: "采集指定的事故疑点记录" },
        { value: 17, label: "采集指定的超时驾驶记录" },
        { value: 18, label: "采集指定的驾驶人身份记录" },
        { value: 19, label: "采集指定的外部供电记录" },
        { value: 20, label: "采集指定的参数修改记录" },
        { value: 21, label: "采集指定的速度状态日志" }
      ],
      form: {
        functions: null,
        datetimerange: null
      },
      rules: {
        functions: [
          {
            required: true,
            message: "请选择功能",
            trigger: "change"
          }
        ],
        datetimerange: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请选择时间范围"));
              } else if (
                new Date(value[1]).valueOf() - new Date(value[0]).valueOf() >
                10 * 60 * 1000
              ) {
                callback(new Error("时间范围不能超过10分钟"));
              } else {
                return callback();
              }
            }
          }
        ]
      }
    };
  },
  filters: {},
  computed: {
    isShowDatetimerange() {
      return this.form.functions > 7;
    }
  },
  watch: {
    "form.functions"() {
      this.form.datetimerange = null;
    }
  },
  created() {},
  mounted() {
    this.deviceType = sessionStorage.getItem("system");
  },
  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          queryDeviceByIds({ ids: this.data.id, system: this.deviceType }).then(
            res => {
              if (res.code === 1000) {
                let vehicle = res.data[0];
                let deviceNo = returnVideoArr(vehicle.videos, true);
                this.terminalNos = [deviceNo];
                this.vehicleInfos = [`${vehicle.cph}#${vehicle.companyName}`];
                let params = Object.values(this.form).filter(item => item > -1);
                params.length > 1 && params.pop();
                this.form.datetimerange?.forEach(item => [
                  params.push(new Date(item).valueOf() / 1000)
                ]);
                this.$yhsdp.GBQueryCarDrivingRecords(
                  [this.terminalNos, this.vehicleInfos],
                  params,
                  this.deviceType
                );
                this.$emit("close");
              }
            }
          );
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.queryCarDrivingRecords {
  .el-select {
    width: 100%;
  }
}
</style>
