var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.operationList.length > 0
    ? _c(
        "div",
        { ref: "pop", staticClass: "operationPop" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "top-start",
                trigger: "hover",
                "popper-class": "operation-pop"
              }
            },
            [
              _vm.data.status !== 0 && _vm.data.status !== null
                ? _c(
                    "div",
                    { staticClass: "pop", on: { mouseleave: _vm.leave } },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.operationList, function(item, key) {
                          return _c(
                            "li",
                            {
                              key: key,
                              staticClass: "el-cascader-node",
                              on: {
                                mouseenter: function($event) {
                                  return _vm.operationEnter(item.actionKey)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-cascader-node__label" },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.children,
                                    expression: "item.children"
                                  }
                                ],
                                staticClass:
                                  "el-icon-arrow-right el-cascader-node__postfix"
                              })
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "ul",
                        _vm._l(_vm.childOperationList, function(item, key) {
                          return _c(
                            "li",
                            {
                              key: key,
                              staticClass: "el-cascader-node",
                              on: {
                                click: function($event) {
                                  return _vm.handleNodeClick(item)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "el-cascader-node__label" },
                                [_vm._v(_vm._s(item.name))]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e(),
              _c("i", {
                ref: "icon",
                staticClass: "iconfont its_icon_peizhi",
                class:
                  _vm.data.status !== 0 && _vm.data.status !== null
                    ? "active"
                    : "carOutline",
                attrs: { slot: "reference" },
                slot: "reference"
              })
            ]
          ),
          _vm.showDialog
            ? _c(
                "el-dialog",
                {
                  key: _vm.DialogIndex,
                  attrs: {
                    "close-on-click-modal": false,
                    title: _vm.title,
                    visible: _vm.showDialog,
                    width: _vm.dialoWidth,
                    "append-to-body": "",
                    "custom-class":
                      _vm.DialogIndex == "addVehicle"
                        ? "add-vehicle-dialog"
                        : ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.showDialog = $event
                    }
                  }
                },
                [
                  _vm.DialogIndex == "sendInformation"
                    ? _c("send-information", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "sendDrivers"
                    ? _c("send-driver", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "terminalParameter" &&
                  _vm.deviceType !== "1"
                    ? _c("terminal-parametergps", {
                        attrs: {
                          data: _vm.data,
                          settingValue: _vm.settingValue
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "terminalParameter" &&
                  _vm.deviceType == "1"
                    ? _c("terminal-parameter", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "terminalTaxiIp" && _vm.deviceType == "1"
                    ? _c("terminal-taxi-ip", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "terminalIp"
                    ? _c("terminal-ip", {
                        attrs: {
                          data: _vm.data,
                          settingValue: _vm.settingValue
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "ICardset"
                    ? _c("make-card", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  (_vm.DialogIndex == "setAlarmShield" ||
                    _vm.DialogIndex === "closeVoice") &&
                  _vm.deviceType === "1"
                    ? _c("setAlarmShield", {
                        attrs: {
                          settingValue: _vm.settingValue,
                          type: _vm.DialogIndex == "setAlarmShield" ? 0 : 1,
                          id: _vm.data,
                          vehicleInfos: _vm.vehicleInfos,
                          terminalNo: _vm.telNos
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  (_vm.DialogIndex == "setAlarmShield" ||
                    _vm.DialogIndex === "closeVoice") &&
                  _vm.deviceType !== "1"
                    ? _c("set-alarmShieldGps", {
                        attrs: {
                          settingValue: _vm.settingValue,
                          type: _vm.DialogIndex == "setAlarmShield" ? 0 : 1,
                          id: _vm.data,
                          vehicleInfos: _vm.vehicleInfos,
                          terminalNo: _vm.telNos
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "listening"
                    ? _c("listening", {
                        attrs: { settingValue: _vm.settingValue },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "advancedParameters"
                    ? _c("advancedParameters", {
                        attrs: {
                          settingValue: _vm.settingValue,
                          data: _vm.data
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "areaAlarmSetting"
                    ? _c("area-alarmSetting", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "queryCarDrivingRecords"
                    ? _c("queryCarDrivingRecords", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "carOfflineTime"
                    ? _c(
                        "div",
                        { staticClass: "body" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "addForm",
                              staticClass: "demo-form-inline dialog-form",
                              attrs: {
                                model: _vm.addForm,
                                size: "small",
                                "label-position": "left"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "车牌号：",
                                    "label-width": "92px"
                                  }
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(_vm._s(_vm.data.name))
                                      ]),
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(
                                          "终端号：" +
                                            _vm._s(
                                              _vm.formatTelNo(_vm.data.videos)
                                            )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "下线时限(分钟)：",
                                    "label-width": "140px"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "请输入",
                                      min: 0,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.addForm.time,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "time", $$v)
                                      },
                                      expression: "addForm.time"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "长时间离线(分钟)：",
                                    "label-width": "140px"
                                  }
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: {
                                      placeholder: "请输入",
                                      min: 0,
                                      "controls-position": "right"
                                    },
                                    model: {
                                      value: _vm.addForm.longTime,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addForm, "longTime", $$v)
                                      },
                                      expression: "addForm.longTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { label: "    " }
                                },
                                [
                                  _c("br"),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small" },
                                      on: {
                                        click: function($event) {
                                          _vm.showDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("取消")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "small" },
                                      on: {
                                        click: function($event) {
                                          _vm.showDialog = false
                                        }
                                      }
                                    },
                                    [_vm._v("保存")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.DialogIndex == "setOperationLine"
                    ? _c(
                        "div",
                        { staticClass: "body" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              staticClass: "demo-form-inline dialog-form",
                              attrs: {
                                model: _vm.form,
                                size: "small",
                                rules: _vm.rules,
                                "label-position": "left",
                                "label-width": "92px"
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "车牌号：" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(_vm._s(_vm.data.name))
                                      ]),
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(
                                          "终端号：" +
                                            _vm._s(
                                              _vm.formatTelNo(_vm.data.videos)
                                            )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "运营线路：",
                                    prop: "operateLine"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入" },
                                    model: {
                                      value: _vm.form.operateLine,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "operateLine", $$v)
                                      },
                                      expression: "form.operateLine"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.DialogIndex == "setOperationLine"
                    ? _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "default", size: "small" },
                              on: {
                                click: function($event) {
                                  _vm.showDialog = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.saveOperateLine }
                            },
                            [_vm._v("保存")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.DialogIndex == "terminalAttributes" ||
                  _vm.DialogIndex == "QueryAttributes"
                    ? _c("terminalUpgrade", {
                        attrs: {
                          settingValue: _vm.settingValue,
                          data: _vm.data,
                          type: _vm.DialogIndex
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "driverManage"
                    ? _c("dirverInfo", {
                        attrs: { driverInfo: _vm.driverInfo },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "carDrivingHibit"
                    ? _c("carDrivingHibit", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "realtimePictures"
                    ? _c("realtimePictures", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "removeAlarm"
                    ? _c("removeAlarm", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "addVehicle"
                    ? _c("addVehicle", {
                        ref: "addVehicle",
                        attrs: {
                          vehicleNo: _vm.vehicleNo,
                          rowData: _vm.rowData,
                          monitoringDisabled: true
                        },
                        on: { saveEnd: _vm.saveEnd, onLoading: _vm.onLoading }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "roadNetworkState" && _vm.deviceType !== 1
                    ? _c("roadNetworkStatePage", {
                        attrs: { data: _vm.data, deviceNo: _vm.deviceNo }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "roadNetworkState2" && _vm.deviceType !== 1
                    ? _c("roadNetworkStatePage2", {
                        attrs: { data: _vm.data, deviceNo: _vm.deviceNo }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "holderControl"
                    ? _c("holderControl", {
                        attrs: { data: _vm.data },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "highLoadLimit"
                    ? _c("highLoadLimit", {
                        attrs: {
                          data: _vm.data,
                          settingValue: _vm.settingValue
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "setAudioAndVideo"
                    ? _c("setAudioAndVideo", {
                        attrs: {
                          data: _vm.data,
                          settingValue: _vm.settingValue
                        },
                        on: { close: _vm.handleCloseDialog }
                      })
                    : _vm._e(),
                  _vm.DialogIndex == "addVehicle"
                    ? _c(
                        "span",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer"
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "default", size: "small" },
                              on: {
                                click: function($event) {
                                  _vm.showDialog = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "small",
                                loading: _vm.editLoading
                              },
                              on: { click: _vm.onSave }
                            },
                            [_vm._v("保存")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }