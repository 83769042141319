var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "tree-expand",
      class: { isCamera: _vm.singleData.type === 4 }
    },
    [
      _c(
        "span",
        {
          class: _vm.treeClass,
          on: {
            click: function($event) {
              return _vm.nodeclick(
                _vm.singleStore,
                _vm.singleData,
                _vm.singleNode
              )
            }
          }
        },
        [
          _c("span", {
            staticClass: "carIcon iconfont",
            class: _vm.classObject
          }),
          _c("span", [_vm._v(_vm._s(_vm.singleData.name) + " ")])
        ]
      ),
      (_vm.singleData.type === 3 &&
        (_vm.singleData.videos
          ? _vm.returnVideoArr(_vm.singleData.videos)
          : 0) > 0) ||
      _vm.singleData.type === 4
        ? _c("span", { staticClass: "tree-video" }, [
            _c("i", {
              staticClass: "carIcon iconfont",
              class: _vm.videoObject,
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.nodeVideo(
                    _vm.singleStore,
                    _vm.singleData,
                    _vm.singleNode
                  )
                }
              }
            })
          ])
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.singleData.type == 3 && _vm.showInstructions,
              expression: "singleData.type == 3 && showInstructions"
            }
          ],
          staticClass: "tree-video"
        },
        [
          _c("operationPop", {
            attrs: { data: _vm.singleData },
            on: { nodeOperation: _vm.nodeOperation }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.singleData.type == 4,
              expression: "singleData.type == 4"
            }
          ],
          staticClass: "tree-video"
        },
        [
          _c("i", {
            staticClass: "carIcon iconfont",
            class: _vm.checkObject,
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.nodeCheck(
                  _vm.singleStore,
                  _vm.singleData,
                  _vm.singleNode,
                  $event
                )
              }
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }