<template>
  <div class="roadNetworkState">
    <div class="my-table">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="状态查询详情" name="first">
          <el-form
            :model="tableData"
            :inline="true"
            ref="addForm"
            label-width="120px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="VIN码：">{{tableData.vehicleRelTbox.vehicle.vin}}</el-form-item>
            <el-form-item label="TBOXID：">{{tableData.vehicleRelTbox.tbox.deviceNo}}</el-form-item>
            <el-form-item label="厂商：">{{tableData.vehicleRelTbox.vehicle.factoryCnme}}</el-form-item>
            <el-form-item label="型号：">{{tableData.vehicleRelTbox.tbox.tboxModelTerminalModel}}</el-form-item>
            <el-form-item label="SIM卡号：">{{tableData.vehicleRelTbox.tbox.phoneNumber}}</el-form-item>
            <el-form-item label="升级方式：">{{tableData.softUpgradeAuth.authType==2?'区域':'其他'}}</el-form-item>
            <el-form-item label="注册时间：">{{tableData.license.registerTime}}</el-form-item>
            <el-form-item label="验证时间：">{{tableData.license.checkTime}}</el-form-item>
            <el-form-item label="升级授权类型：">{{tableData.license.licenseType==2?'时间范围类型':'其他'}}</el-form-item>
            <el-form-item label="功能开关：" style="width:100%;">
              <div class="switch">
                <div v-for="(item,index) in tableData.license.functions" :key="index" :class="item.flag?'on tag':'off tag'">
                {{item.name}}
              </div>
              </div>
            </el-form-item>
            <el-form-item label="License：">{{tableData.license.license}}</el-form-item>
            
            <el-form-item label="升级有效期：">{{tableData.softUpgradeAuth.startDate.substring(0,10)}} ~ {{tableData.softUpgradeAuth.endDate.substring(0,10)}}</el-form-item>
            <el-form-item label="升级状态：">{{tableData.license.status
==1?'有效':'无效'}}</el-form-item>
            <el-form-item label="License授权类型：">{{tableData.license.licenseType==2?'限制时间':'其他'}}</el-form-item>
            <el-form-item label="License有效期：">{{tableData.license.startDate.substring(0,10)}} ~ {{tableData.license.endDate.substring(0,10)}}</el-form-item>
            <el-form-item label="License状态：">{{tableData.license.status
==1?'激活':'未激活'}}</el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="版本信息" name="second">
          <div class="yy">
            <el-table :data="logList" stripe height="95%">
              <el-table-column prop="name" label="名称"></el-table-column>
              <el-table-column prop="version" label="版本"></el-table-column>
              <el-table-column prop="createTime" label="创建时间"></el-table-column>
              <el-table-column prop="updateTime" label="更新时间"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getRoadVehicleDetail, getVersionList } from '@/api/lib/api.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deviceNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeName: 'first',
      tableData:{
        license:{},
        softUpgradeAuth:{},
        softUpgradeMode:{},
        vehicleRelTbox:{},
        tbox:{},
      },
      logList: []
    }
  },
  mounted () {
    this.queryFindMapsByDeviceNo()
  },
  methods: {
    //获取终端状态详情
    queryFindMapsByDeviceNo () {
      const data = {
        deviceNo: this.deviceNo
      }
      //状态详情
      getRoadVehicleDetail(data).then(res => {
        if(res.code === 1000){
          this.tableData = res.data;
        }
      }).catch(err => {
        console.log(err)
      })
      this.queryUpdateLogData(data);//版本
    },
    //终端日志查询
    queryUpdateLogData (data) {
      getVersionList(data).then(res => {
        if (res.code === 1000) {
          this.logList = res.data || [];
        }
      }).catch(err => {
        console.log(err)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.roadNetworkState {
  .get-permissions {
    width: 50%;
    margin: 0 auto;
    .ipt-img {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tips {
        color: rgb(0, 69, 173);
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .yy {
    width: 100%;
    height: 50vh;
    overflow: auto;
  }
  .switch{
    display: flex;
    flex-wrap: wrap;
    padding:8px 16px;
    .tag{
      width: 90px;
      border-radius: 8px;
      margin-right: 5px;
      margin-bottom: 4px;
      text-align: center;
    }
    .on{
      background: #f0f9eb;
      border-color: #e1f3d8;
      color:#67c23a;
    }
    .off{
          background-color: #fef0f0;
    border-color: #fde2e2;
    color: #f56c6c;
    }
  }
}
/deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
  height: 0;
}
</style>