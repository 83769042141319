var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "terminalParameter" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "operationForm",
          attrs: {
            model: _vm.form,
            "label-width": "210px",
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          _vm.isBatch
            ? _c("el-col", { attrs: { span: 24 } }, [
                _c("h4", [_vm._v("选择车辆")])
              ])
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-col",
                { attrs: { md: 12, lg: 12, xl: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "公司车队：",
                        prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                      }
                    },
                    [
                      _c("companySelectForSearch", {
                        ref: "companySelectForSearch",
                        attrs: { multiple: true, searchable: true },
                        on: { getValue: _vm.getGroupIds }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-col",
                { attrs: { md: 12, lg: 12, xl: 12 } },
                [
                  _vm.isBatch
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择车辆：",
                            prop:
                              _vm.form.companyIds.length === 0 ? "vehIds" : ""
                          }
                        },
                        [_c("car-tree", { on: { getData: _vm.getVehIds } })],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h4", [_vm._v("运营汇报策略配置")])
          ]),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "位置汇报策略：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.locationReportingStrategy,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "locationReportingStrategy", $$v)
                        },
                        expression: "form.locationReportingStrategy"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "定时汇报", value: 0 }
                      }),
                      _c("el-option", {
                        attrs: { label: "定距汇报", value: 1 }
                      }),
                      _c("el-option", {
                        attrs: { label: "定时+定距汇报", value: 2 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "位置汇报方案：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.locationReportingPlan,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "locationReportingPlan", $$v)
                        },
                        expression: "form.locationReportingPlan"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "ACC状态", value: 0 }
                      }),
                      _c("el-option", {
                        attrs: { label: "根据空重车状态", value: 1 }
                      }),
                      _c("el-option", {
                        attrs: { label: "根据登录状态 + ACC状态", value: 2 }
                      }),
                      _c("el-option", {
                        attrs: { label: "根据登录状态 + 空重车状态", value: 3 }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "未登录汇报时间间隔(s)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.outLoginReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "outLoginReportingInterval", $$v)
                      },
                      expression: "form.outLoginReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ACC ON汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.accOnReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "accOnReportingInterval", $$v)
                      },
                      expression: "form.accOnReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ACC OFF汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.accOFFReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "accOFFReportingInterval", $$v)
                      },
                      expression: "form.accOFFReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "空车汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.emptyCarReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "emptyCarReportingInterval", $$v)
                      },
                      expression: "form.emptyCarReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "重车汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.heavyCarReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "heavyCarReportingInterval", $$v)
                      },
                      expression: "form.heavyCarReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "休眠汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.dormancyReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dormancyReportingInterval", $$v)
                      },
                      expression: "form.dormancyReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "紧急报警汇报时间间隔（s）：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.alarmReportingInterval,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "alarmReportingInterval", $$v)
                      },
                      expression: "form.alarmReportingInterval"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "未登录汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.outLoginReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "outLoginReportDistance", $$v)
                      },
                      expression: "form.outLoginReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ACC On汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.accOnReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "accOnReportDistance", $$v)
                      },
                      expression: "form.accOnReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "ACC OFF汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.accOFFReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "accOFFReportDistance", $$v)
                      },
                      expression: "form.accOFFReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "空车汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.emptyCarReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "emptyCarReportDistance", $$v)
                      },
                      expression: "form.emptyCarReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "重车汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.heavyCarReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "heavyCarReportDistance", $$v)
                      },
                      expression: "form.heavyCarReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "休眠汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.dormancyReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dormancyReportDistance", $$v)
                      },
                      expression: "form.dormancyReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "紧急报警汇报距离(m)：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.alarmReportDistance,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "alarmReportDistance", $$v)
                      },
                      expression: "form.alarmReportDistance"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "拐点补传角度：" } },
                [
                  _c("el-input-number", {
                    attrs: { "controls-position": "right", min: 0 },
                    model: {
                      value: _vm.form.inflectionAngle,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "inflectionAngle", $$v)
                      },
                      expression: "form.inflectionAngle"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 12, lg: 12, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终端心跳间隔：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.intervalTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "intervalTime", $$v)
                          },
                          expression: "form.intervalTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "hint",
                      attrs: { span: 14, title: "ACC开30s/次，ACC关60s/次" }
                    },
                    [_vm._v("ACC开30s/次，ACC关60s/次")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("h4", [_vm._v("行车配置")])
          ]),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "最高速度门限（km/h）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.speedLimit,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "speedLimit", $$v)
                          },
                          expression: "form.speedLimit"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "hint", attrs: { span: 14 } }, [
                    _vm._v("单位: km/h")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "超速持续时间（s）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.overSpeedContinueTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "overSpeedContinueTime", $$v)
                          },
                          expression: "form.overSpeedContinueTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "hint",
                      attrs: { span: 14, title: "持续超速超过该时间则产生报警" }
                    },
                    [_vm._v("持续超速超过该时间则产生报警")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "连续驾驶时限（分钟）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: 255
                        },
                        model: {
                          value: _vm.form.continueDriveTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "continueDriveTime", $$v)
                          },
                          expression: "form.continueDriveTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "hint",
                      attrs: {
                        span: 14,
                        title:
                          "单位: min(最小为1分钟,最大为4小时15分钟(255分钟)"
                      }
                    },
                    [_vm._v("单位: min(最小为1分钟,最大为4小时15分钟(255分钟)")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "当天累计驾驶时限（分钟）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 1 },
                        model: {
                          value: _vm.form.driverTotalTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "driverTotalTime", $$v)
                          },
                          expression: "form.driverTotalTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "hint",
                      attrs: { span: 14, title: "单位: min(最小为1分钟)" }
                    },
                    [_vm._v("单位: min(最小为1分钟)")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "最小休息时间（分钟）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.minSleepTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "minSleepTime", $$v)
                          },
                          expression: "form.minSleepTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "hint", attrs: { span: 14 } }, [
                    _vm._v("单位: min")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "最长停车时间（分钟）：" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c("el-input-number", {
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.form.maximumStopTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maximumStopTime", $$v)
                          },
                          expression: "form.maximumStopTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { staticClass: "hint", attrs: { span: 14 } }, [
                    _vm._v("单位: min")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { md: 24, lg: 24, xl: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.saveInput }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }