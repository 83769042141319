var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setAlarmShield" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            inline: true,
            "label-width": "150px",
            "label-position": "left"
          }
        },
        [
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.list, function(item, i) {
            return _c(
              "el-form-item",
              { key: i },
              [
                _c(
                  "el-switch",
                  {
                    attrs: {
                      "active-text": item.name,
                      "active-value": "1",
                      "inactive-value": "0"
                    },
                    model: {
                      value: _vm.form[i],
                      callback: function($$v) {
                        _vm.$set(_vm.form, i, $$v)
                      },
                      expression: "form[i]"
                    }
                  },
                  [_vm._v(">")]
                )
              ],
              1
            )
          }),
          _c("el-form-item"),
          _c(
            "el-form-item",
            { staticClass: "right-text" },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.reset } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.saveInput }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }