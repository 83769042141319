<template>
  <div class="roadNetworkState">
    <!-- 获取权限 -->
    <div class="get-permissions" v-if="!show">
      <el-form
        :model="numberValidateForm"
        ref="numberValidateForm"
        label-width="120px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="终端号:" prop="deviceNo">
          <!-- <span style="padding-left:20px;">{{deviceNo}}</span> -->
          <el-input
            v-model="numberValidateForm.deviceNo"
            placeholder="请输入终端号"
            style="margin-left:20px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码:" prop="captcha" v-if="picShow">
          <div class="ipt-img">
            <img :src="captcha" alt />
            <span class="tips" @click="changePic">看不清？换一个</span>
            <el-input
              v-model="numberValidateForm.captcha"
              placeholder="请输入验证码"
              style="width:180px;"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('numberValidateForm')" v-if="picShow">查询</el-button>
          <el-button @click="queryFindMapsByDeviceNo" v-if="!picShow">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 有权限展示路网图状态 -->
    <div class="my-table" v-else>
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="状态查询信息" name="first">
          <el-form
            :model="tableData"
            :inline="true"
            ref="addForm"
            label-width="110px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="地图序列号：">{{tableData.roadMapNo}}</el-form-item>
            <el-form-item label="终端号：">{{tableData.deviceNumber}}</el-form-item>
            <el-form-item label="终端型号：">{{tableData.deviceModel}}</el-form-item>
            <el-form-item label="地图版本号：">{{tableData.roadMapNowVersion}}</el-form-item>
            <el-form-item label="终端厂商：">{{tableData.deviceManufacturer}}</el-form-item>
            <el-form-item label="地图商：">{{tableData.roadMapSupplier}}</el-form-item>
            <el-form-item label="编译器类型：">{{tableData.deviceSdkType}}</el-form-item>
            <el-form-item label="地图状态：">{{tableData.activeState}}</el-form-item>
            <el-form-item label="激活时间：">{{tableData.activeTime}}</el-form-item>
            <el-form-item label="销售状态：">{{tableData.saleFlag}}</el-form-item>
            <el-form-item label="销售时间：">{{tableData.saleTime}}</el-form-item>
            <el-form-item label="重新激活次数：">{{tableData.deviceActiverNumber}}</el-form-item>
            <el-form-item label="允许升级：">{{tableData.isUpDataPackage}}</el-form-item>
            <el-form-item label="服务商：">{{tableData.orgName}}</el-form-item>
            <el-form-item label="服务时长：">{{tableData.serviceDuration}}</el-form-item>
            <el-form-item label="MAC地址：">{{tableData.mac}}</el-form-item>
            <el-form-item label="末次请求时间：">{{tableData.lastRequestTime}}</el-form-item>
            <el-form-item label="全量数据升级：">{{tableData.fullUpgrade}}</el-form-item>
            <el-form-item label="是否过期：">{{tableData.overdue}}</el-form-item>
            <el-form-item label="使用状态：">{{tableData.terminalActive}}</el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="日志查询信息" name="second">
          <div class="yy">
            <el-table :data="logList" stripe height="95%">
              <el-table-column prop="deviceNumber" label="终端号" width="140"></el-table-column>
              <el-table-column prop="roadMapNo" label="地图序列号" width="180"></el-table-column>
              <el-table-column prop="deviceManufacturer" label="终端厂商"></el-table-column>
              <el-table-column prop="deviceModel" label="型号"></el-table-column>
              <el-table-column prop="deviceSdkType" label="编译器类型" width="110"></el-table-column>
              <el-table-column prop="remark" label="更新类型"></el-table-column>
              <el-table-column prop="deviceRequestCheckResultName" label="请求更新结果" width="140"></el-table-column>
              <el-table-column prop="deviceRequestTime" label="请求更新时间" width="160"></el-table-column>
              <el-table-column prop="deviveResponseResultName" label="终端更新结果" width="140"></el-table-column>
              <el-table-column prop="deviveResponseTime" label="终端更新时间" width="160"></el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getCaptcha, findMapsByDeviceNo, loginRoadMap, queryUpdateLog } from '@/api/lib/api.js'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    deviceNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      captcha: '',
      rules: {
        captcha: [{ required: true, message: '校验码不能为空', trigger: 'change' }],
        deviceNo: [{ required: true, message: '校验码不能为空', trigger: 'change' }],
      },
      numberValidateForm: {
        captcha: '',
        deviceNo: ''
      },
      activeName: 'first',
      show: true,
      picShow: true,
      tableData: {
        roadMapNo: '',
        deviceNumber: '',
        deviceModel: '',
        roadMapNowVersion: '',
        deviceManufacturer: '',
        roadMapSupplier: '',
        deviceSdkType: '',
        activeState: '',
        activeTime: '',
        saleFlag: '',
        saleTime: '',
        deviceActiverNumber: '',
        isUpDataPackage: '',
        orgName: '',
        serviceDuration: '',
        mac: '',
        lastRequestTime: '',
        fullUpgrade: '',
        overdue: '',
        terminalActive: ''
      },
      logList: []
    }
  },
  mounted () {
    this.numberValidateForm.deviceNo = this.deviceNo;
    this.queryFindMapsByDeviceNo()
  },
  methods: {
    //获取图片
    changePic () {
      getCaptcha().then(res => {
        if (res.code !== 1000) {
          this.$message.warning(res.msg)
        } else {
          this.captcha = res.data.captcha;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //获取终端状态详情
    queryFindMapsByDeviceNo () {
      const data = {
        deviceNo: this.numberValidateForm.deviceNo
      }
      //状态详情
      findMapsByDeviceNo(data).then(res => {
        if (res.code === 1000) {
          this.show = true;
          this.tableData = res.data[0];//状态查询数据
        } else if (res.code === 2162) {
          this.show = false;
          this.picShow = false;
          this.$message.warning('暂无数据，请重试！')
        }
        else {
          this.show = false;
          this.picShow = true;
          this.$message.warning(res.msg)
          this.changePic();
        }
      }).catch(err => {
        console.log(err)
      })
      this.queryUpdateLogData(data);//日志详情列表
    },
    //终端日志查询
    queryUpdateLogData (data) {
      queryUpdateLog(data).then(res => {
        if (res.code === 1000) {
          this.logList = res.data || [];
        }
      }).catch(err => {
        console.log(err)
      })
    },
    //提交验证码
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //获取token
          const data = {
            verification: this.numberValidateForm.captcha
          }
          loginRoadMap(data).then(res => {
            if (res.code === 1000) {
              this.token = res.data;
              this.show = true
              this.queryFindMapsByDeviceNo();//重新获取路网图
            } else {
              this.$message.warning('验证码错误！')
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.roadNetworkState {
  .get-permissions {
    width: 50%;
    margin: 0 auto;
    .ipt-img {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tips {
        color: rgb(0, 69, 173);
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .yy {
    width: 100%;
    height: 50vh;
    overflow: auto;
  }
}
/deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
  height: 0;
}
</style>