export default {

  AlarmPromptOption: [
    'mainServerAPN',
    'mainServerUer',
    'mainServerPsd',
    'mainServerIP',
    'mainServerTcp',
    'backupServerAPN',
    'backupServerUer',
    'backupServerPsd',
    'backupServerIP',
    'backupServerTcp',
    'locationReportingStrategy',
    'locationReportingPlan',
    'outLoginReportingInterval',
    'accOnReportingInterval',
    'accOFFReportingInterval',
    'emptyCarReportingInterval',
    'heavyCarReportingInterval',
    'dormancyReportingInterval',
    'alarmReportingInterval',
    'outLoginReportDistance',
    'accOnReportDistance',
    'accOFFReportDistance',
    'emptyCarReportDistance',
    'heavyCarReportDistance',
    'dormancyReportDistance',
    'alarmReportDistance',
    'inflectionAngle',
    'speedLimit',
    'continueDriveTime',
    'overSpeedContinueTime',
    'driverTotalTime',
    'minSleepTime',
    'maximumStopTime',
    'intervalTime'
  ],
  setAlarmShield: [
    { name: '紧急报警,触动报警开关后触发', isHide: '' },
    { name: '预警', isHide: true },
    { name: '卫星定位模块发生故障', isHide: '' },
    { name: '卫星定位天线未接或被剪断', isHide: '' },
    { name: '卫星定位天线短路', isHide: '' },
    { name: 'ISU主电源欠压', isHide: '' },
    { name: 'UISU主电源掉电', isHide: true },
    { name: '液晶(LCD)显示ISU故障', isHide: '' },
    { name: '语音合成(TTS)模块故障', isHide: '' },
    { name: '摄像头故障', isHide: '' },
    { name: '计价器故障', isHide: '' },
    { name: '服务评价器故障(前后排)', isHide: '' },
    { name: 'LED广告屏故障', isHide: '' },
    { name: '液晶(LCD)显示屏故障', isHide: '' },
    { name: '安全访问模块故障', isHide: '' },
    { name: 'LED顶灯故障', isHide: '' },
    { name: '超速报警', isHide: '' },
    { name: '连续驾驶超时', isHide: '' },
    { name: '当天累计驾驶超时', isHide: '' },
    { name: '超时停车', isHide: '' },
    { name: '进出区域/路线', isHide: '' },
    { name: '路段行驶时间不足/过长', isHide: '' },
    { name: '禁行路段行驶', isHide: '' },
    { name: '车速传感器故障', isHide: '' },
    { name: '车辆非法点火', isHide: '' },
    { name: '车辆非法位移', isHide: '' },
    { name: 'ISU存储异常', isHide: '' },
    { name: '录音设备故障', isHide: '' },
    { name: '计价器时钟超过规定误差', isHide: '' }
  ],
  setAlarmShieldGps:[
    {name:'紧急报警,触动报警开关后触发'},
    {name:'超速报警'},
    {name:'疲劳驾驶报警'},
    {name:'危险驾驶行为报警'},
    {name:'GNSS模块发生故障报警'},
    {name:'GNSS天线未接或被剪断报警'},
    {name:'GNSS天线短路报警'},
    {name:'终端主电源欠压报警'},
    {name:'终端主电源掉电报警'},
    {name:'终端LCD或显示器故障报警'},
    {name:'TTS模块故障报警'},
    {name:'摄像头故障报警'},
    {name:'道路运输证IC卡模块故障报警'},
    {name:'超速预警'},
    {name:'疲劳驾驶预警'},
    {name:'违规行驶报警'},
    {name:'胎压预警'},
    {name:'右转盲区异常报警'},
    {name:'当天累计驾驶超时报警'},
    {name:'超时停车报警'},
    {name:'进出区域报警'},
    {name:'进出路线报警'},
    {name:'路段行驶时间不足/过长报警'},
    {name:'路线偏离报警'},
    {name:'车辆VSS故障'},
    {name:'车辆油量异常报警'},
    {name:'车辆被盗报警（通过车辆防盗器）'},
    {name:'车辆非法点火报警'},
    {name:'车辆非法移位报警'},
    {name:'碰撞侧翻报警'},
    {name:'侧翻预警'}
  ]

}
