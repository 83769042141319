<template>
  <div class="tire" v-loading="loading">
    <p class="form-title form-title2">
      轮胎状态监测报警配置
      <el-button type="primary" size="mini" @click="commit">发送指令</el-button>
    </p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="160px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="轮胎规格型号：">
        <el-input v-model="addForm.model" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="胎压单位：">
        <el-select v-model="addForm.unit" placeholder="请选择">
          <el-option
            v-for="item in unitList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="正常胎压值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pressure"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="单位同胎压单位" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="胎压不平衡门限：">
        <el-input-number
          controls-position="right"
          v-model="addForm.unbalanced"
          :min="0"
          :max="100"
          placeholder="请输入"
        ></el-input-number>%
      </el-form-item>

      <el-form-item label="慢漏气门限：">
        <el-input-number
          controls-position="right"
          v-model="addForm.slow"
          :min="0"
          :max="100"
          placeholder="请输入"
        ></el-input-number>%
      </el-form-item>

      <el-form-item label="低压阈值：">
        <el-input-number controls-position="right" v-model="addForm.low" :min="0" placeholder="请输入"></el-input-number>
        <el-tooltip class="item" effect="dark" content="单位同胎压单位" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="高压阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.heigh"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="单位同胎压单位" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="高温阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.temperature"
          :min="0"
          placeholder="请输入"
        ></el-input-number>℃
      </el-form-item>

      <el-form-item label="电压阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.voltage"
          :min="0"
          :max="100"
          placeholder="请输入"
        ></el-input-number>%
      </el-form-item>

      <el-form-item label="定时上报时间间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.upTime"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>秒
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    arr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      loading: false,//加载画面
      addForm: {
        model: '',
        unit: 3,
        pressure: 140,
        unbalanced: 20,
        slow: 5,
        low: 110,
        heigh: 189,
        temperature: 80,
        voltage: 10,
        upTime: 60
      },
      unitList: [
        { dictValue: 'kg/cm2', dictCode: 0 },
        { dictValue: 'bar', dictCode: 1 },
        { dictValue: 'Kpa', dictCode: 2 },
        { dictValue: 'PSI', dictCode: 3 }
      ],
    }
  },
  methods: {
    commit () {
      const {
        model,
        unit,
        pressure,
        unbalanced,
        slow,
        low,
        heigh,
        temperature,
        voltage,
        upTime
      } = this.addForm
      const resultData=[
        model,
        unit,
        pressure,
        unbalanced,
        slow,
        low,
        heigh,
        temperature,
        voltage,
        upTime,
        0
      ]
      let resultObj = [...this.arr];
      resultData.map((item,index)=>{
        resultObj[index].value = item;
      })
      this.$emit('commit',resultObj)
    },
    //数据处理
    computerObj (arr) {
      this.addForm = {
        model:arr[0].value,
        unit:arr[1].value,
        pressure:arr[2].value,
        unbalanced:arr[3].value,
        slow:arr[4].value,
        low:arr[5].value,
        heigh:arr[6].value,
        temperature:arr[7].value,
        voltage:arr[8].value,
        upTime:arr[9].value
      }
      this.loading = false
    }
  },
  mounted () {
    if (this.arr && this.arr.length > 0) {
      this.loading = true;
      this.computerObj(this.arr)
    }
  }
}
</script>
<style lang="scss" scoped>
.tire {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>