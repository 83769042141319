<template>
  <div class="advanced">
    <!-- tab -->
    <el-tabs type="card" v-model="activeName">
      <el-tab-pane label="adas参数设置" name="first">
        <adasP :arr="adasArr" @commit="adasLogSend"></adasP>
      </el-tab-pane>
      <el-tab-pane label="dsm参数设置" name="second">
        <dsmP :arr="dsmArr" @commit="dsmLogSend"></dsmP>
      </el-tab-pane>
      <el-tab-pane label="轮胎状态监测参数设置">
        <tireP :arr="tireArr" @commit="tireLogSend"></tireP>
      </el-tab-pane>
      <el-tab-pane label="盲区监测参数设置">
        <blindP :arr="blindArr" @commit="blindLogSend"></blindP>
      </el-tab-pane>
      <el-tab-pane label="激烈驾驶参数设置">
        <fierceP :arr="fierceArr" @commit="fierceLogSend"></fierceP>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import adasP from './components/adasP.vue'
import dsmP from './components/dsmP.vue'
import tireP from './components/tireP.vue'
import blindP from './components/blindP.vue'
import fierceP from './components/fierceP.vue'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import { returnVideoArr } from '@/common/utils/index'
export default {
  components:{
    adasP,
    dsmP,
    tireP,
    blindP,
    fierceP
  },
  props:{
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      activeName:'first',
      adasArr:null,
      dsmArr:null,
      tireArr:null,
      blindArr:null,
      fierceArr:null,
      terminalNos: [],
      vehicleInfos: [],
      deviceType:sessionStorage.getItem('system'),
    }
  },
  methods: {
    tireLogSend(children){
      //轮胎检测参数设置
      this.allSend('tire',children);
    },
    fierceLogSend(children){
      this.allSend('fierce',children);
    },
    blindLogSend(children){
      this.allSend('blind',children);
    },
    dsmLogSend(children){
      //dsm参数设置
      this.allSend('dsm',children);
    },
    adasLogSend(children){//adas
      this.allSend('adas',children);
    },
    allSend(code,arr){     
      const terminalNos = []
      const vehicleInfos = []
      let ids = this.data.id;
      queryDeviceByIds({
        ids,
        system: this.deviceType
      }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos
          const params = []
          //指令下发
          code==='adas'&& params.push(0xF364,arr)
          code === 'dsm' && params.push(0xF365,arr)
          code === 'tire' && params.push(0xF366,arr)
          code === 'blind' && params.push(0xF367,arr)
          code === 'fierce' && params.push(0xF370,arr)
          //指令下发
          this.$yhsdp.GBAdasConfig(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    
  },
  created(){
    if(this.settingValue){
      this.adasArr = this.settingValue.adasArr;
      this.dsmArr = this.settingValue.dsmArr;
      this.tireArr = this.settingValue.tireArr;
      this.blindArr = this.settingValue.blindArr;
      this.fierceArr = this.settingValue.fierceArr;
    }
  }
}
</script>
<style lang="scss" scoped>
.advanced {
  height: 55vh;
  overflow-y: auto;
}
</style>