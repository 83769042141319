var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "limit-height" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form terminal-form",
          attrs: {
            model: _vm.form,
            inline: true,
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                _vm._v(_vm._s(_vm.data.name))
              ])
            : _vm._e(),
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "终端号：" } }, [
                _vm._v(_vm._s(_vm.formatTelNo(_vm.data.videos)))
              ])
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 音视频参数定义 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveInput }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm2",
          staticClass: "demo-form-inline dialog-form selfclass",
          attrs: {
            model: _vm.addForm2,
            inline: true,
            rules: _vm.formRules,
            "label-width": "140px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "实时流编码模式：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.encodeMode,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "encodeMode", $$v)
                    },
                    expression: "addForm2.encodeMode"
                  }
                },
                _vm._l(_vm.encodeModeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实时流分辨率：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.resolutionRatio,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "resolutionRatio", $$v)
                    },
                    expression: "addForm2.resolutionRatio"
                  }
                },
                _vm._l(_vm.resolutionRatioList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实时流关键帧间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 1000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.realInterval,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "realInterval", $$v)
                  },
                  expression: "addForm2.realInterval"
                }
              }),
              _c("span", [_vm._v("1-1000")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实时流目标帧率：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.targetFrame,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "targetFrame", $$v)
                  },
                  expression: "addForm2.targetFrame"
                }
              }),
              _c("span", [_vm._v("1-120")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实时流目标码率：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.targetCode,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "targetCode", $$v)
                  },
                  expression: "addForm2.targetCode"
                }
              }),
              _c("span", [_vm._v("kbps")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储流编码模式：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.saveEncodeMode,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "saveEncodeMode", $$v)
                    },
                    expression: "addForm2.saveEncodeMode"
                  }
                },
                _vm._l(_vm.encodeModeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储流分辨率：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.saveResolutionRatio,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "saveResolutionRatio", $$v)
                    },
                    expression: "addForm2.saveResolutionRatio"
                  }
                },
                _vm._l(_vm.resolutionRatioList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储流关键帧间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 1000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.saveInterval,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "saveInterval", $$v)
                  },
                  expression: "addForm2.saveInterval"
                }
              }),
              _c("span", [_vm._v("1-1000")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储流目标帧率：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.saveTargetFrame,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "saveTargetFrame", $$v)
                  },
                  expression: "addForm2.saveTargetFrame"
                }
              }),
              _c("span", [_vm._v("1-120")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "存储流目标码率：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.savetargetCode,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "savetargetCode", $$v)
                  },
                  expression: "addForm2.savetargetCode"
                }
              }),
              _c("span", [_vm._v("kbps")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用音视频输出：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.isAVOpen,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "isAVOpen", $$v)
                    },
                    expression: "addForm2.isAVOpen"
                  }
                },
                _vm._l(_vm.openList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { width: "100%" },
              attrs: { label: "OSD字幕叠加设置：" }
            },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.addForm2.osdConfig,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "osdConfig", $$v)
                    },
                    expression: "addForm2.osdConfig"
                  }
                },
                _vm._l(_vm.osdOptions, function(ele) {
                  return _c(
                    "el-checkbox",
                    { key: ele.value, attrs: { label: ele.value } },
                    [_vm._v(_vm._s(ele.label))]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }