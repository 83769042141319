var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "tire"
    },
    [
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 轮胎状态监测报警配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.commit }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm,
            inline: true,
            "label-width": "160px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "轮胎规格型号：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入内容" },
                model: {
                  value: _vm.addForm.model,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "model", $$v)
                  },
                  expression: "addForm.model"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "胎压单位：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.unit,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "unit", $$v)
                    },
                    expression: "addForm.unit"
                  }
                },
                _vm._l(_vm.unitList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "正常胎压值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pressure,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pressure", $$v)
                  },
                  expression: "addForm.pressure"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "单位同胎压单位",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "胎压不平衡门限：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.unbalanced,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "unbalanced", $$v)
                  },
                  expression: "addForm.unbalanced"
                }
              }),
              _vm._v("% ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "慢漏气门限：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.slow,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "slow", $$v)
                  },
                  expression: "addForm.slow"
                }
              }),
              _vm._v("% ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "低压阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.low,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "low", $$v)
                  },
                  expression: "addForm.low"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "单位同胎压单位",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "高压阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.heigh,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "heigh", $$v)
                  },
                  expression: "addForm.heigh"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "单位同胎压单位",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "高温阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.temperature,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "temperature", $$v)
                  },
                  expression: "addForm.temperature"
                }
              }),
              _vm._v("℃ ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "电压阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 100,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.voltage,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "voltage", $$v)
                  },
                  expression: "addForm.voltage"
                }
              }),
              _vm._v("% ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "定时上报时间间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 3600,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.upTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "upTime", $$v)
                  },
                  expression: "addForm.upTime"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }