<template>
  <el-row class="terminalParameter">
    <el-form
      ref="form"
      :model="form"
      label-width="210px"
      size="small"
      :rules="formRules"
      class="operationForm"
    >
      <el-col :span="24" v-if="isBatch">
        <h4>选择车辆</h4>
      </el-col>
      <!-- 公司车队： -->
      <el-col :md="12" :lg="12" :xl="12" v-if="isBatch">
        <el-form-item label="公司车队：" :prop="form.vehIds.length===0 ? 'companyIds':''">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="true"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
      </el-col>

      <!-- 选择车辆： -->
      <el-col :md="12" :lg="12" :xl="12" v-if="isBatch">
        <el-form-item label="选择车辆：" v-if="isBatch" :prop="form.companyIds.length===0 ? 'vehIds':''">
          <car-tree @getData="getVehIds"></car-tree>
        </el-form-item>
      </el-col>
      <!------------------------------------运营汇报策略配置------------------------------------------------------------------->

      <el-col :span="24">
        <h4>运营汇报策略配置</h4>
      </el-col>
      <!-- 位置汇报策略： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="位置汇报策略：">
          <el-select v-model="form.locationReportingStrategy" clearable>
            <el-option label="定时汇报" :value="0"></el-option>
            <el-option label="定距汇报" :value="1"></el-option>
            <el-option label="定时+定距汇报" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <!--  位置汇报方案：-->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="位置汇报方案：">
          <el-select v-model="form.locationReportingPlan" clearable>
            <el-option label="ACC状态" :value="0"></el-option>
            <el-option label="根据空重车状态" :value="1"></el-option>
            <el-option label="根据登录状态 + ACC状态" :value="2"></el-option>
            <el-option label="根据登录状态 + 空重车状态" :value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <!-- 未登录汇报时间间隔 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="未登录汇报时间间隔(s)：">
          <el-input-number
            controls-position="right"
            :min="0"
            v-model="form.outLoginReportingInterval"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <!-- ACC ON汇报时间间隔（s）： -->
      <el-col :md="12" :lg="12" :xl="8">
        <el-form-item label="ACC ON汇报时间间隔（s）：">
          <el-input-number controls-position="right" :min="0" v-model="form.accOnReportingInterval"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- ACC OFF汇报时间间隔（s） -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="ACC OFF汇报时间间隔（s）：">
          <el-input-number
            controls-position="right"
            :min="0"
            v-model="form.accOFFReportingInterval"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 空车汇报时间间隔（s）： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="空车汇报时间间隔（s）：">
          <el-input-number
            controls-position="right"
            :min="0"
            v-model="form.emptyCarReportingInterval"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 重车汇报时间间隔（s）： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="重车汇报时间间隔（s）：">
          <el-input-number
            controls-position="right"
            :min="0"
            v-model="form.heavyCarReportingInterval"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 休眠汇报时间间隔（s）： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="休眠汇报时间间隔（s）：">
          <el-input-number
            controls-position="right"
            :min="0"
            v-model="form.dormancyReportingInterval"
          ></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 紧急报警汇报时间间隔（s）： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="紧急报警汇报时间间隔（s）：">
          <el-input-number controls-position="right" :min="0" v-model="form.alarmReportingInterval"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 未登录汇报距离 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="未登录汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.outLoginReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- ACC On汇报距离(m) -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="ACC On汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.accOnReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- ACC OFF汇报距离(m) -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="ACC OFF汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.accOFFReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 空车汇报距离 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="空车汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.emptyCarReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 重车汇报距离 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="重车汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.heavyCarReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 休眠汇报距离 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="休眠汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.dormancyReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 紧急报警汇报距离 -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="紧急报警汇报距离(m)：">
          <el-input-number controls-position="right" :min="0" v-model="form.alarmReportDistance"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 拐点补传角度： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="拐点补传角度：">
          <el-input-number controls-position="right" :min="0" v-model="form.inflectionAngle"></el-input-number>
        </el-form-item>
      </el-col>
      <!-- 终端心跳间隔-->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="终端心跳间隔：">
          <el-col :span="10">
            <el-input-number controls-position="right" v-model="form.intervalTime" :min="0"></el-input-number>
          </el-col>
          <el-col :span="14" class="hint" title="ACC开30s/次，ACC关60s/次">ACC开30s/次，ACC关60s/次</el-col>
        </el-form-item>
      </el-col>
      <!------------------------------------行车配置------------------------------------------------------------------->
      <!--  -->
      <el-col :span="24">
        <h4>行车配置</h4>
      </el-col>
      <!-- 最高速度门限（km /h）-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="最高速度门限（km/h）：">
          <el-col :span="10">
            <el-input-number controls-position="right" v-model="form.speedLimit" :min="0"></el-input-number>
          </el-col>
          <el-col :span="14" class="hint">单位: km/h</el-col>
        </el-form-item>
      </el-col>

      <!-- 超速持续时间（s）：-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="超速持续时间（s）：">
          <el-col :span="10">
            <el-input-number
              controls-position="right"
              v-model="form.overSpeedContinueTime"
              :min="0"
            ></el-input-number>
          </el-col>
          <el-col :span="14" class="hint" title="持续超速超过该时间则产生报警">持续超速超过该时间则产生报警</el-col>
        </el-form-item>
      </el-col>
      <!-- 连续驾驶时限（分钟）-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="连续驾驶时限（分钟）：">
          <el-col :span="10">
            <el-input-number
              controls-position="right"
              v-model="form.continueDriveTime"
              :min="1"
              :max="255"
            ></el-input-number>
          </el-col>
          <el-col
            :span="14"
            class="hint"
            title="单位: min(最小为1分钟,最大为4小时15分钟(255分钟)"
          >单位: min(最小为1分钟,最大为4小时15分钟(255分钟)</el-col>
        </el-form-item>
      </el-col>
      <!-- 当天累计驾驶时限（分钟）：-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="当天累计驾驶时限（分钟）：">
          <el-col :span="10">
            <el-input-number controls-position="right" v-model="form.driverTotalTime" :min="1"></el-input-number>
          </el-col>
          <el-col :span="14" class="hint" title="单位: min(最小为1分钟)">单位: min(最小为1分钟)</el-col>
        </el-form-item>
      </el-col>
      <!-- 最小休息时间（分钟）：-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="最小休息时间（分钟）：">
          <el-col :span="10">
            <el-input-number controls-position="right" v-model="form.minSleepTime" :min="0"></el-input-number>
          </el-col>
          <el-col :span="14" class="hint">单位: min</el-col>
        </el-form-item>
      </el-col>
      <!-- 最长停车时间（分钟：-->
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item label="最长停车时间（分钟）：">
          <el-col :span="10">
            <el-input-number controls-position="right" v-model="form.maximumStopTime" :min="0"></el-input-number>
          </el-col>
          <el-col :span="14" class="hint">单位: min</el-col>
        </el-form-item>
      </el-col>
      
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item>
          <el-button type="primary" size="mini" @click="saveInput">保存</el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
</template>
<script>
import api from '@/api/static/terminal'
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import { returnVideoArr,checkString, checkIpAddress, checkPositiveInteger } from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆id */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // }
  },

  data () {
    return {
      terminalNos: [],
      vehicleInfos: [],
      deviceType:'',
      form: {
        vehIds: [],
        companyIds: [],
        locationReportingStrategy: '', // 位置汇报策略：
        locationReportingPlan: '', // 位置汇报方案：
        outLoginReportingInterval: '', // 未登录汇报时间间隔
        accOnReportingInterval: '', // ACC On汇报时间间隔（s）
        accOFFReportingInterval: '', // ACC OFF汇报时间间隔（s）
        emptyCarReportingInterval: '', // 空车汇报时间间隔（s）：
        heavyCarReportingInterval: '', // 重车汇报时间间隔（s）：
        dormancyReportingInterval: '', // 休眠汇报时间间隔（s）：
        alarmReportingInterval: '', // 紧急报警汇报时间间隔（s）：
        outLoginReportDistance: '', // 未登录汇报距离
        accOnReportDistance: '', // ACC On汇报距离
        accOFFReportDistance: '', // ACC OFF汇报距离
        emptyCarReportDistance: '', // 空车汇报距离
        heavyCarReportDistance: '', // 重车汇报距离
        dormancyReportDistance: '', // 休眠汇报距离
        alarmReportDistance: '', // 紧急报警汇报距离
        inflectionAngle: '', // 拐点补传角度：
        speedLimit: '', // 最高速度门限（km
        overSpeedContinueTime: '', // 超速持续时间（s）：
        continueDriveTime: '', // 连续驾驶时限（分钟）：
        driverTotalTime: '', // 当天累计驾驶时限（分钟）：
        minSleepTime: '', // 最小休息时间（分钟）：
        maximumStopTime: '', // 最长停车时间（分钟：
        intervalTime: '' // 终端心跳间隔：
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.vehicleInfos = []
      this.form.vehIds = vehStrIds
      if (vehStrIds.length > 0)
        this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 获取已存的提示内容 */
    queryDeviceParams (data) {
      queryDeviceParams(data).then(res => {
        if (res.code === 1000) {
          this.form = { ...this.form, ...res.data }
          // this.form.locationReportingStrategy = Number(res.data.params.locationReportingStrategy)
          // this.form.locationReportingPlan = Number(res.data.params.locationReportingPlan)
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      let valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      let vArr = []
      for (let v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput () {
      this.$refs.form.validate(valid => {        
        if (valid) {
          let parameter = 0
          api.AlarmPromptOption.forEach(item => {
            if (
              this.form[item] === null ||
              this.form[item] === undefined ||
              this.form[item] === 'undefined' ||
              this.form[item] === ''
            ) { parameter = parameter + 1 }
          })
          if (parameter === api.AlarmPromptOption.length) {
            this.$message.warning(`请至少下发一个指令`)
            return
          }
          let terminalNos = []
          const vehicleInfos = []
          let ids = this.form.companyIds.concat(this.form.vehIds)
          queryDeviceByIds({
            ids: ids.join(','),
            system: this.deviceType
          }).then(res => {
            if (res.code === 1000) {
              if (res.data.length === 0) {
                this.$message.warning('车辆没有绑定终端')
                return
              }
              res.data.forEach(element => {
                let deviceNo = returnVideoArr(element.videos, true)
                terminalNos.push(deviceNo)
                vehicleInfos.push(`${element.cph}#${element.companyName}`)
              })
              this.terminalNos = terminalNos
              this.vehicleInfos = vehicleInfos
              // if (this.terminalNos.length > this.number) {
              //   this.$message.warning(`最多只能对${this.number}个终端批量下发指令`)
              //   return
              // }
              let params = []
              let formParams = { ...this.form }
              delete formParams.vehIds
              delete formParams.companyIds
              let reqData = {
                deviceNos: terminalNos, // 下发的终端
                params: formParams // 报警提示类型
              }
              setDeviceParams(reqData).then(res => { })
              let {
                locationReportingStrategy,
                locationReportingPlan,
                outLoginReportingInterval,
                accOnReportingInterval,
                accOFFReportingInterval,
                emptyCarReportingInterval,
                heavyCarReportingInterval,
                dormancyReportingInterval,
                alarmReportingInterval,
                outLoginReportDistance,
                accOnReportDistance,
                accOFFReportDistance,
                emptyCarReportDistance,
                heavyCarReportDistance,
                dormancyReportDistance,
                alarmReportDistance,
                inflectionAngle,
                speedLimit,
                continueDriveTime,
                overSpeedContinueTime,
                driverTotalTime,
                minSleepTime,
                maximumStopTime,
                intervalTime
              } = this.form
              if (locationReportingStrategy === 0 || locationReportingStrategy) params.push(0x0020, locationReportingStrategy)
              if (locationReportingPlan === 0 || locationReportingPlan) params.push(0x0021, locationReportingPlan)
              if (outLoginReportingInterval) params.push(0x0022, outLoginReportingInterval)
              if (accOnReportingInterval) params.push(0x0024, accOnReportingInterval)
              if (accOFFReportingInterval) params.push(0x0023, accOFFReportingInterval)
              if (emptyCarReportingInterval) params.push(0x0025, emptyCarReportingInterval)
              if (heavyCarReportingInterval) params.push(0x0026, heavyCarReportingInterval)
              if (dormancyReportingInterval) params.push(0x0027, dormancyReportingInterval)
              if (alarmReportingInterval) params.push(0x0028, alarmReportingInterval)
              if (outLoginReportDistance) params.push(0x0029, outLoginReportDistance)
              if (accOnReportDistance) params.push(0x002B, accOnReportDistance)
              if (accOFFReportDistance) params.push(0x002A, accOFFReportDistance)
              if (emptyCarReportDistance) params.push(0x002C, emptyCarReportDistance)
              if (heavyCarReportDistance) params.push(0x002D, emptyCarReportDistance)
              if (dormancyReportDistance) params.push(0x002E, dormancyReportDistance)
              if (alarmReportDistance) params.push(0x002F, alarmReportDistance)
              if (inflectionAngle) params.push(0x0030, inflectionAngle)
              // if (speedLimit === 0 || speedLimit) params.push(0x0055, speedLimit)
              if (speedLimit) params.push(0x0055, speedLimit)
              if (continueDriveTime) params.push(0x0057, continueDriveTime);//连续驾驶时限
              if (overSpeedContinueTime) params.push(0x0056, overSpeedContinueTime);//超速持续时间（s）
              if (driverTotalTime) params.push(0x005A, driverTotalTime)
              if (minSleepTime === 0 || minSleepTime) params.push(0x0058, minSleepTime)
              if (maximumStopTime) params.push(0x0059, maximumStopTime)
              if (intervalTime === 0 || intervalTime) params.push(0x0001, intervalTime)
              this.$yhsdp.GBSetParameters([terminalNos,this.vehicleInfos], params, this.deviceType)
              this.$emit('close')
            }
          })
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminalParameter {
  .operationForm {
    .hint {
      text-indent: 46px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.el-input-group--append .el-input__inner {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
</style>
