var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "limit-height" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form terminal-form",
          attrs: { model: _vm.form, inline: true, size: "small" }
        },
        [
          _c("el-form-item", { attrs: { label: "车牌号：" } }, [
            _vm._v(_vm._s(_vm.data.name))
          ]),
          _c("el-form-item", { attrs: { label: "终端号：" } }, [
            _vm._v(_vm._s(_vm.formatTelNo(_vm.data.videos)))
          ])
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 载重监测参数 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveInput }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm2",
          staticClass: "demo-form-inline dialog-form terminal-form selfclass",
          attrs: {
            model: _vm.addForm2,
            inline: true,
            "label-width": "140px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车辆限定总质量：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 20000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.totalQuality,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "totalQuality", $$v)
                  },
                  expression: "addForm2.totalQuality"
                }
              }),
              _c("span", [_vm._v("Kg")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0-20000",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发动机最大扭矩：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.maxTorque,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "maxTorque", $$v)
                  },
                  expression: "addForm2.maxTorque"
                }
              }),
              _c("span", [_vm._v("N·m(牛*米)")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车速1CAN报文ID：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm2.speedMsgID1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "speedMsgID1", $$v)
                  },
                  expression: "addForm2.speedMsgID1"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车速2CAN报文ID：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm2.speedMsgID2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "speedMsgID2", $$v)
                  },
                  expression: "addForm2.speedMsgID2"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "检测设备安装方向：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.addForm2.direction,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "direction", $$v)
                    },
                    expression: "addForm2.direction"
                  }
                },
                _vm._l(_vm.directionList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "载重计算周期：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.period,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "period", $$v)
                  },
                  expression: "addForm2.period"
                }
              }),
              _c("span", [_vm._v("10毫秒")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "10-100",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设置计算滤波系数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.coefficient,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "coefficient", $$v)
                  },
                  expression: "addForm2.coefficient"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "值越大，载重计算结果跳动越小",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "加速度初始值：" } }, [
            _c(
              "div",
              [
                _c("el-input-number", {
                  attrs: {
                    "controls-position": "right",
                    min: 0,
                    placeholder: "请输入"
                  },
                  model: {
                    value: _vm.addForm2.upSpeedInitial,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm2, "upSpeedInitial", $$v)
                    },
                    expression: "addForm2.upSpeedInitial"
                  }
                }),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content:
                        "根据车辆静止在平直道路上时加速度传感器的原始值对该值进行修正，直至加速度传感器原始值读数接近0",
                      placement: "top-start"
                    }
                  },
                  [_c("i", { staticClass: "el-icon-warning" })]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 限高监测参数 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveConfiguration }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm3",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm3,
            inline: true,
            "label-width": "90px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车身高度：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 5000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.height,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "height", $$v)
                  },
                  expression: "addForm3.height"
                }
              }),
              _c("span", [_vm._v("毫米")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0-5000",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车身宽度：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 5000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.width,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "width", $$v)
                  },
                  expression: "addForm3.width"
                }
              }),
              _c("span", [_vm._v("毫米")]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0-5000",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }