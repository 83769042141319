
<template>
  <!-- 人工确认解除报警 -->
  <div class="vehicle-seting-page">
    <el-form
      ref="form"
      :model="form"
      label-width="140px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 已选车辆终端 -->
      <el-form-item label>
        <el-row>
          <el-col :span="12">车牌号：{{ data.name }}</el-col>
          <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="确认解除报警类型：">
        <el-checkbox-group v-model="form.checkedAlarm">
          <el-checkbox
            v-for="ele in alarmOptions"
            :label="ele.value"
            :key="ele.value"
            :value="ele.value"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="cancle()">取消</el-button>
        <el-button type="primary" size="small" @click="save()">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { returnVideoArr } from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'

export default {
  
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  watch: {},
  mounted () { },
  data () {
    return {
      form: {
        checkedAlarm: [],
      },
      terminalNos: [],
      alarmOptions: [
        { value: '紧急报警', label: '紧急报警' },
        { value: '危险报警', label: '危险报警' },
        { value: '进出区域报警', label: '进出区域报警' },
        { value: '进出路线报警', label: '进出路线报警' },
        { value: '路线行驶时长不足/过长报警', label: '路线行驶时长不足/过长报警' },
        { value: '车辆非法点火报警', label: '车辆非法点火报警' },
        { value: '车辆非法位移报警', label: '车辆非法位移报警' }
      ],
      deviceType: sessionStorage.getItem('system'),

    }
  },

  methods: {
    save () {
      if (this.form.checkedAlarm.length < 1) {
        this.$message.warning('请选择解除报警类型')
        return
      }
      const terminalNos = []
      const vehicleInfos = []
      const params = [this.form.checkedAlarm.join('|')];
      let ids = this.data.id;
      queryDeviceByIds({
        ids,
        system: this.deviceType
      }).then(res => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos
          this.$yhsdp.GBArtificialRemoveAlarm(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    cancle () {
      this.$emit('close', true)
    },

    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {

  },
}
</script>
<style lang="scss" scoped>
.vehicle-seting-page {
  .el-checkbox-group {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    label {
      margin-right: 20px !important;
      margin-left: 0 !important;
    }
  }
}
</style>
