<template>
  <!-- 设置音视频参数 -->
  <div class="limit-height">
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      size="small"
      :rules="formRules"
      class="dialog-form terminal-form"
    >
      <el-form-item label="车牌号：" v-if="!isBatch">{{ data.name }}</el-form-item>
      <el-form-item label="终端号：" v-if="!isBatch">{{ formatTelNo(data.videos) }}</el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <!-- 选择车辆： -->
      <!--  -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
    </el-form>
    <!--  音视频参数定义-->
    <p class="form-title form-title2">
      音视频参数定义
      <el-button type="primary" size="mini" @click="saveInput">发送指令</el-button>
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      ref="addForm2"
      :rules="formRules"
      label-width="140px"
      label-position="left"
      class="demo-form-inline dialog-form selfclass"
    >
      <el-form-item label="实时流编码模式：">
        <el-select v-model="addForm2.encodeMode" placeholder="请选择">
          <el-option
            v-for="item in encodeModeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实时流分辨率：">
        <el-select v-model="addForm2.resolutionRatio" placeholder="请选择">
          <el-option
            v-for="item in resolutionRatioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实时流关键帧间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.realInterval"
          :min="1"
          :max="1000"
          placeholder="请输入"
        ></el-input-number>
        <span>1-1000</span>
      </el-form-item>
      <el-form-item label="实时流目标帧率：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.targetFrame"
          :min="1"
          :max="120"
          placeholder="请输入"
        ></el-input-number>
        <span>1-120</span>
      </el-form-item>
      <el-form-item label="实时流目标码率：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.targetCode"
          :min="1"
          placeholder="请输入"
        ></el-input-number>
        <span>kbps</span>
      </el-form-item>
      <el-form-item label="存储流编码模式：">
        <el-select v-model="addForm2.saveEncodeMode" placeholder="请选择">
          <el-option
            v-for="item in encodeModeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="存储流分辨率：">
        <el-select v-model="addForm2.saveResolutionRatio" placeholder="请选择">
          <el-option
            v-for="item in resolutionRatioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="存储流关键帧间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.saveInterval"
          :min="1"
          :max="1000"
          placeholder="请输入"
        ></el-input-number>
        <span>1-1000</span>
      </el-form-item>
      <el-form-item label="存储流目标帧率：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.saveTargetFrame"
          :min="1"
          :max="120"
          placeholder="请输入"
        ></el-input-number>
        <span>1-120</span>
      </el-form-item>
      <el-form-item label="存储流目标码率：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.savetargetCode"
          :min="1"
          placeholder="请输入"
        ></el-input-number>
        <span>kbps</span>
      </el-form-item>
      <el-form-item label="是否启用音视频输出：">
        <el-select v-model="addForm2.isAVOpen" placeholder="请选择">
          <el-option
            v-for="item in openList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="OSD字幕叠加设置：" style="width:100%;">
        <el-checkbox-group v-model="addForm2.osdConfig">
          <el-checkbox
            v-for="ele in osdOptions"
            :label="ele.value"
            :key="ele.value"
          >{{ele.label}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import {
  returnVideoArr,
} from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },

  },

  data () {
    return {
      terminalNos: [],
      vehicleInfos: [],

      form: {
        vehIds: [],
        companyIds: []
      },
      addForm2: {
        encodeMode: 1,//实时流编码模式
        resolutionRatio: 1,//实时流分辨率
        realInterval: 200,//实时流关键帧间隔
        targetFrame: 100,//实时流目标帧率
        targetCode: 200,//实时流目标码率
        saveEncodeMode:0,//存储流编码模式
        saveResolutionRatio: 1,//存储流分辨率
        saveInterval: 3,//存储流关键帧间隔
        saveTargetFrame:200,//存储流目标帧率
        savetargetCode:200,//存储流目标码率
        isAVOpen:1,//是否启用音视频输出
        osdConfig:[],//OSD字幕叠加设置
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],

      },
      formRules2: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
      },
      encodeModeList: [
        { dictValue: 'CBR(固定码率)', dictCode: 0 },
        { dictValue: 'VBR(可变码率)', dictCode: 1 },
        { dictValue: 'ABR(平均码率)', dictCode: 2 },
      ],
      resolutionRatioList: [
        { dictValue: 'QCIF', dictCode: 0 },
        { dictValue: 'CIF', dictCode: 1 },
        { dictValue: 'WCIF', dictCode: 2 },
        { dictValue: 'D1', dictCode: 3 },
        { dictValue: 'WD1', dictCode: 4 },
        { dictValue: '720P', dictCode: 5 },
        { dictValue: '1080P', dictCode: 6 },
      ],
      openList:[
        { dictValue: '是', dictCode: 1 },
        { dictValue: '否', dictCode: 0 },
      ],
      osdOptions:[
        {value:0,label:'日期和时间'},
        {value:1,label:'车牌号码'},
        {value:2,label:'逻辑通道号'},
        {value:3,label:'经纬度'},
        {value:4,label:'行驶记录速度'},
        {value:5,label:'卫星定位速度'},
        {value:6,label:'连续驾驶时间'},
      ],
    }
  },

  methods: {
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.vehicleInfos = []
      this.form.vehIds = vehStrIds
      // if (vehStrIds.length > 0)
      //   this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 获取已存的提示内容 */
    queryDeviceParams (data) {
      queryDeviceParams(data).then((res) => {
        if (res.code === 1000) {
          this.addForm2 = { ...this.addForm2, ...res.data }
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.addForm2.validate((valid) => {
            if (valid) {
              
              const terminalNos = []
              const vehicleInfos = []
              let singleArr = new Array(8).fill(0);//格式化为8位二进制格式
              let ids = this.form.companyIds.concat(this.form.vehIds)

              queryDeviceByIds({
                ids: ids.join(','),
                system: this.deviceType
              }).then((res) => {
                if (res.code === 1000) {
                  if (res.data.length === 0) {
                    this.$message.warning('车辆没有绑定终端')
                    return
                  }
                  res.data.forEach((element) => {
                    let deviceNo = returnVideoArr(element.videos, true)
                    terminalNos.push(deviceNo)
                    vehicleInfos.push(`${element.cph}#${element.companyName}`)
                  })
                  this.terminalNos = terminalNos
                  this.vehicleInfos = vehicleInfos

                  const params = []
                  const reqData = {
                    deviceNos: terminalNos, // 下发的终端
                    terminalParams: { ...this.addForm2 } // 报警提示类型
                  }
                  const {
                    encodeMode,
                    resolutionRatio,
                    realInterval,
                    targetFrame,
                    targetCode,
                    saveEncodeMode,
                    saveResolutionRatio,
                    saveInterval,
                    saveTargetFrame,
                    savetargetCode,
                    isAVOpen,
                    osdConfig
                  } = this.addForm2
                  osdConfig.forEach(item=>{
                    singleArr[item]=1;
                  })
                 let singleStr = parseInt(singleArr.reverse().join(''),2);
                  params.push(0x0075, [
                    encodeMode,
                    resolutionRatio,
                    realInterval,
                    targetFrame,
                    targetCode,
                    saveEncodeMode,
                    saveResolutionRatio,
                    saveInterval,
                    saveTargetFrame,
                    savetargetCode,
                    singleStr,
                    isAVOpen                    
                  ]);
                  this.$yhsdp.GBLimitHeightConfig(
                    [terminalNos, this.vehicleInfos],
                    params,
                    this.deviceType
                  )
                  this.$emit('close')
                }
              })
            }
          })
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
    }
    this.deviceType = sessionStorage.getItem('system')
    this.addForm2 = {...this.settingValue}
  }
}
</script>
<style lang="scss" scoped>
.limit-height {
  height: 55vh;
  overflow-y: auto;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .selfclass.dialog-form.el-form .el-form-item{
      width: 50%;
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
