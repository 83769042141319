var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "holder-control" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form",
          attrs: {
            model: _vm.form,
            "label-width": "100px",
            "label-position": "left",
            size: "small"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "车牌号：" } }, [
            _vm._v(_vm._s(_vm.data.name))
          ]),
          _c("el-form-item", { attrs: { label: "终端号：" } }, [
            _vm._v(_vm._s(_vm.formatTelNo(_vm.data.videos)))
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "摄像头：" } },
            _vm._l(_vm.videoNumber, function(i) {
              return _c(
                "el-button",
                {
                  key: i,
                  attrs: {
                    type: "text",
                    size: "small",
                    sort: _vm.channel === i ? "" : "grey"
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleChannelClick(i)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "iconfont its_shexiangtou" }),
                  _vm._v(" 0" + _vm._s(i) + " ")
                ]
              )
            }),
            1
          ),
          _c("el-form-item", { attrs: { label: "旋转：" } }, [
            _c("div", { staticClass: "rotate" }, [
              _c("i", {
                staticClass: "iconfont its_shang",
                class: _vm.chooseActive === 1 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(1)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_youshang",
                class: _vm.chooseActive === 2 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(2)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_you1",
                class: _vm.chooseActive === 3 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(3)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_youxia",
                class: _vm.chooseActive === 4 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(4)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_xia",
                class: _vm.chooseActive === 5 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(5)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_zuoxia",
                class: _vm.chooseActive === 6 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(6)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_zuo1",
                class: _vm.chooseActive === 7 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(7)
                  }
                }
              }),
              _c("i", {
                staticClass: "iconfont its_zuoshang",
                class: _vm.chooseActive === 8 ? "active" : "",
                on: {
                  click: function($event) {
                    return _vm.Rotate(8)
                  }
                }
              }),
              _c("div", { staticClass: "i-box" }, [
                _c("i", { staticClass: "iconfont its_xuanzhuan" })
              ])
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "速度值(0-255):" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 255,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.speed,
                  callback: function($$v) {
                    _vm.speed = $$v
                  },
                  expression: "speed"
                }
              })
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "更多操作:" } }, [
            _c("div", { staticClass: "more" }, [
              _c("div", { staticClass: "item" }, [
                _c("i", {
                  staticClass: "iconfont its_zengjia",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("焦距", 1)
                    }
                  }
                }),
                _c("span", [_vm._v("焦距")]),
                _c("i", {
                  staticClass: "iconfont its_jianshao",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("焦距", 2)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "item" }, [
                _c("i", {
                  staticClass: "iconfont its_zengjia",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("光圈", 1)
                    }
                  }
                }),
                _c("span", [_vm._v("光圈")]),
                _c("i", {
                  staticClass: "iconfont its_jianshao",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("光圈", 2)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "item" }, [
                _c("i", {
                  staticClass: "iconfont its_zengjia",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("变倍", 1)
                    }
                  }
                }),
                _c("span", [_vm._v("变倍")]),
                _c("i", {
                  staticClass: "iconfont its_jianshao",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("变倍", 2)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "item" }, [
                _c("i", {
                  staticClass: "iconfont its_zuo",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction2("雨刷", 3)
                    }
                  }
                }),
                _c("span", [_vm._v("雨刷")]),
                _c("i", {
                  staticClass: "iconfont its_1hua",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction2("雨刷", 4)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "item" }, [
                _c("i", {
                  staticClass: "iconfont its_zuo",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("红外补光", 3)
                    }
                  }
                }),
                _c("span", [_vm._v("红外补光")]),
                _c("i", {
                  staticClass: "iconfont its_1hua",
                  on: {
                    click: function($event) {
                      return _vm.MoreAction("红外补光", 4)
                    }
                  }
                })
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }