<template>
  <div class="setAlarmShield">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      :inline="true"
      label-width="150px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
      <el-form-item v-for="(item, i) in list" :key="i">
        <el-switch
          v-model="form[i]"
          :active-text="item.name"
          active-value="1"
          inactive-value="0"
          >></el-switch
        >
      </el-form-item>
      <el-form-item></el-form-item>
      <el-form-item class="right-text">
        <el-button size="mini" @click="reset">取消</el-button>
        <el-button type="primary" size="mini" @click="saveInput"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import api from '@/api/static/terminal'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 屏蔽报警 | 关闭报警声音 */
    type: {
      type: Number,
      default: 0
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // },
    /** 是否批量操作 **/
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 设置的屏蔽报警值 **/
    settingValue: {
      type: String
    },
    /** 需下发的终端号 **/
    terminalNo: {
      type: Array,
      default: () => {
        return []
      }
    },
    /** 回显的车牌号与公司 **/
    vehicleInfos: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      value1: false,
      deviceType: '', // 协议类型
      form: {
        vehIds: [],
        companyIds: [],
        1: '0',
        6: '0'
      },
      list: api.setAlarmShieldGps,
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    // 获取车组数据
    getGroupIds(val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    saveInput() {
      // debugger
      this.$refs.form.validate((valid) => {
        if (valid) {
          const coefficient = this.type === 0 ? 0x0050 : 0x0051

          const data = { ...this.form }
          data.length = 32
          delete data.vehIds
          delete data.companyIds
          const param = Array.from(data).reverse().join('') // 二进制
          const value = parseInt(param, 2) // 十进制
          this.$yhsdp.GBSetAlarmSeting(
            [this.terminalNo, this.vehicleInfos],
            [coefficient, value.toString()],
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    reset() {
      this.$emit('close')
    }
  },
  mounted() {
    this.deviceType = sessionStorage.getItem('system');
    const settingValue = this.settingValue.split('').reverse();
    const obj = Object.fromEntries(settingValue.map((item, i) => [i, item]));
    this.form = { ...obj }
  }
}
</script>
<style lang="scss" scoped>
.right-text {
  display: flex;
  justify-content: flex-end;
  padding: 10px 2px;
}
</style>
