var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rollCall" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "公司车队：",
                prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
              }
            },
            [
              _c("companySelectForSearch", {
                ref: "companySelectForSearch",
                attrs: { multiple: true, searchable: true },
                on: { getValue: _vm.getGroupIds }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "选择车辆：",
                prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
              }
            },
            [
              _c("car-tree", {
                attrs: { label: "id" },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }