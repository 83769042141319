<template>
  <!-- 载重限高 -->
  <div class="limit-height">
    <el-form ref="form" :model="form" :inline="true" size="small" class="dialog-form terminal-form">
      <el-form-item label="车牌号：">{{ data.name }}</el-form-item>
      <el-form-item label="终端号：">{{ formatTelNo(data.videos) }}</el-form-item>
    </el-form>
    <!--  载重监测参数-->
    <p class="form-title form-title2">
      载重监测参数
      <el-button type="primary" size="mini" @click="saveInput">发送指令</el-button>
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      ref="addForm2"
      label-width="140px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form selfclass"
    >
      <el-form-item label="车辆限定总质量：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.totalQuality"
          :min="0"
          :max="20000"
          placeholder="请输入"
        ></el-input-number>
        <span>Kg</span>
        <el-tooltip class="item" effect="dark" content="0-20000" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="发动机最大扭矩：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.maxTorque"
          :min="1"
          placeholder="请输入"
        ></el-input-number>
        <span>N·m(牛*米)</span>
      </el-form-item>
      <el-form-item label="车速1CAN报文ID：">
        <el-input v-model="addForm2.speedMsgID1" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="车速2CAN报文ID：">
        <el-input v-model="addForm2.speedMsgID2" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="检测设备安装方向：">
        <el-select v-model="addForm2.direction" clearable placeholder="请选择">
          <el-option
            v-for="item in directionList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="载重计算周期：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.period"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <span>10毫秒</span>
        <el-tooltip class="item" effect="dark" content="10-100" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="设置计算滤波系数：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.coefficient"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="值越大，载重计算结果跳动越小" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="加速度初始值：">
        <div>
          <el-input-number
            controls-position="right"
            v-model="addForm2.upSpeedInitial"
            :min="0"
            placeholder="请输入"
          ></el-input-number>
          <el-tooltip class="item" effect="dark" content="根据车辆静止在平直道路上时加速度传感器的原始值对该值进行修正，直至加速度传感器原始值读数接近0" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
        </div>
      </el-form-item>
    </el-form>
    <!-- 限高监测参数 -->
    <p class="form-title form-title2">
      限高监测参数
      <el-button type="primary" size="mini" @click="saveConfiguration">发送指令</el-button>
    </p>
    <el-form
      :model="addForm3"
      :inline="true"
      ref="addForm3"
      label-width="90px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="车身高度：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.height"
          :min="0"
          :max="5000"
          placeholder="请输入"
        ></el-input-number>
        <span>毫米</span>
        <el-tooltip class="item" effect="dark" content="0-5000" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="车身宽度：">
        <el-input-number
          controls-position="right"
          v-model="addForm3.width"
          :min="0"
          :max="5000"
          placeholder="请输入"
        ></el-input-number>
        <span>毫米</span>
        <el-tooltip class="item" effect="dark" content="0-5000" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { setDeviceParams } from '@/api/lib/api'
import {
  returnVideoArr,
} from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
export default {
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },

  data () {
    return {
      terminalNos: [],
      vehicleInfos: [],

      form: {
        vehIds: [],
        companyIds: []
      },
      addForm2: {
        totalQuality: 30,//车辆限定总质量
        maxTorque: 6,//发动机最大扭矩
        speedMsgID1: '',//车速1CAN报文ID
        speedMsgID2: '',//车速2CAN报文ID
        direction: '',//检测设备安装方向
        period: 0,//载重计算周期
        coefficient: 3,//设置计算滤波系数
        upSpeedInitial: 3,//加速度初始值
      },
      addForm3: {
        height: '',//车身高度
        width: '',//车身宽度
      },
      directionList: [
        { dictValue: '安装朝向车辆驾驶室', dictCode: 0x01 },
        { dictValue: '安装朝向车辆尾部', dictCode: 0x02 },
        // { dictValue: '不修改此参数', dictCode: 0xFF },
      ]
    }
  },

  methods: {
    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },
    /** 载重 */
    saveInput () {
      const terminalNos = []
      const vehicleInfos = []
      let ids = this.data.id;
      queryDeviceByIds({
        ids,
        system: this.deviceType
      }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos

          const params = []
          const reqData = {
            deviceNos: terminalNos, // 下发的终端
            terminalParams: { ...this.addForm2 } // 报警提示类型
          }
          if (this.addForm2['direction'] === '' || this.addForm2['direction'] === '-') {
            this.addForm2['direction'] = 0xFF;
          }
          const {
            totalQuality,
            maxTorque,
            speedMsgID1,
            speedMsgID2,
            direction,
            period,
            coefficient,
            upSpeedInitial
          } = this.addForm2

          params.push(0xF372, [totalQuality,
            maxTorque,
            speedMsgID1,
            speedMsgID2,
            direction,
            period,
            coefficient,
            upSpeedInitial]);
          this.$yhsdp.GBLimitHeightConfig(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    /**限高 */
    saveConfiguration () {
      const terminalNos = []
      const vehicleInfos = []
      queryDeviceByIds({
        ids: this.data.id,
        system: this.deviceType
      }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos

          const params = []
          const reqData = {
            deviceNos: terminalNos, // 下发的终端
            terminalParams: { ...this.addForm3 } // 限高
          }
          const {
            height, width
          } = this.addForm3
          params.push(0xF373, [height, width]);
          this.$yhsdp.GBLimitHeightConfig(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          this.$emit('close')
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    this.addForm2 = { ...this.settingValue }

    this.addForm3 = {
      height: this.settingValue.height,
      width: this.settingValue.width
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.limit-height {
  height: 55vh;
  overflow-y: auto;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
/deep/.selfclass.el-form--inline .el-form-item__content {
  width: calc(100% - 140px);
}
</style>
