var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-seting-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            "label-width": "100px",
            rules: _vm.formRules,
            "label-position": "left"
          }
        },
        [
          !_vm.isBatch
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm._v("车牌号：" + _vm._s(_vm.data.name))
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm._v(
                          "终端号：" + _vm._s(_vm.formatTelNo(_vm.data.videos))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "    " } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "接驳" },
                            model: {
                              value: _vm.form.connectionConfig,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "connectionConfig", $$v)
                              },
                              expression: "form.connectionConfig"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0" } },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "夜间禁行" },
                            model: {
                              value: _vm.form.nightProhibition,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nightProhibition", $$v)
                              },
                              expression: "form.nightProhibition"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.cancle()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }