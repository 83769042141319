<template>
  <div class="sendInformation">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="96px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 已选车辆终端 -->
      <el-form-item label="车牌号：" v-if="!isBatch">
        <el-row>
          <el-col :span="9">{{ data.name }}</el-col>
          <el-col :span="15">终端号：{{ formatTelNo(data.videos) }}</el-col>
        </el-row>
      </el-form-item>
      <!-- 选择驾驶员： -->
      <el-form-item label="选择驾驶员：" prop="credentialsNos">
        <el-badge :value="form.driverIds.length" :hidden="!form.driverIds.length" class="item">
          <el-select
            v-model="driverArr"
            multiple
            filterable
            remote
            collapse-tags
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            :multiple-limit="50"
            :reserve-keyword="false"
            value-key="credentialsNo"
            @change="driverChange"
          >
            <el-option v-for="item in driverList" :key="item.id" :label="`${item.name}(${item.workNum})`" :value="item"></el-option>
          </el-select>
        </el-badge>
      </el-form-item>
      <el-form-item label="    ">
        <br />
        <el-button type="primary" size="small" @click="save()">确定下发</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'
import { returnVideoArr } from '@/common/utils/index'
import { queryDriverByName, getDriverList } from "@/api/lib/api";
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import { getDriverByline } from "@/api/lib/bus-api.js";
export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 **/
    isBatch: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      driverArr: [],
      vehicleInfos: [],
      deviceType: '', // 协议类型
      loading: false,
      driverList: [],
      form: {
        driverIds: [],
        credentialsNos: []
      },
      terminalNos: [],
      formRules: {
        credentialsNos: [
          {
            required: true,
            type: 'array',
            message: '请选择驾驶员',
            trigger: 'change'
          }
        ],
      }
    }
  },

  methods: {
    // 模糊搜索
    remoteMethod (query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          // queryDriverByName({ driverName: query }).then((res) => {
          //   if (res.code === 1000) {
          //     this.driverList = res.data;
          //   }
          // });
          // //谢修改2021.05.10
          getDriverList({ name: query, pageSize: 999, currentPage: 1 }).then(res => {
            if (res.code == 1000) {
              this.driverList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    save () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          //个数：TaxiSendDriverNum，详情：TaxiSendDriverDetails
          this.$yhsdp.TaxiSendDriverNum(
            [this.terminalNos, this.vehicleInfos], [this.form.credentialsNos.length || 0], this.deviceType
          )
          this.driverArr.forEach(item => {
            //拼装数据
            let obj = {
              driverName: item.name,
              serviceOrgan: item.serviceOrgan,
              credentialsNo: item.credentialsNo,
              idCard: item.idCard,
              cph: this.data.name,
              starLevel: item.starLevel,
              driverPhoto: item.driverPhoto,
              serviceSuperviseCardNo: item.workNum,
              phone: item.phone,
              certificatePeriod:item.credentialsEndDate
            }
            this.$yhsdp.TaxiSendDriverDetails([this.terminalNos, this.vehicleInfos], [obj], this.deviceType)
          })
          this.$emit('close')
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    },
    driverChange (val) {
      let list = [];
      this.driverArr = val;
      val.forEach(item => {
        list.push(item.credentialsNo)
      })
      this.form.credentialsNos = list;
    }
  },
  created () {
  },
  mounted () {
    this.deviceType = sessionStorage.getItem('system')
    /** 单个车辆的数据 */
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.terminalNos = [this.formatTelNo(this.data.videos)]
      queryDeviceByIds({
            ids: this.data.id,
            system: this.deviceType
          }).then(res=>{
            if (res.code === 1000) {
              if (res.data.length === 0) {
                this.$message.warning('车辆没有绑定终端')
                return
              }
              res.data.forEach((element) => {
                this.vehicleInfos.push(`${element.cph}#${element.companyName}`)
              })
            }
          })
    }
    
  }
}
</script>
<style lang="scss" scoped>
.sendInformation {
  @include themify() {
    .el-row {
      .el-col {
        line-height: $base-input-height;
      }
    }
  }
}
</style>
