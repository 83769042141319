var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lins" },
    [
      _c("videoList", {
        ref: "flvPlayModule",
        attrs: { buttonValue: _vm.buttonValue },
        on: { showTips: _vm.showTips }
      }),
      _c("div", { staticClass: "mengceng" }, [
        _c("div", [
          _c("img", {
            attrs: { src: _vm.show ? _vm.imgPng : _vm.imgJpg, alt: "" }
          })
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShow,
                expression: "isShow"
              }
            ],
            staticClass: "tips"
          },
          [_vm._v(_vm._s(_vm.show ? "设备链接中，请等待..." : "正在监听..."))]
        ),
        _c(
          "div",
          { staticClass: "btns" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small", disabled: _vm.isShow },
                on: { click: _vm.open }
              },
              [_vm._v("开始")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "default", size: "small" },
                on: { click: _vm.close }
              },
              [_vm._v("结束")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }