<template>
  <div class="blind" v-loading="loading">
    <p class="form-title form-title2">
      盲区监测报警配置
      <el-button type="primary" size="mini" @click="commit">发送指令</el-button>
    </p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="170px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="后方接近报警时间阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.rearTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
      </el-form-item>
      <el-form-item label="侧后方接近报警时间阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.sideTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>秒
      </el-form-item>      
    </el-form>
  </div>
</template>
<script>
export default {
  props:{
    arr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      loading:false,//加载画面
      addForm: {
        rearTime:1,
        sideTime:1,
      },
    }
  },
  methods: {
    commit () {
      const {
        rearTime,
        sideTime
      } = this.addForm
      const resultData=[
        rearTime,
        sideTime
      ]
      let resultObj = [...this.arr];
      resultData.map((item,index)=>{
        resultObj[index].value = item;
      })
      this.$emit('commit',resultObj)
    },
    //数据处理
    computerObj(arr){
      this.addForm={
        rearTime:arr[0].value,
        sideTime:arr[1].value,
      }
      this.loading =false
    }
  },
  mounted(){
    if(this.arr&&this.arr.length>0){
      this.loading = true;
      this.computerObj(this.arr)
    }
  }
}
</script>
<style lang="scss" scoped>
.blind {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>