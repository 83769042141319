<template>
  <div class="dsm" v-loading="loading">
    <p class="form-title form-title2">
      DSM报警配置
      <el-button type="primary" size="mini" @click="commit">发送指令</el-button>
    </p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="160px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="报警判断速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmSpeed"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>km/h
      </el-form-item>

      <el-form-item label="报警提示音量：">
        <el-input-number
          controls-position="right"
          v-model="addForm.tipsVoice"
          :min="0"
          :max="8"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0-8，数值越大，音量越大" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="主动拍照策略：">
        <el-select v-model="addForm.takePicture" placeholder="请选择">
          <el-option
            v-for="item in takeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="主动定时拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeTime"
          :min="60"
          :max="60000"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="主动拍照策略定时拍照时需要填写" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="主动定距拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeDistance"
          :min="0"
          :max="60000"
          placeholder="请输入"
        ></el-input-number>米
        <el-tooltip class="item" effect="dark" content="主动拍照策略定距拍照时需要填写" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="单次主动拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="单次主动拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.takeinterval"
          :min="1"
          :max="5"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="拍照分辨率：">
        <el-select v-model="addForm.takeRatio" placeholder="请选择">
          <el-option
            v-for="item in ratioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark" content="该参数也适用于报警触发拍照分辨率" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="视频录制分辨率：">
        <el-select v-model="addForm.takevideoRatio" placeholder="请选择">
          <el-option
            v-for="item in resolutionRatioList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <el-tooltip class="item" effect="dark" content="该参数也适用于报警触发视频分辨率" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.blockLeve1"></el-switch>疲劳驾驶一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.blockLeve2"></el-switch>疲劳驾驶二级报警
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.levelSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.videoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="疲劳驾驶报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="疲劳驾驶报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.alarmPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.freLeve1"></el-switch>接打电话一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.freLeve2"></el-switch>接打电话二级报警
      </el-form-item>

      <el-form-item label="接打电话报警判断间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePeriod"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="此时间隔内只触发一次报警" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="接打电话分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.freSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="接打电话前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.freTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePictrueNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="接打电话拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.frePictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.awayFromLeve1"></el-switch>抽烟一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.awayFromLeve2"></el-switch>抽烟二级报警
      </el-form-item>

      <el-form-item label="抽烟报警判断时间间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.awayFromPeriod"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="此时间隔内只触发一次报警" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="抽烟报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.awayFromSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="抽烟报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.awayFromVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="抽烟面部特征拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.afPictrueNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="抽烟面部特征拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.afPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.baLeve1"></el-switch>长时间不目视前方一级报警
      </el-form-item>
      <el-form-item label=" ">
        <el-switch v-model="addForm.baLeve2"></el-switch>长时间不目视前方二级报警
      </el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abPictrueNum"
          :min="0"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.abPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.pLeve1"></el-switch>未检测到驾驶员二级报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label="报警分级速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pSpeed"
          :min="0"
          :max="220"
          placeholder="请输入"
        ></el-input-number>km/h
        <el-tooltip class="item" effect="dark" content="高于设置值为二级，否则为一级" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警前后视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pPictrueNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.pPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label="驾驶员身份识别触发：">
        <el-select v-model="addForm.takevideoRatio" placeholder="请选择">
          <el-option
            v-for="item in driverList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <!-- 兼容 -->
      <template v-if="arr&&arr.length>36">
        <el-form-item label=" ">
        <el-switch v-model="addForm.night"></el-switch>夜间禁行报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label="禁行时段开始时间：">
        <el-time-picker v-model="addForm.nightBegin" placeholder="选择时间" format="HH:mm:ss"></el-time-picker>
      </el-form-item>
      <el-form-item label="禁行时段结束时间：">
        <el-time-picker
          v-model="addForm.nightEnd"
          placeholder="选择时间"
          format="HH:mm:ss"
          minTime:addForm.nightBegin
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="夜间禁行报警判断间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.nightTime"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="此时间隔内只触发一次报警" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="夜间禁行报警视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.nightVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.nightPictrueNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="报警面部特征拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.nightPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.overTime"></el-switch>超速驾驶报警
      </el-form-item>

      <el-form-item label=" "></el-form-item>
      <el-form-item label="超时驾驶报警时间阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.turnTime"
          :min="0"
          :max="3600"
          placeholder="请输入"
        ></el-input-number>100ms
        <el-tooltip class="item" effect="dark" content="此时间间隔内只触发一次报警" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="超时驾驶报警视频时间：">
        <el-input-number
          controls-position="right"
          v-model="addForm.turnVideoTime"
          :min="0"
          :max="60"
          placeholder="请输入"
        ></el-input-number>秒
        <el-tooltip class="item" effect="dark" content="0 表示不录像" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="超时驾驶报警拍照张数：">
        <el-input-number
          controls-position="right"
          v-model="addForm.turnPictrueNum"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>
        <el-tooltip class="item" effect="dark" content="0 表示不抓拍" placement="top-start">
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>

      <el-form-item label="超时驾驶报警拍照间隔：">
        <el-input-number
          controls-position="right"
          v-model="addForm.turnPictrueTime"
          :min="1"
          :max="10"
          placeholder="请输入"
        ></el-input-number>100ms
      </el-form-item>
      </template>
      
    </el-form>
  </div>
</template>
<script>
const single2011 = [
  { key: 'alarmSpeed', valueKey: 'arr', valueIndex: 0, defaultValue: 30 },
  { key: 'tipsVoice', valueKey: 'arr', valueIndex: 1, defaultValue: 6 },
  { key: 'takePicture', valueKey: 'arr', valueIndex: 2, defaultValue: 0 },
  { key: 'takeTime', valueKey: 'arr', valueIndex: 3, defaultValue: 3600 },
  { key: 'takeDistance', valueKey: 'arr', valueIndex: 4, defaultValue: 200 },
  { key: 'takeNum', valueKey: 'arr', valueIndex: 5, defaultValue: 3 },
  { key: 'takeinterval', valueKey: 'arr', valueIndex: 6, defaultValue: 2 },
  { key: 'takeRatio', valueKey: 'arr', valueIndex: 7, defaultValue: 1 },
  { key: 'takevideoRatio', valueKey: 'arr', valueIndex: 8, defaultValue: 1 },
  { key: 'blockLeve1', valueKey: 'bit32', valueIndex: 0, defaultValue: true },
  { key: 'blockLeve2', valueKey: 'bit32', valueIndex: 1, defaultValue: true },
  { key: 'frePeriod', valueKey: 'arr', valueIndex: 12, defaultValue: 120 },
  { key: 'levelSpeed', valueKey: 'arr', valueIndex: 14, defaultValue: 50 },
  { key: 'videoTime', valueKey: 'arr', valueIndex: 15, defaultValue: 5 },
  { key: 'alarmPictrueNum', valueKey: 'arr', valueIndex: 16, defaultValue: 3 },
  { key: 'alarmPictrueTime', valueKey: 'arr', valueIndex: 17, defaultValue: 2 },
  { key: 'freLeve1', valueKey: 'bit32', valueIndex: 2, defaultValue: true },
  { key: 'freLeve2', valueKey: 'bit32', valueIndex: 3, defaultValue: true },
  { key: 'freSpeed', valueKey: 'arr', valueIndex: 18, defaultValue: 50 },
  { key: 'freTime', valueKey: 'arr', valueIndex: 19, defaultValue: 5 },
  { key: 'frePictrueNum', valueKey: 'arr', valueIndex: 20, defaultValue: 3 },
  { key: 'frePictrueTime', valueKey: 'arr', valueIndex: 21, defaultValue: 2 },
  { key: 'awayFromLeve1', valueKey: 'bit32', valueIndex: 4, defaultValue: true },
  { key: 'awayFromLeve2', valueKey: 'bit32', valueIndex: 5, defaultValue: true },
  { key: 'awayFromPeriod', valueKey: 'arr', valueIndex: 11, defaultValue: 120 },
  { key: 'awayFromSpeed', valueKey: 'arr', valueIndex: 22, defaultValue: 50 },
  { key: 'awayFromVideoTime', valueKey: 'arr', valueIndex: 23, defaultValue: 5 },
  { key: 'afPictrueNum', valueKey: 'arr', valueIndex: 24, defaultValue: 3 },
  { key: 'afPictrueTime', valueKey: 'arr', valueIndex: 25, defaultValue: 2 },
  { key: 'baLeve1', valueKey: 'bit32', valueIndex: 6, defaultValue: true },
  { key: 'baLeve2', valueKey: 'bit32', valueIndex: 7, defaultValue: true },
  { key: 'abSpeed', valueKey: 'arr', valueIndex: 26, defaultValue: 50 },
  { key: 'abVideoTime', valueKey: 'arr', valueIndex: 27, defaultValue: 5 },
  { key: 'abPictrueNum', valueKey: 'arr', valueIndex: 28, defaultValue: 3 },
  { key: 'abPictrueTime', valueKey: 'arr', valueIndex: 29, defaultValue: 2 },
  { key: 'pLeve1', valueKey: 'bit32', valueIndex: 9, defaultValue: true },
  { key: 'pSpeed', valueKey: 'arr', valueIndex: 30, defaultValue: 50 },
  { key: 'pVideoTime', valueKey: 'arr', valueIndex: 31, defaultValue: 5 },
  { key: 'pPictrueNum', valueKey: 'arr', valueIndex: 32, defaultValue: 3 },
  { key: 'pPictrueTime', valueKey: 'arr', valueIndex: 33, defaultValue: 2 },
  { key: 'driver', valueKey: 'arr', valueIndex: 34, defaultValue: 1 }
]
const single2019 = [
  { key: 'night', valueKey: 'bit32', valueIndex: 10, defaultValue: true },
  { key: 'overTime', valueKey: 'bit32', valueIndex: 11, defaultValue: true },
  { key: 'nightVideoTime', valueKey: 'arr', valueIndex: 37, defaultValue: 5 },
  { key: 'nightPictrueNum', valueKey: 'arr', valueIndex: 38, defaultValue: 3 },
  { key: 'nightPictrueTime', valueKey: 'arr', valueIndex: 39, defaultValue: 2 },
  { key: 'nightTime', valueKey: 'arr', valueIndex: 40, defaultValue: 300 },
  { key: 'turnVideoTime', valueKey: 'arr', valueIndex: 41, defaultValue: 5 },
  { key: 'turnPictrueNum', valueKey: 'arr', valueIndex: 42, defaultValue:3 },
  { key: 'turnPictrueTime', valueKey: 'arr', valueIndex: 43, defaultValue: 2 },
  { key: 'turnTime', valueKey: 'arr', valueIndex: 44, defaultValue: 300 },
]
const singleTime = [
  { key: 'nightBegin', valueKey: 'time', valueIndex: 0, defaultValue: new Date(2016, 9, 10, 22, 0) },
  { key: 'nightEnd', valueKey: 'time', valueIndex: 1, defaultValue: new Date(2016, 9, 10, 0, 0) },
]
import { formatDate } from '@/common/utils/index'
export default {
  props: {
    arr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      loading: false,//加载画面
      addForm: null,
      takeList: [
        { dictValue: '不开启', dictCode: 0 },
        { dictValue: '定时拍照', dictCode: 1 },
        { dictValue: '定距拍照', dictCode: 2 },
        { dictValue: '保留', dictCode: 3 }
      ],
      ratioList: [
        { dictValue: '352*288', dictCode: 1 },
        { dictValue: '704*288', dictCode: 2 },
        { dictValue: '704*576', dictCode: 3 },
        { dictValue: '640*480', dictCode: 4 },
        { dictValue: '1280*720', dictCode: 5 },
        { dictValue: '1920*1080', dictCode: 6 }
      ],
      resolutionRatioList: [
        { dictValue: "CIF", dictCode: 1 },
        { dictValue: "HD1", dictCode: 2 },
        { dictValue: "D1", dictCode: 3 },
        { dictValue: "WD1", dictCode: 4 },
        { dictValue: "VGA", dictCode: 5 },
        { dictValue: "720P", dictCode: 6 },
        { dictValue: "1080P", dictCode: 7 }
      ],
      driverList: [
        { dictValue: "不开启", dictCode: 0 },
        { dictValue: "定时触发", dictCode: 1 },
        { dictValue: "定距触发", dictCode: 2 },
        { dictValue: "插卡开始行驶触发", dictCode: 3 },
        { dictValue: "保留", dictCode: 4 },
      ],
      temp1: 0,
      temp2: 0,
    }
  },
  methods: {
    commit () {
      //处理成数组返给父组件下发指令
      const {
        alarmSpeed,
        tipsVoice,
        takePicture,
        takeTime,
        takeDistance,
        takeNum,
        takeinterval,
        takeRatio,
        takevideoRatio,

        blockLeve1,
        blockLeve2,
        levelSpeed,
        videoTime,
        alarmPictrueNum,
        alarmPictrueTime,

        freLeve1,
        freLeve2,
        frePeriod,
        freSpeed,
        freTime,
        frePictrueNum,
        frePictrueTime,

        awayFromLeve1,
        awayFromLeve2,
        awayFromPeriod,
        awayFromSpeed,
        awayFromVideoTime,
        afPictrueNum,
        afPictrueTime,

        baLeve1,
        baLeve2,
        abSpeed,
        abVideoTime,
        abPictrueNum,
        abPictrueTime,

        pLeve1,
        pSpeed,
        pVideoTime,
        pPictrueNum,
        pPictrueTime,

        night,
        overTime,

        driver,
        nightBegin,
        nightEnd,
        nightVideoTime,
        nightPictrueNum,
        nightPictrueTime,
        nightTime,

        turnVideoTime,
        turnPictrueNum,
        turnPictrueTime,
        turnTime
      } = this.addForm
      //二进制转换为十进制
      const singleArr = [blockLeve1 ? 1 : 0, blockLeve2 ? 1 : 0, freLeve1 ? 1 : 0, freLeve2 ? 1 : 0, awayFromLeve1 ? 1 : 0, awayFromLeve2 ? 1 : 0, baLeve1 ? 1 : 0, baLeve2 ? 1 : 0, 0, pLeve1 ? 1 : 0, night ? 1 : 0, overTime ? 1 : 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];//32位
      let singleStr = parseInt(singleArr.reverse().join(''), 2);
      const biscData = [
        alarmSpeed,
        tipsVoice,
        takePicture,
        takeTime,
        takeDistance,
        takeNum,
        takeinterval,
        takeRatio,
        takevideoRatio,//8
        singleStr,//9
        this.temp1,//10、
        awayFromPeriod,//抽烟间隔
        frePeriod,//12
        this.temp2,//13
        levelSpeed,
        videoTime,
        alarmPictrueNum,
        alarmPictrueTime,
        freSpeed,//18
        freTime,
        frePictrueNum,
        frePictrueTime,
        awayFromSpeed,
        awayFromVideoTime,
        afPictrueNum,
        afPictrueTime,
        abSpeed,
        abVideoTime,
        abPictrueNum,
        abPictrueTime,
        pSpeed,
        pVideoTime,
        pPictrueNum,
        pPictrueTime,//33
        driver,//34
      ];//长度为46的数组，传回父组件下发指令
      const additional = [
        formatDate(nightBegin).split(' ')[1],
        formatDate(nightEnd).split(' ')[1],
        nightVideoTime,
        nightPictrueNum,
        nightPictrueTime,
        nightTime,
        turnVideoTime,
        turnPictrueNum,
        turnPictrueTime,
        turnTime//45
      ]//兼容
      let resultData = [];
      if(this.arr&&this.arr.length<37){
        resultData = [...biscData,0];//2011协议
      }else{
        resultData = [...biscData,...additional,0];//2019协议
      }
      let resultObj = [...this.arr];
      resultData.map((item, index) => {
        resultObj[index].value = item;
      })
      
      this.$emit('commit', resultObj)
    },
    //数据处理
    computerObj (arr) {
      //报警使能:arr[9],事件使能arr[10],预留字段arr[13]
      //暂存数据
      this.temp1 = arr[10].value;
      this.temp2 = arr[13].value;
      //二进制转换-报警使能
      let bfArr = Number(arr[9].value);
      let bit2 = bfArr.toString(2).split('').reverse();
      let l1 = bit2.length;
      bit2.length = 32;
      let bit32 = bit2.fill('0', l1, 32);
      //兼容处理
      let start = new Date(2016, 9, 10, 22, 0);
      let end = new Date(2016, 9, 10, 0, 0);
      let single = []
      if (this.arr.length > 36) {
        //禁行时间转换
        let startArr = [];
        let endtArr = [];
        if (arr[35].value) {
          startArr = arr[35].value.split(':')
          start = new Date(2016, 9, 10, startArr[0], startArr[1])
        }
        if (arr[36].value) {
          endtArr = arr[35].value.split(':')
          end = new Date(2016, 9, 10, endtArr[0], endtArr[1])
        }
        single = [...single2011,...single2019,...singleTime];
      }else{
        single = [...single2011];
      }
      const dataObj = { arr, bit32,time:[
        {value:start},//第一项表示开始时间
        {value:end},//第二项表示结束时间
      ] };
      const resetObj = {};
      single.forEach(item => {
        if (item.valueKey === 'arr') {
          resetObj[item.key] = dataObj[item.valueKey][item.valueIndex].value;
        } else if(item.valueKey === 'time'){
          resetObj[item.key] = dataObj[item.valueKey][item.valueIndex].value;
        }else {
          resetObj[item.key] = dataObj[item.valueKey][item.valueIndex] == 1;
        }
      })
      this.addForm = resetObj;
      this.loading = false;
    }
  },
  created () {
    if (this.arr && this.arr.length > 0) {
      //数组分为46和36，兼容2019新增的碰撞侧翻
      this.loading = true;
      this.computerObj(this.arr)
    } else {
      //赋初始值
      const initialArr = [...single2011, ...single2019,...singleTime];
      let initialObj = {};
      initialArr.forEach(item => {
        initialObj[item.key] = item.defaultValue;
      })
      this.addForm = initialObj;
    }
  }
}
</script>
<style lang="scss" scoped>
.dsm {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>