var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vehicle-seting-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            "label-width": "140px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v("车牌号：" + _vm._s(_vm.data.name))
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _vm._v(
                      "终端号：" + _vm._s(_vm.formatTelNo(_vm.data.videos))
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "确认解除报警类型：" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.form.checkedAlarm,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "checkedAlarm", $$v)
                    },
                    expression: "form.checkedAlarm"
                  }
                },
                _vm._l(_vm.alarmOptions, function(ele) {
                  return _c("el-checkbox", {
                    key: ele.value,
                    attrs: { label: ele.value, value: ele.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.cancle()
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }