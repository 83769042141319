<template>
  <div class="terminal-parameter">
    <el-form
      ref="form"
      :model="form"
      :inline="true"
      size="small"
      :rules="formRules"
      class="dialog-form terminal-form"
    >
      <el-form-item label="车牌号：" v-if="!isBatch">
        {{ data.name }}
      </el-form-item>
      <el-form-item label="终端号：" v-if="!isBatch">
        {{ formatTelNo(data.videos) }}
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>

      <!-- 选择车辆： -->
      <!--  -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
    </el-form>
    <!--  超速报警设置-->
    <p class="form-title form-title2">
      超速报警设置
      <el-button type="primary" size="mini" @click="saveInput(2)"
        >发送指令</el-button
      >
      <el-button type="defalut" size="mini" @click="saveInput(1)"
        >发送指令(新川标)</el-button
      >
    </p>
    <el-form
      :model="addForm2"
      :inline="true"
      :rules="formRules"
      ref="addForm2"
      label-width="130px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="最高速度(km/h)：">
        <el-input-number
          controls-position="right"
          v-model="addForm2.speedLimit"
          :min="0"
          placeholder="请输入"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="预警提前量(km/h)：">
        <el-input-number
          v-model="addForm2.preSpeeding"
          placeholder="请输入"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="夜间限速比例(%)：">
        <el-input-number
          v-model="addForm2.nightSpeedLimitPer"
          placeholder="请输入"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="超速持续时间(s)：">
        <el-input-number
          v-model="addForm2.time"
          placeholder="请输入"
          controls-position="right"
          :min="0"
          disabled
        ></el-input-number>
      </el-form-item>
      <!-- 夜间限速的 -->
      <el-form-item label="白天时间段：" prop="period">
        <el-time-picker
          is-range
          v-model="addForm2.period"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          format="HH:mm"
        >
        </el-time-picker>
        <el-tooltip
            class="item"
            effect="dark"
            content="新川标：夜间限速比例需要下发至终端"
            placement="top-start"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
      </el-form-item>
    </el-form>
    <!--疲劳驾驶报警设置  -->
    <p class="form-title form-title2">
      疲劳驾驶报警设置
      <el-button type="primary" size="mini" @click="saveInput(3)"
        >发送指令</el-button
      >
      <el-button type="defalut" size="mini" @click="nomalValue"
        >恢复设置</el-button
      >
    </p>
    <el-form
      :model="addForm3"
      :inline="true"
      ref="addForm3"
      label-width="130px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="是否执行疲劳">
        <el-checkbox v-model="addForm3.fatigue" @change="handleCheckAllChange"></el-checkbox>
      </el-form-item>
        <el-form-item v-if="addForm3.fatigue"></el-form-item>
        <el-form-item label="预警提前量(分钟)：" v-if="addForm3.fatigue">
          <el-input-number
            v-model="addForm3.preFatigue"
            placeholder="请输入"
            :min="0"
            disabled
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最小休息(分钟)：" v-if="addForm3.fatigue">
          <el-input-number
            v-model="addForm3.minSleepTime"
            placeholder="请输入"
            :min="0"
            disabled
            controls-position="right"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="是否单次疲劳" v-if="addForm3.fatigue">
          <el-checkbox v-model="addForm3.onceFatigue" @change="handleCheckedChange"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="addForm3.fatigue"></el-form-item>
        <el-form-item label="连续驾驶(分钟)：" v-if="addForm3.fatigue">
          <el-input-number
            v-model="addForm3.continueDriveTime"
            placeholder="请输入"
            controls-position="right"
            :min="1"
            disabled
            :max="255"
          ></el-input-number>
          <el-tooltip
            class="item"
            effect="dark"
            content="最小为1分钟,最大为4小时15分钟(255分钟)"
            placement="top-start"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="addForm3.fatigue"></el-form-item>
        <el-form-item label="是否累计疲劳" v-if="addForm3.fatigue">
          <el-checkbox v-model="addForm3.totalFatigue" @change="handleCheckedChange"></el-checkbox>
        </el-form-item>
        <el-form-item v-if="addForm3.fatigue"></el-form-item>
        <el-form-item label="当天累计驾驶(分钟)：" label-width="140px" v-if="addForm3.fatigue">
          <el-input-number
            v-model="addForm3.driverTotalTime"
            placeholder="请输入"
            :min="480"
            :max="1440"
            controls-position="right"
          ></el-input-number>
          <el-tooltip
            class="item"
            effect="dark"
            content="1-1440"
            placement="top-start"
          >
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-form-item>
    </el-form>
    <!-- <p class="form-title form-title2">
      夜间禁行设置
      <el-button type="primary" size="mini" @click="saveInput(4)"
        >发送指令</el-button
      >
    </p>
    <el-form
      :model="addForm4"
      :inline="true"
      ref="addForm4"
      label-width="130px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label="是否夜间禁行">
        <el-select v-model="addForm4.isOpenNight" placeholder="请选择">
          <el-option
            v-for="item in nightOptions"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item></el-form-item>
      <el-form-item label="禁行时段开始时间：" prop="stopBegin" v-if="addForm4.isOpenNight==1">
        <el-time-picker
          v-model="addForm4.stopBegin"
          placeholder="选择时间"
          format="HH:mm"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="禁行时段结束时间：" prop="stopEnd" v-if="addForm4.isOpenNight==1">
        <el-time-picker
          v-model="addForm4.stopEnd"
          placeholder="选择时间"
          format="HH:mm"
          minTime: addForm4.stopBegin
        >
        </el-time-picker>
      </el-form-item>
    </el-form> -->
  </div>
</template>
<script>
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import { returnVideoArr, formatDate, formatDay } from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    settingValue: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // }
  },

  data () {
    let validateDate = (rule, value, callback) => {
      if (value && value !== '') {
        let day = formatDay(new Date())
        let startTime = `${day} ${formatDate(value[0]).split(' ')[1]}`
        let endTime = `${day} ${formatDate(value[1]).split(' ')[1]}`
        if (new Date(startTime) >= new Date(endTime)) {
          callback(new Error(`开始时间必须小于结束时间`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      terminalNos: [],
      vehicleInfos: [],

      form: {
        vehIds: [],
        companyIds: []
      },
      addForm2: {
        time: 10, //超速持续时间(s)：
        speedLimit: null, // 最高速度门限（km
        nightSpeedLimitPer: 80, //夜间限速比例
        // period: [new Date(2016, 9, 10, 6, 0), new Date(2016, 9, 10, 22, 0)],
        period:null,
        preSpeeding: null //预超速
      },
      isIndeterminate:true,
      addForm3: {
        fatigue:true,//是否疲劳报警
        onceFatigue:true,//单词疲劳报警
        totalFatigue:true,//是否累计疲劳
        continueDriveTime: 240, // 连续驾驶时限（分钟）：
        preFatigue: 30, //预疲劳
        driverTotalTime: 480, // 当天累计驾驶时限（分钟）：
        minSleepTime: 20 // 最小休息时间（分钟）：
      },
      // addForm4:{
      //   isOpenNight:0,
      //   stopBegin:'',
      //   stopEnd:''
      // },
      nightOptions:[
        { dictValue: '不禁行(接驳)', dictCode: 0 },
        { dictValue: '禁行', dictCode: 1 },
      ],

      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ],
        period: [{ validator: validateDate, trigger: 'change' }]
      },
      AlarmPromptOption: {
        1:['speedLimit', 'preSpeeding', 'nightSpeedLimitPer', 'period'],
        2: ['speedLimit', 'preSpeeding', 'nightSpeedLimitPer', 'period'],
        3: [
          'continueDriveTime',
          'driverTotalTime',
          'preFatigue',
          'minSleepTime'
        ],
      }
    }
  },

  methods: {
    nomalValue(){
      this.addForm3={
        fatigue:true,//是否疲劳报警
        onceFatigue:true,//单词疲劳报警
        totalFatigue:true,//是否累计疲劳
        continueDriveTime: 240, // 连续驾驶时限（分钟）：
        preFatigue: 30, //预疲劳
        driverTotalTime: 480, // 当天累计驾驶时限（分钟）：
        minSleepTime: 20 // 最小休息时间（分钟）：
      }
    },
    handleCheckAllChange(val){
      // console.log('全部',val)
      if(val){
        this.addForm3.onceFatigue=true;
        this.addForm3.totalFatigue=true;
      }else{
        this.addForm3.onceFatigue=false;
        this.addForm3.totalFatigue=false
      }
    },
    handleCheckedChange(val){
      if(!this.addForm3.onceFatigue && !this.addForm3.totalFatigue){
        this.addForm3.fatigue=false;
      }else{
        this.addForm3.fatigue=true;
      }
    },
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.vehicleInfos = []
      this.form.vehIds = vehStrIds
      if (vehStrIds.length > 0)
        this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 
     * 获取已存的提示内容
     * 
     *  */
    queryDeviceParams (data) {
      queryDeviceParams(data).then((res) => {
        if (res.code === 1000) {
          //如果终端没有返回，则读取服务器的数据
          //不走后端保存的数据，直接查询终端参数回显
          let period = null
          // this.addForm2 = { ...this.addForm2, ...res.data }
          if (res.data && res.data.period) {
            period = [
              new Date(res.data.period.split('_')[0]),
              new Date(res.data.period.split('_')[1])
            ]
          }
          this.addForm2.period = period;
          this.addForm2.nightSpeedLimitPer = res.data.nightSpeedLimitPer;
          // 2021-6-3 谢 修改疲劳驾驶
          if(res.data){
            if(res.data.fatigue===null)res.data.fatigue=true
            if(res.data.onceFatigue===null)res.data.onceFatigue=true
            if(res.data.totalFatigue===null)res.data.totalFatigue=true
            if(res.data.continueDriveTime===null)res.data.continueDriveTime=240
            if(res.data.preFatigue===null)res.data.preFatigue=30
            if(res.data.driverTotalTime===null)res.data.driverTotalTime=480
            if(res.data.minSleepTime===null)res.data.minSleepTime=20            
          }
          this.addForm3 = { ...this.addForm3, ...res.data }
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput (key) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let parameter = 0
          let form = `addForm${key}`          
          if(key !==1){
              this.AlarmPromptOption[key].forEach((item) => {
              if (
                this[form][item] === null ||
                this[form][item] === undefined ||
                this[form][item] === 'undefined' ||
                this[form][item] === ''
              ) {
                parameter = parameter + 1
              }
            })
            if (parameter === this.AlarmPromptOption[key].length) {
              this.$message.warning('请至少下发一个指令')
              return
            }
          }
          /**
           * key=1：新川标；key=2:超速报警
           */
          if (key === 2 || key === 1) {
            if(key === 1 && !this.addForm2.period){
              this.$message.warning('请选择限速时间段')
            return
            }
            if(!(this.addForm2.speedLimit || this.addForm2.speedLimit ===0)){
              this.$message.warning('最高速度门限必填')
            return
            }
            if(!(this.addForm2.preSpeeding || this.addForm2.preSpeeding ===0)){
              this.$message.warning('预警提前量必填')
            return
            }
            if(!(this.addForm2.nightSpeedLimitPer || this.addForm2.nightSpeedLimitPer ===0)){
              this.$message.warning('夜间超速比例必填')
            return
            }
            this.$refs.addForm2.validate((valid) => {
              if (valid) {
                this.sendData(key)
              }
            })
          } else {
            this.sendData(key)
          }
        }
      })
    },
    sendData (key) {
      const terminalNos = []
      const vehicleInfos = []
      let ids = this.form.companyIds.concat(this.form.vehIds)
      queryDeviceByIds({
        ids: ids.join(','),
        system: this.deviceType
      }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length === 0) {
            this.$message.warning('车辆没有绑定终端')
            return
          }
          res.data.forEach((element) => {
            let deviceNo = returnVideoArr(element.videos, true)
            terminalNos.push(deviceNo)
            vehicleInfos.push(`${element.cph}#${element.companyName}`)
          })
          this.terminalNos = terminalNos
          this.vehicleInfos = vehicleInfos

          // if (this.terminalNos.length > this.number) {
          //   this.$message.warning(`最多只能对${this.number}个终端批量下发指令`)
          //   return
          // }
          const params = []
          const reqData = {
            deviceNos: this.terminalNos, // 下发的终端
            terminalParams: null // 报警提示类型
          }
            const { speedLimit, preSpeeding,nightSpeedLimitPer,period } = this.addForm2
          switch (key) {
            case 1:
              //新川标设置（超速和限速控制都要传)
                params.push(0x0055, speedLimit)//最高速度
                params.push(0x005b, preSpeeding)//预警提前量
                params.push(0x0056, 10) //超速持续时间
                params.push(0xF001,[1,nightSpeedLimitPer,formatDate(period[1]).split(' ')[1],formatDate(period[0]).split(' ')[1]])//限速设置---新川标--1表示默认开启限速
                reqData.terminalParams = { ...this.addForm2 }
                if (this.addForm2.period) {
                let period = `${formatDate(
                  this.addForm2.period[0]
                )}_${formatDate(this.addForm2.period[1])}`
                reqData.terminalParams.period = period
              }
                
              break;
            //超速报警设置（只传限速的)
            case 2:
              //最高速度
              if (speedLimit === 0 || speedLimit) {
                params.push(0x0055, speedLimit)
              }
              //预警提前量
              if (preSpeeding === 0 || preSpeeding) {
                params.push(0x005b, preSpeeding)
              }
              params.push(0x0056, 10) //超速持续时间
              reqData.terminalParams = { ...this.addForm2 }
              if (this.addForm2.period) {
                let period = `${formatDate(
                  this.addForm2.period[0]
                )}_${formatDate(this.addForm2.period[1])}`
                reqData.terminalParams.period = period
              }

              break
            //疲劳驾驶报警设置
            case 3:
              const {
                continueDriveTime,
                preFatigue,
                driverTotalTime,
                minSleepTime,
                fatigue,
                onceFatigue,
                totalFatigue
              } = this.addForm3
              if (continueDriveTime) params.push(0x0057, continueDriveTime) //连续驾驶
              //预警提前量
              if (preFatigue === 0 || preFatigue) {
                params.push(0x005c, preFatigue)
              }
              if (driverTotalTime) params.push(0x0058, driverTotalTime) //当天累计驾驶
              //最小休息
              if (minSleepTime === 0 || minSleepTime) {
                params.push(0x0059, minSleepTime)
              }
              reqData.terminalParams = { ...this.addForm3 }

              break
            case 4:
              //夜间禁行设置
              const {
                isOpenNight,
                stopBegin,
                stopEnd,
              } = this.addForm4
              console.log('发送参数/////////////////',isOpenNight,
                stopBegin,
                stopEnd,)
              params.push(0xF001,[isOpenNight,nightSpeedLimitPer,stopBegin,stopEnd])
              break;
            default:
              break
          }
          this.$yhsdp.GBSetParameters(
            [terminalNos, this.vehicleInfos],
            params,
            this.deviceType
          )
          setDeviceParams(reqData).then((res) => { })
          this.$emit('close')
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
      this.form.vehIds = [this.data.id]
    }
    if(this.settingValue){
      const {
        speedLimit,
        preSpeeding,
        time,
        isOpenNight,
        // nightSpeedLimitPer,
        period
      } = this.settingValue
      this.addForm2.speedLimit = speedLimit;
      this.addForm2.preSpeeding = preSpeeding && preSpeeding / 10;
      this.addForm2.time = time;
      // this.addForm2.nightSpeedLimitPer = nightSpeedLimitPer||80;
      period&&(this.addForm2.period = [new Date(2016,9,10,period[1].split(':')[0],period[1].split(':')[1]),new Date(2016,9,10,period[0].split(':')[0],period[0].split(':')[1])]);
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminal-parameter {
  height: 55vh;
  overflow-y: auto;
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>
