var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "roadNetworkState" }, [
    _c(
      "div",
      { staticClass: "my-table" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "border-card" },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "状态查询详情", name: "first" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass:
                      "demo-form-inline dialog-form dialog-form-inline",
                    attrs: {
                      model: _vm.tableData,
                      inline: true,
                      "label-width": "120px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c("el-form-item", { attrs: { label: "VIN码：" } }, [
                      _vm._v(_vm._s(_vm.tableData.vehicleRelTbox.vehicle.vin))
                    ]),
                    _c("el-form-item", { attrs: { label: "TBOXID：" } }, [
                      _vm._v(_vm._s(_vm.tableData.vehicleRelTbox.tbox.deviceNo))
                    ]),
                    _c("el-form-item", { attrs: { label: "厂商：" } }, [
                      _vm._v(
                        _vm._s(_vm.tableData.vehicleRelTbox.vehicle.factoryCnme)
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "型号：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.vehicleRelTbox.tbox
                            .tboxModelTerminalModel
                        )
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "SIM卡号：" } }, [
                      _vm._v(
                        _vm._s(_vm.tableData.vehicleRelTbox.tbox.phoneNumber)
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "升级方式：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.softUpgradeAuth.authType == 2
                            ? "区域"
                            : "其他"
                        )
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "注册时间：" } }, [
                      _vm._v(_vm._s(_vm.tableData.license.registerTime))
                    ]),
                    _c("el-form-item", { attrs: { label: "验证时间：" } }, [
                      _vm._v(_vm._s(_vm.tableData.license.checkTime))
                    ]),
                    _c("el-form-item", { attrs: { label: "升级授权类型：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.license.licenseType == 2
                            ? "时间范围类型"
                            : "其他"
                        )
                      )
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { label: "功能开关：" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "switch" },
                          _vm._l(_vm.tableData.license.functions, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                class: item.flag ? "on tag" : "off tag"
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c("el-form-item", { attrs: { label: "License：" } }, [
                      _vm._v(_vm._s(_vm.tableData.license.license))
                    ]),
                    _c("el-form-item", { attrs: { label: "升级有效期：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.softUpgradeAuth.startDate.substring(
                            0,
                            10
                          )
                        ) +
                          " ~ " +
                          _vm._s(
                            _vm.tableData.softUpgradeAuth.endDate.substring(
                              0,
                              10
                            )
                          )
                      )
                    ]),
                    _c("el-form-item", { attrs: { label: "升级状态：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.license.status == 1 ? "有效" : "无效"
                        )
                      )
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "License授权类型：" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.tableData.license.licenseType == 2
                              ? "限制时间"
                              : "其他"
                          )
                        )
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "License有效期：" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.tableData.license.startDate.substring(0, 10)
                          ) +
                            " ~ " +
                            _vm._s(
                              _vm.tableData.license.endDate.substring(0, 10)
                            )
                        )
                      ]
                    ),
                    _c("el-form-item", { attrs: { label: "License状态：" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.tableData.license.status == 1 ? "激活" : "未激活"
                        )
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "版本信息", name: "second" } },
              [
                _c(
                  "div",
                  { staticClass: "yy" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: { data: _vm.logList, stripe: "", height: "95%" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "name", label: "名称" }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "version", label: "版本" }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "createTime", label: "创建时间" }
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "updateTime", label: "更新时间" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }