var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terminal-ip" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form terminal-form",
          attrs: {
            model: _vm.form,
            inline: true,
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                _vm._v(" " + _vm._s(_vm.data.name) + " ")
              ])
            : _vm._e(),
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "终端号：" } }, [
                _vm._v(" " + _vm._s(_vm.formatTelNo(_vm.data.videos)) + " ")
              ])
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 服务器配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveInput }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm2",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm2,
            inline: true,
            rules: _vm.formRules,
            "label-width": "120px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "心跳间隔(秒)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.intervalTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "intervalTime", $$v)
                  },
                  expression: "addForm2.intervalTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主服务器APN：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm2.apnSet,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "apnSet", $$v)
                  },
                  expression: "addForm2.apnSet"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主服务器IP地址：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm2.serverIp,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "serverIp", $$v)
                  },
                  expression: "addForm2.serverIp"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主服务器端口：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm2.serverTcpPort,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "serverTcpPort", $$v)
                  },
                  expression: "addForm2.serverTcpPort"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 运营汇报策略配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.saveConfiguration }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm3",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm3,
            inline: true,
            rules: _vm.formRules2,
            "label-width": "190px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "位置汇报策略：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.addForm3.report,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm3, "report", $$v)
                    },
                    expression: "addForm3.report"
                  }
                },
                _vm._l(_vm.reportList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "位置汇报方案：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.addForm3.plan,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm3, "plan", $$v)
                    },
                    expression: "addForm3.plan"
                  }
                },
                _vm._l(_vm.planList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "缺省汇报时间间隔（秒）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.noReportTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "noReportTime", $$v)
                  },
                  expression: "addForm3.noReportTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "休眠汇报时间间隔（秒）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.dormancyReportTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "dormancyReportTime", $$v)
                  },
                  expression: "addForm3.dormancyReportTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "缺省汇报距离间隔（米）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.noReportDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "noReportDistance", $$v)
                  },
                  expression: "addForm3.noReportDistance"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "休眠汇报距离间隔（米）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.dormancyReportDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "dormancyReportDistance", $$v)
                  },
                  expression: "addForm3.dormancyReportDistance"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机未登签汇报间隔（秒）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.noSignTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "noSignTime", $$v)
                  },
                  expression: "addForm3.noSignTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机未登签距离间隔（米）：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm3.noSignDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "noSignDistance", $$v)
                  },
                  expression: "addForm3.noSignDistance"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }