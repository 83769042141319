var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "carTree", style: _vm.setCarTreeHeight }, [
    _c("div", { staticClass: "expand" }, [
      _c("div", { staticClass: "formTab" }, [
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _c("el-autocomplete", {
              staticClass: "inline-input",
              attrs: {
                clearable: "",
                "fetch-suggestions": _vm.querySearch,
                placeholder: "车组/车牌/SIM卡/终端号/司机",
                "trigger-on-focus": false
              },
              on: { select: _vm.handleSelect },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.filterText,
                callback: function($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText"
              }
            }),
            _c(
              "el-button",
              {
                staticStyle: { margin: "0 10px" },
                attrs: {
                  size: "small",
                  disabled: _vm.searchStatus,
                  type: _vm.searchStatus ? "info" : "primary"
                },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            ),
            _c("batchPop", { on: { nodeOperation: _vm.handleContextMenu } })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "tree-box", style: _vm.setTreeBoxHeight },
        [
          _c("el-tree", {
            ref: "tree",
            staticClass: "expand-tree",
            attrs: {
              props: _vm.props,
              load: _vm.loadNode,
              lazy: "",
              data: _vm.setTree,
              "render-content": _vm.renderContent,
              "show-checkbox": "",
              "node-key": "id",
              "default-expanded-keys": _vm.defaultExpandKeys,
              "filter-node-method": _vm.filterNode,
              "auto-expand-parent": true
            },
            on: { check: _vm.handleCheck }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }