var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sendInformation" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "96px",
            "label-position": "left"
          }
        },
        [
          !_vm.isBatch
            ? _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 9 } }, [
                        _vm._v(_vm._s(_vm.data.name))
                      ]),
                      _c("el-col", { attrs: { span: 15 } }, [
                        _vm._v(
                          "终端号：" + _vm._s(_vm.formatTelNo(_vm.data.videos))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "选择驾驶员：", prop: "credentialsNos" } },
            [
              _c(
                "el-badge",
                {
                  staticClass: "item",
                  attrs: {
                    value: _vm.form.driverIds.length,
                    hidden: !_vm.form.driverIds.length
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        remote: "",
                        "collapse-tags": "",
                        placeholder: "请输入关键词",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                        "multiple-limit": 50,
                        "reserve-keyword": false,
                        "value-key": "credentialsNo"
                      },
                      on: { change: _vm.driverChange },
                      model: {
                        value: _vm.driverArr,
                        callback: function($$v) {
                          _vm.driverArr = $$v
                        },
                        expression: "driverArr"
                      }
                    },
                    _vm._l(_vm.driverList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + "(" + item.workNum + ")",
                          value: item
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "    " } },
            [
              _c("br"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("确定下发")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }