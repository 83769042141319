var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sendInformation" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          !_vm.isBatch
            ? _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm._v(_vm._s(_vm.data.name))
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _vm._v(
                          "终端号：" + _vm._s(_vm.formatTelNo(_vm.data.videos))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "消息类型", prop: "messageType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.messageType,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "messageType", $$v)
                    },
                    expression: "form.messageType"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm.$store.state.menu.nowMenuList.indexOf("文本信息下发类型") != -1
            ? _c(
                "el-form-item",
                { attrs: { label: "下发类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.issuanceType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "issuanceType", $$v)
                        },
                        expression: "form.issuanceType"
                      }
                    },
                    _vm._l(_vm.issuanceTypeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.form.issuanceType === "定时下发"
            ? _c(
                "el-form-item",
                {
                  staticClass: "daterange-box",
                  attrs: {
                    label: "下发日期",
                    prop:
                      _vm.form.issuanceType === "定时下发" ? "daterange" : ""
                  }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.form.daterange,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "daterange", $$v)
                      },
                      expression: "form.daterange"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.form.issuanceType === "定时下发"
            ? _c(
                "el-form-item",
                {
                  staticClass: "daterange-box",
                  attrs: {
                    label: "下发时间",
                    prop: _vm.form.issuanceType === "定时下发" ? "sendTime" : ""
                  }
                },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm:ss",
                      placeholder: "请选择下发时间"
                    },
                    model: {
                      value: _vm.form.sendTime,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sendTime", $$v)
                      },
                      expression: "form.sendTime"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "消息模板" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择消息模板", clearable: "" },
                  on: { change: _vm.checkModule },
                  model: {
                    value: _vm.form.module,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "module", $$v)
                    },
                    expression: "form.module"
                  }
                },
                _vm._l(_vm.messageModule, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "消息内容", prop: "code" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 4, placeholder: "请输入内容" },
                model: {
                  value: _vm.form.code,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "code", $$v)
                  },
                  expression: "form.code"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "    " } },
            [
              _c("br"),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }