var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advanced" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "adas参数设置", name: "first" } },
            [
              _c("adasP", {
                attrs: { arr: _vm.adasArr },
                on: { commit: _vm.adasLogSend }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "dsm参数设置", name: "second" } },
            [
              _c("dsmP", {
                attrs: { arr: _vm.dsmArr },
                on: { commit: _vm.dsmLogSend }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "轮胎状态监测参数设置" } },
            [
              _c("tireP", {
                attrs: { arr: _vm.tireArr },
                on: { commit: _vm.tireLogSend }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "盲区监测参数设置" } },
            [
              _c("blindP", {
                attrs: { arr: _vm.blindArr },
                on: { commit: _vm.blindLogSend }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "激烈驾驶参数设置" } },
            [
              _c("fierceP", {
                attrs: { arr: _vm.fierceArr },
                on: { commit: _vm.fierceLogSend }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }