<template>
  <div id="VidedList" style="width: 100%; height: 100%">
    <div
      v-for="(value, key) in maxVideoNum"
      :id="'VidedList' + (key + 1)"
      class="videoDiv"
      style="overflow: hidden; float: left"
      :style="{
        width: getVideoWidthHeight(key + 1).width - 1 + 'px',
        height: getVideoWidthHeight(key + 1).height + 'px'
      }"
      :key="key"
      @dbclick="cblVideoQPClick(key + 1)"
    >
      <div class="video" :id="'videoP' + (key + 1)">
        <video
          name="videoElement"
          autoplay="autoplay"
          loop="false"
          class="videoObj cover video-js"
          :id="'video' + (key + 1)"
          :width="getVideoWidthHeight(key + 1).width - 1"
          :height="getVideoWidthHeight(key + 1).height"
          style="object-fit: fill"
          @timeupdate="videoTimeUpdate(key + 1)"
        ></video>
        <p class="termileP" v-show="value.isShow">连接成功，等待设备响应...</p>
        <div
          :id="'videoScreen' + (key + 1)"
          :class="{
            selected: selectedId == key + 1,
            videoScreen: !value[key + 1]
          }"
          @click="videoScreenClick"
        >
          <span class="carVideoName"></span>
          <div
            :id="'videoPlayBtn' + (key + 1)"
            class="videoPlayBtn"
            :class="'playImg' + (key + 1)"
            v-if="value[key + 1]"
          ></div>
          <div
            class="videoInfo errInfo"
            v-if="!value.isOpen || !value.isMuted"
          ></div>
          <!-- 实时视频 控件-->
          <div class="videoCtrlBottom" v-show="value[key + 1] && !isHistory">
            <span
              class="progress"
              :style="{
                width: (getVideoWidthHeight(key + 1).width / 3) * 2 + 'px'
              }"
            >
              <i
                v-if="value.currentTime > 0"
                :class="[
                  value.isOpen ? 'el-icon-video-pause' : 'el-icon-video-play',
                  maxVideoNum.length >= 6 ? 'pause-small' : ''
                ]"
                @click="pauseVideoClick(key + 1)"
              ></i>
              <!-- <span>{{ formatTimeBySeconds(value.currentTime) }}</span> -->
              <!-- &nbsp; &nbsp; &nbsp; -->
              <span
                class="carPlate"
                :class="maxVideoNum.length >= 6 ? 'text-small' : ''"
                :title="value.plate"
                >{{ value.plate }}</span
              >
            </span>
            <span
              class="rightctr"
              :style="{ width: getVideoWidthHeight(key + 1).width / 3 + 'px' }"
            >
              <a
                :class="value.isMuted ? 'mutedVideo' : 'isMutedVideo'"
                @click="mutedVideoClick(key + 1)"
              ></a>
              <a class="closecarVideo" @click="flv_destroy(key + 1, true)"></a>
              <a class="captureVideo" @click="flv_screenshot(key + 1)"></a>
              <a class="quanpcarVideo" @click="quanpVideoClick(key + 1)"></a>
            </span>
          </div>
          <!-- 历史视频 进度条 -->
          <div
            class="videoControl"
            v-if="
              showVideoControl && value[key + 1] && historyVideo.currentTime > 0
            "
          >
            <el-slider
              class="commonSlider"
              :show-tooltip="false"
              v-model="historyVideo.currentTime"
              :max="formateTimeRound(timeRound, true)"
              @change="changeVideoTime"
            ></el-slider>
            <div class="progress">
              <div class="time">
                <i
                  :class="
                    value.isOpen ? 'el-icon-video-pause' : 'el-icon-video-play'
                  "
                  @click="pauseVideoClick(key + 1)"
                ></i>
                <span>{{ formatTimeBySeconds(historyVideo.currentTime) }}</span>
                /
                <span>{{ formateTimeRound(timeRound) }}</span>
              </div>
              <div class="rightctr">
                <a
                  :class="value.isMuted ? 'mutedVideo' : 'isMutedVideo'"
                  @click="mutedVideoClick(key + 1)"
                ></a>
                <a class="captureVideo" @click="flv_screenshot(key + 1)"></a>

                <a class="quanpcarVideo" @click="quanpVideoClick(key + 1)"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from "./flv.min.js";
import baseUrl from "@/api/static/config";
import fGetWidthHeight from "./getWidthHeight.js";
import { YHTools } from "@/assets/js/Tools.js";
import { formatDate, getCurent } from "@/common/utils/index";
import { mapGetters } from "vuex";

import TripleDES from "@/api/lib/crypto.js";
import heartbeat from "@/api/lib/heartbeat.js";

export default {
  name: "videoList",
  props: {
    buttonValue: {
      type: Number,
      default: 4
    },
    isHistory: {
      type: Boolean,
      default: false
    },
    showVideoControl: {
      type: Boolean,
      default: false
    },
    timeRound: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: "nowMenuList"
    })
  },
  data() {
    return {
      isOpen: true, // 正在播放
      isMuted: true, // 正在静音
      isShow: false, //加载提示
      isFullScreen: true, // 正在全屏
      fullscreen: false, // 全画面全屏
      videoSrc: baseUrl.videoIframeUrl, //gps和公交
      // videoSrc: sessionStorage.getItem("system")==='1'?baseUrl.taxiVideoIframeUrl:baseUrl.videoIframeUrl,//gps和公交
      maxVideoNum: [],
      playerMap: new Map(),
      stateMap: new Map(),
      playVideoList: [],
      pauseVideoMap: new Map(),
      selectedId: null, // 点击屏幕被选中
      firstLoadHistory: true,
      historyVideo: {
        drag: false, // 拖拽进度条 true拖拽中
        currentTime: 0, // 当前播放时间
        lastTime: null, // 标记时间戳
        historyTime: 0 // 拖动前的时间标记
      },
      timer_time: null, // 播放定时器
      playVideoObj: [], // 存放视频播放地址
      // videoPlaySrc: sessionStorage.getItem("system")==='1'?baseUrl.taxiVideoIframeUrlList:baseUrl.videoIframeUrlList,
      videoPlaySrc: baseUrl.videoIframeUrlList,
      playVideTimeObj: new Map(), // 存放视频播放的时间
      timer_heartbeat: null //心跳定时器
    };
  },
  mounted() {
    // this.system = sessionStorage.getItem("system")
    /** 定时关闭视频 */
    if (!this.isHistory) this.checkPlayOnTime();
    /** 监控全屏事件 */
    let self = this;
    window.onresize = function() {
      self.hanleResize();
    };
    // eslint-disable-next-line no-undef
    if (!flvjs.isSupported()) {
      this.$message.error(
        "视频播放仅对支持flv.js的浏览器适用,您所使用的浏览器不能使用视频功能,请换个浏览器试试~"
      );
    }
    /** 播放流 心跳 关闭 20-02-23*/
    // this.setVideoHeartbeat()
  },
  methods: {
    /** 暂停 | 播放 */
    pauseVideoClick(i) {
      if (this.maxVideoNum[i - 1].isOpen) {
        this.pauseVideoMap.set(this.playVideoList[i - 1], new Date());
        this.flv_pause(i);
      } else {
        if (!this.isHistory) this.flv_seekto(i);
        this.flv_pause(i);
      }
    },

    /** 静音| 取消静音 */
    mutedVideoClick(i) {
      this.flv_muted(i);
      this.maxVideoNum[i - 1].isMuted = !this.maxVideoNum[i - 1].isMuted;
    },

    /** 监控全屏事件 */
    hanleResize() {
      if (document.fullscreenElement) {
        // 进入全屏
        this.fullscreen = true;
        this.isFullScreen = false;
      } else {
        // 退出全屏
        this.fullscreen = false;
        this.isFullScreen = true;
      }
    },

    /** 全屏| 退出全屏 */
    quanpVideoClick(i) {
      let el = document.getElementById(`videoP${i}`);
      this.FullScreen(el);
    },

    /** 双击单个页面全屏 */
    cblVideoQPClick(i) {
      let el = document.getElementById(`videoP${i}`);
      this.FullScreen(el);
    },

    /** 多页面全屏 */
    holeVideoFull(i) {
      let el = document.getElementById("VidedList");
      this.FullScreen(el);
    },

    /** 全屏| 退出全屏 */
    FullScreen(el) {
      let isFullscreen =
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      if (!isFullscreen) {
        // 进入全屏,多重短路表达式
        (el.requestFullscreen && el.requestFullscreen()) ||
          (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
          (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
          (el.msRequestFullscreen && el.msRequestFullscreen());
        return true;
      } else {
        // 退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        return false;
      }
    },
    /** 显示切换，宽高重置 */
    getVideoWidthHeight(key) {
      let maxWidth = document.getElementById("VidedList").offsetWidth;
      let maxHeight = document.getElementById("VidedList").offsetHeight;
      return fGetWidthHeight(maxWidth, maxHeight, this.maxVideoNum.length, key);
    },

    /**
     *  指定界面视频播放(实时视频)
     * */

    //TODO--------
    playVideo(params) {
      this.$nextTick(function() {
        let self = this;
        params.forEach(async (item, key) => {
          let deviceUrl = `${item.device}_channel_${item.channel}`;
          const maxLen = self.playVideoList.length;
          let url = self.videoPlaySrc[item.url];
          let space = self.playVideoList.findIndex(val => val === "");
          let index = self.playVideoList.findIndex(
            value => value === deviceUrl
          );
          let last;
          if (index < 0) {
            if (space < 0) {
              self.playVideoList.push(deviceUrl);
              last = maxLen ? maxLen + 1 : 1;
            } else {
              last = space + 1;
              self.playVideoList.splice(space, 1, deviceUrl);
            }
          } else if (index > -1) {
            last = index + 1;
            self.playVideoList.splice(index, 1, deviceUrl);
          }
          console.log(item, "==========>>>>");
          await self.playload(
            last,
            `${url}${deviceUrl}`,
            `${item.plate}_摄像头${item.channel}`,
            deviceUrl
          );
        });
      });
    },
    /** 点击停止 */
    stopParticularVideo(params) {
      let self = this;
      let num = self.playVideoList.findIndex(item => item === params);
      // self.playVideoList = self.playVideoList.filter(item => item !== params);
      if (num >= 0) {
        self.flv_destroy(num + 1);
      }
    },
    /** 点击屏幕 videoScreen */
    videoScreenClick(i) {
      this.selectedId = i;
    },
    /** 指定界面视频播放(历史视频) */
    playHistoryVideo(params) {
      let self = this;
      self.playVideoList = [`${params.device}_channel_${params.channel}`];
      self.playload(
        1,
        `${self.videoSrc}${params.device}_channel_${params.channel}_playback`,
        `${params.plate}_摄像头${params.channel}`,
        `${params.device}_channel_${params.channel}`
      );
      self.firstLoadHistory = false;
      self.historyVideo.drag = false;
    },
    /**
     * 显示控制条
     */
    showControlStrip(i) {
      const index = i - 1;
      const cur = this.maxVideoNum[index];
      if (cur) {
        this.maxVideoNum.splice(index, 1, { ...cur, [i]: true });
      }
      // if (this.maxVideoNum[i - 1]) this.$set(this.maxVideoNum[i - 1], i, true);
    },
    /**
     * 隐藏控制条
     */
    hideControlStrip(i) {
      const index = i - 1;
      const cur = this.maxVideoNum[index];
      if (cur) {
        this.maxVideoNum.splice(index, 1, { ...cur, [i]: false });
      }
      // if (this.maxVideoNum[i - 1]) this.$set(this.maxVideoNum[i - 1], i, false);
    },
    playload(i, url, plate, urlKey) {
      //当前是否存在已播放的实例
      let current = this.maxVideoNum.findIndex(item => item.urlKey === urlKey);
      //存在
      let player = this.playerMap.get(i);
      if (current > -1) {
        //  player.play();
        //   this.stateMap.set(parseInt(i), true);
        return;
      }
      const index = i - 1;
      let cur = {
        ...this.maxVideoNum[index],
        isShow: true,
        urlKey,
        isOpen: true
      };

      let vid = "video" + i;
      if (this.maxVideoNum[index] && plate)
        cur = { ...cur, plate: plate.replace("摄像头", "CH") };
      this.maxVideoNum.splice(index, 1, { ...cur });

      let self = this;
      let element = document.getElementById(vid);
      const currentEle = this.playVideoList[index];
      element.addEventListener("play", function(params) {
        // 开始播放
        /** 实时播放  暂停后跳转播放 */
        if (!self.isHistory) {
          if (self.pauseVideoMap.get(currentEle)) {
            let setTime = self.pauseVideoMap.get(currentEle);
            let timeDiff = new Date().getTime() - setTime.getTime();
            element.currentTime =
              (element.currentTime + timeDiff / 1000).toFixed(2) - 1;
            self.pauseVideoMap.delete(currentEle);
          }
        }
        self.maxVideoNum[index].isOpen = true;
        self.stateMap.set(parseInt(i), true);
      });
      /** 监听暂停 */
      element.addEventListener("pause", function(params) {
        if (!self.isHistory) {
          self.pauseVideoMap.set(currentEle, new Date());
        }
        if (self.maxVideoNum[index]) self.maxVideoNum[index].isOpen = false;
        self.stateMap.set(parseInt(i), false);
      });

      if (typeof player !== "undefined") {
        if (player != null) {
          player.unload();
          player.detachMediaElement();
          player.destroy();
          player = null;
        }
      }
      // eslint-disable-next-line no-undef
      player = flvjs.createPlayer(
        {
          // isLive: true,
          enableStashBuffer: true,
          hasAudio: true,
          hasVideo: true,
          type: "flv",
          //TODO----
          // isLive: true,
          url: url
        },
        {
          enableWorker: false,
          //TODO----
          // reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
          // autoCleanupSourceBuffer: true, //自动清除缓存
          // fixAudioTimestampGap: false,
          //TODO
          lazyLoadMaxDuration: 1 * 60, // 延迟负载最大持续时间180s
          seekType: "range" // 滑块控件range
        }
      );
      this.playerMap.set(parseInt(i), player);
      this.stateMap.set(parseInt(i), true);
      player.attachMediaElement(element);
      player.load();
      player.on("error", err => {
        console.log("err====》》》》》", err);
        this.flv_destroy(i,true);
        // this.$emit("playError", currentEle);
      });

      // let wating = 0;
      // player.on("statistics_info", res => {
      //   let decodedFrames = res.decodedFrames;
      //   console.log(decodedFrames, "====>>>>", cur.plate);
      //   if (decodedFrames !== cur.lastDecodedFrame) {
      //     this.maxVideoNum[index].lastDecodedFrame = decodedFrames;
      //   } else {
      //     console.log(wating,'---------------9999')
      //     this.maxVideoNum[index].wating = wating++;
      //   }
      //   if(this.maxVideoNum[index].wating>=10){
      //     console.log(cur.plate,'======>>>>>>卡顿了10s')
      //   }
      // });

      // 显示控制条
      this.showControlStrip(i);
      setTimeout(() => {
        player
          .play()
          .then(() => {
            if (currentEle) {
              self.playVideTimeObj.set(currentEle, new Date());
            }
            const cur = { ...this.maxVideoNum[index] };
            this.maxVideoNum.splice(index, 1, { ...cur, isShow: false });
            this.$emit("showTips", false); //监听功能状态变化
            // this.$set(
            //   this.maxVideoNum[index],
            //   "isShow",
            //   false
            // );
            /**加载提示消失 */
          })
          .catch(error => {});
      }, 300);
    },

    /**
     * 播放
     */

    flv_play(i) {
      let player = this.playerMap.get(i);
      if (typeof player !== "undefined") {
        player.play();
        this.stateMap.set(parseInt(i), true);
      }
    },
    /**
     * 暂停播放
     */
    flv_pause(i) {
      let player = this.playerMap.get(i);
      if (typeof player !== "undefined") {
        let state = this.stateMap.get(i);
        if (state === true) {
          player.pause();
          this.stateMap.set(parseInt(i), false);
        } else {
          player.play();
          this.stateMap.set(parseInt(i), true);
        }
      }
    },

    /**
     * 静音处理
     */
    flv_muted(i) {
      let player = this.playerMap.get(i);
      if (player.muted) {
        player.muted = false;
      } else {
        player.muted = true;
      }
    },
    /** 关闭对应画面 */
    flv_destroy(i, flag) {
      console.log("销毁=========》", i,flag);
      let player = this.playerMap.get(i);
      const index = i - 1;
      const cur = this.playVideoList[index];
      this.playVideoObj.splice(index, 1, "");
      this.playVideoList.splice(index, 1, "");
      const VideoNumIndex = this.maxVideoNum.findIndex(
        item => item.urlKey === cur
      );

      let param = {
        [i]: false,
        currentTime: 0,
        isOpen: false,
        isMuted: true,
        plate: ""
      };
      if (typeof player !== "undefined") {
        player.pause();
        player.unload();
        player.detachMediaElement();
        player.destroy();
        player = null;
        // 隐藏并显示背景
        this.hideControlStrip(i);
        this.playerMap.delete(i);
        this.stateMap.delete(i);

        if (flag) {
          cur && this.$emit("handleCloseVideo", cur);
        }
      }
      this.maxVideoNum.splice(VideoNumIndex, 1, { ...param });
      this.playVideTimeObj.delete(cur);
    },
    /** 点击关闭全部 */
    flv_destroyAll() {
      let self = this;
      document.querySelectorAll(".videoObj").forEach(item => {
        self.flv_destroy(Number(item.id.replace("video", "")));
      });
      self.playVideoList = [];
      self.playVideoObj = [];
      self.playVideTimeObj.clear();
    },
    /** 跳转播放 */
    flv_seekto(i) {
      let player = this.playerMap.get(i);
      let setTime = this.pauseVideoMap.get(this.playVideoList[i - 1]);
      let timeDiff = new Date().getTime() - setTime.getTime();
      player.currentTime =
        (player.currentTime + timeDiff / 1000).toFixed(2) - 1;
      this.pauseVideoMap.delete(this.playVideoList[i - 1]);
    },
    /**
     * 截屏
     */
    flv_screenshot(i) {
      let scale = 1;
      let vid = "video" + i;
      let canvas = document.createElement("canvas");
      let video = document.getElementById(vid);
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      let img = document.createElement("img");
      img.crossOrigin = "anonymous";
      img.src = canvas.toDataURL("image/png");
      let image = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      this.saveFile(image, "file_" + new Date().getTime() + ".png");
    },
    /**
     * 保存图片至本地
     */
    saveFile(data, filename) {
      // eslint-disable-next-line no-undef
      let saveLink = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      saveLink.href = data;
      saveLink.download = filename;
      let event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      saveLink.dispatchEvent(event);
    },
    isIE() {
      if (!!window.ActiveXObject || "ActiveXObject" in window) return true;
      else return false;
    },
    /** 界面重渲染 */
    changeFrameWH() {
      let self = this;
      let list = [...self.maxVideoNum];
      self.maxVideoNum = [];
      self.maxVideoNum = [...list];
    },
    /*
     *更新视频时间。节流，每秒触发一次
     */
    videoTimeUpdate(key) {
      let nowTime = Date.now();
      let gapTime = 1000;
      let player = this.playerMap.get(key);
      /** 历史视频 */
      if (this.isHistory) {
        if (this.historyVideo.drag) return;
        if (
          !this.historyVideo.lastTime ||
          nowTime - this.historyVideo.lastTime > gapTime
        ) {
          if (player) {
            let time = player.currentTime;
            this.historyVideo.currentTime =
              this.historyVideo.currentTime > time
                ? parseInt(this.historyVideo.historyTime + time)
                : parseInt(time);

            if (
              this.historyVideo.currentTime ===
              this.formateTimeRound(this.timeRound, true)
            ) {
              setTimeout(() => {
                this.flv_destroy(key, true);
              }, 1000);
            }
          }
          this.historyVideo.lastTime = nowTime;
        }
      } else {
        if (player) {
          let time = player.currentTime;
          const index = key - 1;
          const cur = { ...this.maxVideoNum[index] };
          if (cur)
            this.maxVideoNum.splice(index, 1, {
              ...cur,
              currentTime: parseInt(time)
            });
          // this.$set(this.maxVideoNum[key - 1], "currentTime", parseInt(time));
        }
      }
    },
    /** 历史视频 拖拽进度条 */
    changeVideoTime(val) {
      // 改变视频时间
      if (this.firstLoadHistory || this.historyVideo.currentTime === 0) return;
      this.historyVideo.drag = true;
      this.historyVideo.historyTime = val; // 暂存之前时间
      this.historyVideo.currentTime = val;
      this.flv_destroy(1);
      this.$emit("dragChangeVideoTime", val);
    },
    /** 参数还原 */
    initParameter() {
      this.historyVideo.drag = false;
      this.historyVideo.currentTime = 0;
      this.historyVideo.historyTime = 0;
      this.firstLoadHistory = true;
      this.isOpen = true; // 正在播放
      this.isMuted = true; // 正在静音
      this.isFullScreen = true; // 正在全屏
      this.fullscreen = false; // 全画面全屏
    },
    /** 时间转换 */
    formatTimeBySeconds(time) {
      return YHTools.FormatTimeBySeconds(time)
        ? YHTools.FormatTimeBySeconds(time)
        : "00:00";
    },

    /** 时间间隔转换 */
    formateTimeRound(timeRound, flag) {
      let time = "";
      let timeDiff = "";
      let date = "";
      time = timeRound ? timeRound.split(" 至 ") : "";
      timeDiff = new Date(time[1]).getTime() - new Date(time[0]).getTime();
      if (flag) return parseInt(timeDiff / 1000);
      date = YHTools.FormatTimeBySeconds(timeDiff / 1000)
        ? YHTools.FormatTimeBySeconds(timeDiff / 1000)
        : "00:00";
      return date;
    },
    /** 多切少，关闭播放 */
    closePlayerNum(index) {
      for (let i = index; i < this.playVideoList.length; i++) {
        const element = this.playVideoList[i];
        if (element) {
          this.flv_destroy(i + 1);
        }
      }
    },
    /**视频自动停止播放 */
    checkPlayOnTime() {
      let self = this;
      let limiTime = 5; //最多观看5分钟

      let flag = this.nowMenuList.indexOf("可观看长时间实时视频") >= 0;
      if (flag) return;
      if (this.nowMenuList.indexOf("可观看") > -1) {
        //有做观看时长限制
        // let nowMenuList = this.nowMenuList
        //   .replace(/,/g, "")
        //   .replace(/分钟实时视频/g, "");
        // let timeList = nowMenuList.split("可观看");
        let nowMenuList = this.nowMenuList.split(",");
        // console.log("nowMenuList", nowMenuList);
        let allowPlayVideoList = nowMenuList.filter(item => item.indexOf("可观看") > -1);//视频时长权限数组
        let timeStr = allowPlayVideoList.join(",").replace(/,/g, "").replace(/分钟实时视频/g, "");
        let timeList = timeStr.split("可观看");
        console.log("timeList", timeList);
        timeList.shift();
        let max = timeList.reduce(function(a, b) {
          return Number(b) > Number(a) ? Number(b) : Number(a);
        });
        limiTime = max;
      }

      if (self.timer_time) {
        clearInterval(self.timer_time);
      }
      let timerFn = function() {
        if (self.playVideTimeObj.size == 0) {
          return;
        }
        self.playVideTimeObj.forEach((item, key) => {
          let time = new Date();
          let timeDiff = (time.getTime() - item.getTime()) / 60 / 1000;
          if (timeDiff >= limiTime) {
            //超过可播放时限
            let index = self.playVideoList.findIndex(value => value == key);
            if (index >= 0) self.flv_destroy(index + 1, true);
          }
        });
      };
      self.timer_time = setInterval(() => {
        timerFn();
      }, 5000);
    },
    setVideoHeartbeat() {
      let self = this;
      if (self.timer_heartbeat) {
        clearInterval(self.timer_heartbeat);
      }
      let timerFn = function() {
        if (self.playVideTimeObj.size == 0) {
          return;
        }
        let data = {
          userId: self.$store.state.user.userId,
          channels: self.playVideTimeObj.size,
          token: TripleDES.encode(`yinghairuitekeji_${getCurent()}`)
        };
        heartbeat(data).then();
      };
      self.timer_heartbeat = setInterval(() => {
        timerFn();
      }, 5000);
    }
  },
  watch: {
    buttonValue(val, oldVal) {
      let self = this;
      let maxVideoNum = [];
      for (let i = 1; i <= val; i++) {
        let element = self.maxVideoNum[i - 1];
        let flag = element ? element[i] : false;
        let currentTime = element ? element.currentTime : 0;
        let isOpen = element ? element.isOpen : false;
        let isShow = element ? element.isShow : false;
        let isMuted = element ? element.isMuted : true;
        let plate = element ? element.plate.replace("摄像头", "CH") : "";
        maxVideoNum.push({
          [i]: flag,
          currentTime: currentTime,
          isOpen: isOpen,
          isMuted: isMuted,
          isShow: isShow,
          plate: plate
        });
      }
      self.maxVideoNum = [...maxVideoNum];
    }
  },
  /** 页面销毁 清除监听 */
  beforeDestroy() {
    let self = this;
    if (!self.isHistory) clearInterval(self.timer_time);
    clearInterval(self.timer_heartbeat);
    window.removeEventListener("resize", self.hanleResize);
  }
};
</script>

<style lang="scss" scoped>
.videoList {
  border: 0px;
  position: absolute;
  overflow: hidden;
}

.videoDiv {
  overflow: hidden;
}

.videoScreen {
  position: absolute;
  z-index: 999;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: url("../../../../assets/images/video/background.png") repeat
    center;
  background-size: 100% 100%;
}

.videoPlayBtn {
  cursor: pointer;
  z-index: 1010;
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -32px; /* 高度的一半 */
  margin-left: -32px; /* 宽度的一半 */
  width: 64px; /* 要设定宽度 */
  height: 64px;
  background: no-repeat center center;
}

.video {
  border: 1px solid #333;
  width: 100%;
  height: 100%;
  position: relative;
  border-bottom: #ecf5ff;
  video {
    width: 100%;
    height: 100%;
  }
}
.videoList {
  .selected {
    border: 1px solid #ff0000;
    top: 0px;
    left: 0px;
    right: 2px;
    bottom: 2px;
  }
  .fill {
    object-fit: fill;
  }
  .cover {
    object-fit: contain;
  }
  .videoInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: 20px;
    right: 0;
    height: auto;
    line-height: normal;
    padding: 10px;
    text-align: center;
    font-weight: 200;
    color: #ffffff;
    font-size: 14px;
    z-index: 1005;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0,
      #000 0 -1px 0;
    -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
  .errInfo {
    font-weight: 300;
    color: red;
    font-size: 18px;
  }
}
/**底部控制栏 */
.videoCtrlBottom,
.videoControl {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: left;
  z-index: 1000;
  background: #303133;
  opacity: 0.8;
  font-weight: 200;
  padding: 2px 5px;
  color: #fff;

  .progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 24px;
      width: 35px;
    }
    i.pause-small {
      font-size: 12px;
      width: 20px;
    }
  }
  .rightctr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  a {
    width: 3vh;
    height: 2vh;
    padding-right: 2px;
  }
  .mutedVideo {
    background: url("../../../../assets/images/video/voice.png") no-repeat
      center;
    background-size: 70%;
  }
  .isMutedVideo {
    background: url("../../../../assets/images/video/isMuted.png") no-repeat
      center;
    background-size: 70%;
  }
  .captureVideo {
    background: url("../../../../assets/images/video/cutPicture.png") no-repeat
      center;
    background-size: 70%;
  }
  .quanpcarVideo {
    background: url("../../../../assets/images/video/fullscreen.png") no-repeat
      center;
    background-size: 70%;
  }
  .closecarVideo {
    background: url("../../../../assets/images/video/close.png") no-repeat
      center;
    background-size: 70%;
  }
}
.videoCtrlBottom {
  height: 3vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .carVideoName2 {
    float: left;
    color: #ffffff;
    font-weight: 200;
    font-size: 14px;
    height: 24px;
    line-height: 24px;
    width: auto;
    z-index: 1001;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0,
      #000 0 -1px 0;
    -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
  .carPlate {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .carPlate.text-small {
    font-size: 12px;
  }
}
.videoControl {
  height: 68px;
  .el-slider {
    padding: 0 15px;
  }
  .progress {
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.termileP {
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 10%;
  width: 100%;
  z-index: 1000;
  color: #36ad1d;
  font-size: 16px;
}

.videoScreen .carVideoName {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  font-weight: 300;
  font-size: 18px;
  height: 24px;
  line-height: 24px;
  width: auto;
  z-index: 1001;
  text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
}
</style>
<style>
#VidedList video::-webkit-media-controls-enclosure {
  visibility: hidden;
}
</style>
