export default {
  operationList: [
    // 部标设备指令
    {
      name: "点名",
      index: "deviceCall",
      type: "order",
      width: "",
      actionKey: "终端指令"
    },
    {
      name: "文本信息下发",
      index: "sendInformation",
      type: "",
      width: "30%",
      actionKey: "终端指令"
    },
    {
      name: "设置运营线路",
      index: "setOperationLine",
      type: "newPage",
      width: "30%",
      actionKey: "终端指令"
    },
    {
      name: "查询终端参数",
      index: "QueryParameter",
      type: "order",
      width: "",
      actionKey: "终端参数查询"
    },
    {
      name: "查询终端属性",
      index: "QueryAttributes",
      type: "newPage",
      width: "50%",
      actionKey: "终端参数查询"
    },
    {
      name: "设置终端参数",
      index: "terminalParameter",
      type: "newPage",
      width: "60%",
      actionKey: "终端指令"
    },
    {
      name: "设置服务器参数",
      index: "terminalIp",
      type: "newPage",
      width: "60%",
      actionKey: "终端指令"
    },
    {
      name: "设置出租车服务器参数",
      index: "terminalTaxiIp",
      type: "newPage",
      width: "50%",
      actionKey: "终端指令"
    },

    {
      name: "终端升级",
      index: "terminalAttributes",
      type: "newPage",
      width: "50%",
      actionKey: "终端指令"
    },
    {
      name: "设置IC卡参数",
      index: "ICardset",
      type: "",
      width: "30%",
      actionKey: "终端指令"
    },
    {
      name: "查看驾驶员",
      index: "driverManage",
      type: "page",
      width: "",
      actionKey: "车辆属性"
    },
    //出租车绑定司机信息
    {
      name: "下发驾驶员",
      index: "sendDrivers",
      type: "",
      width: "30%",
      actionKey: "车辆属性"
    },
    {
      name: "下线时限配置",
      index: "carOfflineTime",
      type: "",
      width: "30%",
      actionKey: "车辆属性"
    },
    {
      name: "客运车辆禁行设置",
      index: "carDrivingHibit",
      type: "",
      width: "40%",
      actionKey: "车辆属性"
    },
    {
      name: "修改车辆信息",
      index: "addVehicle",
      type: "",
      width: "70%",
      actionKey: "车辆属性"
    },
    {
      name: "屏蔽报警",
      index: "setAlarmShield",
      type: "newPage",
      width: "50%",
      actionKey: "终端指令"
    },
    {
      name: "关闭报警声音",
      index: "closeVoice",
      type: "newPage",
      width: "50%",
      actionKey: "终端指令"
    },
    {
      name: "计价器加锁",
      index: "computedLock",
      type: "order",
      width: "50%",
      actionKey: "终端指令"
    },
    {
      name: "计价器解锁",
      index: "computedUnlock",
      type: "order",
      width: "50%",
      actionKey: "终端指令"
    },
    {
      name: "查询路网状态图",
      index: "roadNetworkState",
      type: "",
      width: "70%",
      actionKey: "终端参数查询"
    },
    {
      name: "查询路网状态图2",
      index: "roadNetworkState2",
      type: "",
      width: "70%",
      actionKey: "终端参数查询"
    },
    {
      name: "清除区域设置",
      index: "clearLocaleSetting",
      type: "clearLocale",
      width: "",
      actionKey: "终端指令"
    },
    {
      name: "实时拍照",
      index: "realtimePictures",
      type: "newPage",
      width: "63%",
      actionKey: "终端指令"
    },
    {
      name: "云台控制",
      index: "holderControl",
      type: "",
      width: "30%",
      actionKey: "视频设置"
    },
    {
      name: "人工解除报警",
      index: "removeAlarm",
      type: "newPage",
      width: "40%",
      actionKey: "终端指令"
    },
    {
      name: "载重限高配置",
      index: "highLoadLimit",
      type: "newPage",
      width: "62%",
      actionKey: "主防报警配置"
    },
    {
      name: "设置音视频参数",
      index: "setAudioAndVideo",
      type: "newPage",
      width: "62%",
      actionKey: "视频设置"
    },
    {
      name: "实时监听",
      index: "listening",
      type: "newPage",
      width: "20%",
      actionKey: "视频设置"
    },
    {
      name: "高级参数设置",
      index: "advancedParameters",
      type: "newPage",
      width: "68%",
      actionKey: "终端指令"
    },
    {
      name: "区域围栏设置",
      index: "areaAlarmSetting",
      type: "newPage",
      width: "60%",
      actionKey: "终端指令"
    },
    {
      name: "查询车辆行驶记录",
      index: "queryCarDrivingRecords",
      type: "newPage",
      width: "30%",
      actionKey: "终端指令"
    },
    {
      name: "清除登退签",
      index: "clearLog",
      type: "order",
      width: "30%",
      actionKey: "终端指令"
    },
  ]
};
