<template>
  <div class="sendInformation">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <!-- 已选车辆终端 -->
      <el-form-item label="车牌号：" v-if="!isBatch">
        <el-row>
          <el-col :span="12">{{ data.name }}</el-col>
          <el-col :span="12">终端号：{{ formatTelNo(data.videos) }}</el-col>
        </el-row>
      </el-form-item>
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        v-if="isBatch"
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        v-if="isBatch"
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>

      <el-form-item label="消息类型" prop="messageType">
        <el-select v-model="form.messageType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="$store.state.menu.nowMenuList.indexOf('文本信息下发类型') != -1"
        label="下发类型"
      >
        <el-select v-model="form.issuanceType" placeholder="请选择">
          <el-option
            v-for="item in issuanceTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="form.issuanceType === '定时下发'"
        label="下发日期"
        class="daterange-box"
        :prop="form.issuanceType === '定时下发' ? 'daterange' : ''"
      >
        <el-date-picker
          v-model="form.daterange"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item
        v-if="form.issuanceType === '定时下发'"
        label="下发时间"
        class="daterange-box"
        :prop="form.issuanceType === '定时下发' ? 'sendTime' : ''"
      >
        <el-time-picker
          v-model="form.sendTime"
          value-format="HH:mm:ss"
          placeholder="请选择下发时间"
        >
        </el-time-picker>
      </el-form-item>

      <el-form-item label="消息模板">
        <el-select
          v-model="form.module"
          placeholder="请选择消息模板"
          clearable
          @change="checkModule"
        >
          <el-option
            v-for="(item, key) in messageModule"
            :key="key"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 紧急下发 -->
      <el-form-item label="消息内容" prop="code">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="form.code"
        ></el-input>
      </el-form-item>
      <el-form-item label="    ">
        <br />
        <el-button type="primary" size="small" @click="save()">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  queryDeviceByIds,
  saveMessageTaskAPI
} from "@/api/lib/refreshAlarm.js";
import { queryMessageTemplateList } from "@/api/lib/api.js";

import carTree from "@/components/carTree/carSearchTree.vue";
import companySelectForSearch from "@/components/treeSelect/companySelectForSearch.vue";
import { returnVideoArr } from "@/common/utils/index";

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /** 是否批量操作 **/
    isBatch: {
      type: Boolean,
      default: false
    }
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // }
  },

  data() {
    return {
      deviceType: "", // 协议类型

      terminalNos: [],
      vehicleInfos: [],
      form: {
        messageType: "终端TTS播读",
        issuanceType: "立即下发",
        daterange: null,
        sendTime: null,
        vehIds: [],
        companyIds: [],
        textarea: "",
        code: "",
        module: ""
      },
      messageModule: [],
      options: [
        {
          value: "紧急",
          label: "紧急"
        },
        {
          value: "终端TTS播读",
          label: "终端TTS播读"
        }
      ],
      issuanceTypeOptions: [
        {
          value: "立即下发",
          label: "立即下发"
        },
        {
          value: "定时下发",
          label: "定时下发"
        }
      ],
      formRules: {
        companyIds: [
          {
            required: true,
            type: "array",
            message: "请选择公司/车队",
            trigger: "change"
          }
        ],
        vehIds: [
          {
            required: true,
            type: "array",
            message: "请选择车辆",
            trigger: "change"
          }
        ],

        messageType: [
          {
            required: true,
            message: "请选择消息类型",
            trigger: "blur"
          }
        ],

        daterange: [
          {
            required: true,
            message: "请选择下发日期",
            trigger: "change"
          }
        ],

        sendTime: [
          {
            required: true,
            message: "请选择下发时间",
            trigger: "change"
          }
        ],

        code: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    "form.issuanceType"(val) {
      this.form.daterange = null;
      this.form.sendTime = null;
    }
  },
  methods: {
    // 获取车组数据
    getGroupIds(val) {
      this.$refs.form.clearValidate();
      const valStr = val.join(",");
      this.form.companyIds = val.length === 0 ? [] : valStr.split(",");
    },
    // 获取车辆数据
    getVehIds(val) {
      this.$refs.form.clearValidate();
      const vArr = [];
      for (const v of val.values()) {
        vArr.push(`v${v}`);
      }
      this.form.vehIds = vArr;
    },

    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.terminalNos = [];
          this.vehicleInfos = [];
          let ids = this.form.companyIds.concat(this.form.vehIds);
          const res = await queryDeviceByIds({
            ids: ids.join(","),
            system: this.deviceType
          });
          if (res.code === 1000) {
            if (res.data.length === 0) {
              this.$message.warning("车辆没有绑定终端");
              return;
            }
            res.data.forEach(element => {
              let deviceNo = returnVideoArr(element.videos, true);
              deviceNo && this.terminalNos.push(deviceNo);
              this.vehicleInfos.push(`${element.cph}#${element.companyName}`);
            });
            if (this.form.issuanceType === "立即下发") {
              const sDispatchType = this.form.messageType;
              const sText = this.form.code;
              this.$yhsdp.GBSendMessage(
                [this.terminalNos, this.vehicleInfos],
                sDispatchType,
                sText,
                this.deviceType
              );
              this.$emit("close");
            } else {
              const {
                messageType,
                code,
                companyIds,
                daterange,
                sendTime
              } = this.form;
              let params = {
                sign: messageType,
                message: code,
                terminalPhoneNos: this.terminalNos,
                companyIds: companyIds,
                deviceType: this.deviceType == 2 ? 0 : this.deviceType,
                beginTime: daterange[0] + " 00:00:00",
                endTime: daterange[1] + " 23:59:59",
                sendTime: "2000-01-01 " + sendTime,
                status: 0
              };
              saveMessageTaskAPI(params).then(res => {
                if (res.code === 1000 && res.data) {
                  this.$emit("close");
                } else {
                  this.$message.warning(res.msg);
                }
              });
            }
          }
        }
      });
    },
    checkModule(id) {
      if (id) {
        let element = this.messageModule.find(item => item.id === id);
        if (element) this.form.code = element.detail;
      } else {
        this.form.code = "";
      }
    },
    //获取telno
    formatTelNo(videos) {
      return videos ? returnVideoArr(videos, true) : "";
    },
    getMessageList() {
      queryMessageTemplateList().then(res => {
        if (res.code === 1000) {
          this.messageModule = res.data;
        } else {
          this.$message({
            type: "error",
            showClose: true,
            message: res.msg
          });
        }
      });
    }
  },
  created() {
    this.getMessageList();
  },
  mounted() {
    /** 单个车辆的数据 */
    if (
      JSON.stringify(this.data) !== "{}" &&
      JSON.stringify(this.data) !== "null"
    ) {
      this.form.vehIds = [this.data.id];
    }
    this.deviceType = sessionStorage.getItem("system");
  }
};
</script>
<style lang="scss" scoped>
.sendInformation {
  .daterange-box {
    .el-input__inner {
      width: 100%;
    }
  }
  @include themify() {
    .el-row {
      .el-col {
        line-height: $base-input-height;
      }
    }
  }
}
</style>
