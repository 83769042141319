<template>
  <div class="terminal-upgrade-bath">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="82px"
      label-position="left"
      class="demo-form dialog-form dialog-form-inline"
    >
      <!-- 公司车队： -->
      <el-form-item
        label="公司车队："
        :prop="form.vehIds.length === 0 ? 'companyIds' : ''"
      >
        <companySelectForSearch
          ref="companySelectForSearch"
          :multiple="true"
          :searchable="true"
          @getValue="getGroupIds"
        />
      </el-form-item>
      <!-- 选择车辆： -->
      <el-form-item
        label="选择车辆："
        :prop="form.companyIds.length === 0 ? 'vehIds' : ''"
      >
        <car-tree @getData="getVehIds" label="id"></car-tree>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="queryAttributes"
          >查询终端属性</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      id="outputTable"
      :data="tableData"
      class="no-blank"
      height="30vh"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" label="序号" width="60"></el-table-column>

      <el-table-column
        prop="vehicleNo"
        label="车牌号"
        :width="90 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="device"
        label="终端号"
        :width="100 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="terminalID"
        label="终端ID"
        :width="80 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="terminalModel"
        label="终端型号"
        :width="100 * screenCoefficient"
        :show-overflow-tooltip="true"
        :filters="terminalFilters"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      ></el-table-column>
      <el-table-column
        prop="hardwareVersion"
        label="硬件版本号"
        :width="120 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="softwareVersion"
        label="固件版本号"
        :width="160 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="SIM_ICCID"
        label="终端SIM_ICCID"
        :width="160 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>

    <el-form
      ref="addForm"
      :model="addForm"
      :rules="rules"
      label-width="102px"
      label-position="right"
      class="demo-form dialog-form dialog-form-inline"
    >
      <!-- 选择升级包： -->
      <el-form-item label="选择升级包：" prop="path">
        <el-select v-model="addForm.path" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.path"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="生产厂家：" prop="type">
        <el-select v-model="addForm.type" placeholder="请选择">
          <el-option label="通立" :value="1"> </el-option>
          <el-option label="通联" :value="2"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="10px">
        <el-tooltip
          class="item"
          effect="dark"
          content="只支持通立和通联，其他产品请联系工作人员"
          placement="top-start"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button
        @click="getXLS"
        type="primary"
        size="small"
        :disabled="tableData.length === 0"
        >导出</el-button
      >
      <el-button type="primary" size="small" @click="onSave">确认</el-button>
    </div>
  </div>
</template>

<script>
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import { queryUpgradeList, queryFtpAccount } from '@/api/lib/api.js'
import { mapGetters } from 'vuex'
import { getCurent, returnVideoArr } from '@/common/utils/index.js'
import getXLS from '@/common/utils/exportXLS'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // },
    /** 终端升级||查询终端属性 */
    type: {
      type: Number,
      default: 1
    },
  },

  data () {
    return {
      deviceType: '', // 协议类型
      terminalNos: [],
      vehicleInfos: [],
      form: {
        vehIds: [],
        companyIds: []
      },
      addForm: {},
      rules: {
        path: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ],
        type: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ]
      },
      formRules: {
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      },
      options: [],
      upterminalNos: [],
      upvehicleInfos: []
    }
  },
  computed: {
    ...mapGetters({
      tableData: 'terminalPropertiesList',
      terminalFilters: 'terminalFilters',
      terminalHardFilters: 'terminalHardFilters',
      terminalSoftFilters: 'terminalSoftFilters',
      screenCoefficient: 'screenCoefficient'
    }),

  },

  methods: {
    /** 导出 */
    getXLS () {
      getXLS('#outputTable', `终端属性-${getCurent()}`)
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      const valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      const vArr = []
      for (const v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },
    //多选
    handleSelectionChange (row) {
      const terminalNos = []
      const vehicleInfos = []
      row.forEach((item) => {
        terminalNos.push(item.device)
        vehicleInfos.push(item.vehicleInfos)
      })
      this.upterminalNos = terminalNos
      this.upvehicleInfos = vehicleInfos
    },
    //查询终端属性
    queryAttributes () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const terminalNos = []
          const vehicleInfos = []
          let ids = this.form.companyIds.concat(this.form.vehIds)

          queryDeviceByIds({
            ids: ids.join(','),
            system: this.deviceType
          }).then((res) => {
            if (res.code === 1000) {
              if (res.data.length > 0) {
                res.data.forEach((item) => {
                  let deviceNo = returnVideoArr(item.videos, true)
                  terminalNos.push(deviceNo)
                  vehicleInfos.push(`${item.cph}#${item.companyName}`)
                })
                this.terminalNos = terminalNos
                this.vehicleInfos = vehicleInfos
                // if (this.terminalNos.length > this.number) {
                //   this.$message.warning(
                //     `最多只能对${this.number}个终端批量下发指令`
                //   )
                //   return
                // }
                if (
                  this.$store.state.vehicle.terminalObj &&
                  this.$store.state.vehicle.terminalObj.list.length > 0
                ) {
                  this.$message.warning(
                    `正在查询数据，请等待此次结果返回后再试`
                  )
                  return
                }
                let param = {
                  list: this.terminalNos,
                  vehicleInfos: this.vehicleInfos,
                  time: new Date()
                }
                this.$yhsdp.GBQueryAttributes(
                  [this.terminalNos, this.vehicleInfos],
                  this.deviceType
                )
                this.$yhsdp.RefreshDataBack(
                  this.$RefreshTypeEnum.GB_TerminalInterval
                )
                this.$store.dispatch('set_terminalObj', param)
              } else {
                this.$message.warning('车辆没有绑定终端')
              }
            }
          })
        }
      })
    },
    /**下发指令终端升级 */
    onSave () {
      if (this.upterminalNos.length === 0) {
        this.$message.warning('请选择要升级的终端')
        return
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let { ip, user, pwd, path, port } = this.addForm
          let parmas =
            this.addForm.type === 1
              ? [
                `ftp://${ip}${path}`,
                'APN',
                user,
                pwd,
                ip,
                port,
                '',
                '',
                '',
                '',
                '0'
              ]
              : [ip, '', user, pwd, path, port, '', '', '', '', '0']
          this.$yhsdp.GBTermainalUpdate(
            [this.upterminalNos, this.upvehicleInfos],
            parmas,
            this.deviceType
          )

          this.$store.dispatch('clear_terminalPropertiesList') //清空查询终端属性数据
          this.$store.dispatch('set_terminalObj', null) //清空查询终端属性数据
          this.$emit('close')
        }
      })
    },
    //获取数据
    getListByField () {
      queryUpgradeList({upgradeType: 808}).then((res) => {
        if (res.code === 1000) {
          this.options = res.data
        } else {
          this.options = []
        }
      })
    },
    //获取ftp数据
    queryFtpAccount () {
      queryFtpAccount().then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }
        }
      })
    },
    cancle () {
      this.$emit('cancle')
    },
    filterTag (value, row) {
      this.$refs.table.clearSelection()
      this.upterminalNos = []
      return row.terminalModel === value;
    },
    filterHard (value, row) {
      this.$refs.table.clearSelection()
      this.upterminalNos = []
      return row.hardwareVersion === value;
    },
    filterSoft (value, row) {
      this.$refs.table.clearSelection()
      this.upterminalNos = []
      return row.softwareVersion === value;
    }
  },
  created () {
    this.getListByField()
    this.queryFtpAccount()
  },
  mounted () {
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminal-upgrade-bath {
  @include themify() {
    .footer {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
<style >
.el-table-filter__content {
  /* max-width: 200px !important; 
  overflow: hidden;*/
}
</style>
