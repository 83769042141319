var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "terminal-parameter" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form terminal-form",
          attrs: {
            model: _vm.form,
            inline: true,
            size: "small",
            rules: _vm.formRules
          }
        },
        [
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "车牌号：" } }, [
                _vm._v(" " + _vm._s(_vm.data.name) + " ")
              ])
            : _vm._e(),
          !_vm.isBatch
            ? _c("el-form-item", { attrs: { label: "终端号：" } }, [
                _vm._v(" " + _vm._s(_vm.formatTelNo(_vm.data.videos)) + " ")
              ])
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司车队：",
                    prop: _vm.form.vehIds.length === 0 ? "companyIds" : ""
                  }
                },
                [
                  _c("companySelectForSearch", {
                    ref: "companySelectForSearch",
                    attrs: { multiple: true, searchable: true },
                    on: { getValue: _vm.getGroupIds }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBatch
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择车辆：",
                    prop: _vm.form.companyIds.length === 0 ? "vehIds" : ""
                  }
                },
                [
                  _c("car-tree", {
                    attrs: { label: "id" },
                    on: { getData: _vm.getVehIds }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 超速报警设置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.saveInput(2)
                }
              }
            },
            [_vm._v("发送指令")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "defalut", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.saveInput(1)
                }
              }
            },
            [_vm._v("发送指令(新川标)")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm2",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm2,
            inline: true,
            rules: _vm.formRules,
            "label-width": "130px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "最高速度(km/h)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm2.speedLimit,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "speedLimit", $$v)
                  },
                  expression: "addForm2.speedLimit"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预警提前量(km/h)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  placeholder: "请输入",
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.addForm2.preSpeeding,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "preSpeeding", $$v)
                  },
                  expression: "addForm2.preSpeeding"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "夜间限速比例(%)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  placeholder: "请输入",
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.addForm2.nightSpeedLimitPer,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "nightSpeedLimitPer", $$v)
                  },
                  expression: "addForm2.nightSpeedLimitPer"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "超速持续时间(s)：" } },
            [
              _c("el-input-number", {
                attrs: {
                  placeholder: "请输入",
                  "controls-position": "right",
                  min: 0,
                  disabled: ""
                },
                model: {
                  value: _vm.addForm2.time,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "time", $$v)
                  },
                  expression: "addForm2.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "白天时间段：", prop: "period" } },
            [
              _c("el-time-picker", {
                attrs: {
                  "is-range": "",
                  "range-separator": "至",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  placeholder: "选择时间范围",
                  format: "HH:mm"
                },
                model: {
                  value: _vm.addForm2.period,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "period", $$v)
                  },
                  expression: "addForm2.period"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "新川标：夜间限速比例需要下发至终端",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" 疲劳驾驶报警设置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.saveInput(3)
                }
              }
            },
            [_vm._v("发送指令")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "defalut", size: "mini" },
              on: { click: _vm.nomalValue }
            },
            [_vm._v("恢复设置")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm3",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm3,
            inline: true,
            "label-width": "130px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否执行疲劳" } },
            [
              _c("el-checkbox", {
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.addForm3.fatigue,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm3, "fatigue", $$v)
                  },
                  expression: "addForm3.fatigue"
                }
              })
            ],
            1
          ),
          _vm.addForm3.fatigue ? _c("el-form-item") : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                { attrs: { label: "预警提前量(分钟)：" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入",
                      min: 0,
                      disabled: "",
                      "controls-position": "right"
                    },
                    model: {
                      value: _vm.addForm3.preFatigue,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "preFatigue", $$v)
                      },
                      expression: "addForm3.preFatigue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                { attrs: { label: "最小休息(分钟)：" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入",
                      min: 0,
                      disabled: "",
                      "controls-position": "right"
                    },
                    model: {
                      value: _vm.addForm3.minSleepTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "minSleepTime", $$v)
                      },
                      expression: "addForm3.minSleepTime"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                { attrs: { label: "是否单次疲劳" } },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.handleCheckedChange },
                    model: {
                      value: _vm.addForm3.onceFatigue,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "onceFatigue", $$v)
                      },
                      expression: "addForm3.onceFatigue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm3.fatigue ? _c("el-form-item") : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                { attrs: { label: "连续驾驶(分钟)：" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入",
                      "controls-position": "right",
                      min: 1,
                      disabled: "",
                      max: 255
                    },
                    model: {
                      value: _vm.addForm3.continueDriveTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "continueDriveTime", $$v)
                      },
                      expression: "addForm3.continueDriveTime"
                    }
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "最小为1分钟,最大为4小时15分钟(255分钟)",
                        placement: "top-start"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.addForm3.fatigue ? _c("el-form-item") : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                { attrs: { label: "是否累计疲劳" } },
                [
                  _c("el-checkbox", {
                    on: { change: _vm.handleCheckedChange },
                    model: {
                      value: _vm.addForm3.totalFatigue,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "totalFatigue", $$v)
                      },
                      expression: "addForm3.totalFatigue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.addForm3.fatigue ? _c("el-form-item") : _vm._e(),
          _vm.addForm3.fatigue
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "当天累计驾驶(分钟)：",
                    "label-width": "140px"
                  }
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入",
                      min: 480,
                      max: 1440,
                      "controls-position": "right"
                    },
                    model: {
                      value: _vm.addForm3.driverTotalTime,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "driverTotalTime", $$v)
                      },
                      expression: "addForm3.driverTotalTime"
                    }
                  }),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "1-1440",
                        placement: "top-start"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }