<template>
  <el-row class="terminalParameter">
    <el-form
      ref="form"
      :model="form"
      label-width="210px"
      size="small"
      :rules="formRules"
      class="operationForm"
    >
      <el-col :span="24" v-if="isBatch">
        <h4>选择车辆</h4>
      </el-col>
      <!-- 公司车队： -->
      <el-col :md="12" :lg="12" :xl="8" v-if="isBatch">
        <el-form-item label="公司车队：" :prop="form.vehIds.length===0 ? 'companyIds':''">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="true"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
      </el-col>

      <!-- 选择车辆： -->
      <el-col :md="12" :lg="12" :xl="8" v-if="isBatch">
        <el-form-item label="选择车辆：" v-if="isBatch" :prop="form.companyIds.length===0 ? 'vehIds':''">
          <car-tree @getData="getVehIds"></car-tree>
        </el-form-item>
      </el-col>
      <!------------------------------------主服务器配置------------------------------------------------------------------->
      <el-col :span="24">
        <h4>主服务器配置</h4>
      </el-col>
      <!-- 主服务器IP地址： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="主服务器IP地址：" prop="mainServerIP">
          <el-input v-model.trim="form.mainServerIP"></el-input>
        </el-form-item>
      </el-col>
      <!-- 主服务器TCP端口号： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="主服务器TCP端口号：" prop="mainServerTcp">
          <el-input v-model.trim="form.mainServerTcp"></el-input>
        </el-form-item>
      </el-col>

      <!------------------------------------备份服务器配置------------------------------------------------------------------->
      <el-col :span="24">
        <h4>备份服务器配置</h4>
      </el-col>
      <!-- 备份服务器IP地址： -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="备份服务器IP地址：" prop="backupServerIP">
          <el-input v-model.trim="form.backupServerIP"></el-input>
        </el-form-item>
      </el-col>
      <!--备份服务器TCP端口号：  -->
      <el-col :md="12" :lg="12" :xl="12">
        <el-form-item label="备份服务器TCP端口号：" prop="backupServerTcp">
          <el-input v-model.trim="form.backupServerTcp"></el-input>
        </el-form-item>
      </el-col>
     
      <el-col :md="24" :lg="24" :xl="12">
        <el-form-item>
          <el-button type="primary" size="mini" @click="saveInput">保存</el-button>
        </el-form-item>
      </el-col>
    </el-form>
  </el-row>
</template>
<script>
import { setDeviceParams, queryDeviceParams } from '@/api/lib/api'
import { returnVideoArr,checkString, checkIpAddress, checkPositiveInteger } from '@/common/utils/index'
import { queryDeviceByIds } from '@/api/lib/refreshAlarm.js'
import carTree from '@/components/carTree/carSearchTree.vue'
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue'

export default {
  components: {
    carTree,
    companySelectForSearch
  },
  props: {
    /** 对单个车下发指令  被选中的车辆id */
    // id: {
    //   type: String
    // },
    /** 对单个车下发指令  被选中的车辆data */
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /** 是否批量操作 */
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 最多只能对多少个车下发指令 (一次性最多下发300条 young 20200430) */
    // number: {
    //   type: Number,
    //   default: 300
    // }
  },

  data () {
    return {
      terminalNos: [],
      deviceType:'',
      form: {
        vehIds: [],
        companyIds: [],
        mainServerIP: '', // 主服务器IP地址：
        mainServerTcp: '', // 主服务器TCP端口号：
        backupServerIP: '', // 备份服务器IP地址：
        backupServerTcp: '', // 备份服务器TCP端口号：
      },
      formRules: {
        //mainServerIP: [{ validator: checkIpAddress, trigger: 'blur' }], // 主IP或域名验证
        //backupServerIP: [{ validator: checkIpAddress, trigger: 'blur' }], // 备份IP或域名验证
        backupServerTcp: [{ validator: checkPositiveInteger, trigger: 'blur' }], // 备份服务器TCP端口设置
        companyIds: [
          {
            required: true,
            type: 'array',
            message: '请选择公司/车队',
            trigger: 'change'
          }
        ],
        vehIds: [
          {
            required: true,
            type: 'array',
            message: '请选择车辆',
            trigger: 'change'
          }
        ]
      },
      AlarmPromptOption: ['mainServerIP', 'mainServerTcp', 'backupServerIP', 'backupServerTcp']
    }
  },

  methods: {
    /** 单个车辆的数据 */
    getData (vehStrIds) {
      this.terminalNos = []
      this.form.vehIds = vehStrIds
       if (vehStrIds.length > 0)
        this.queryDeviceParams(this.formatTelNo(this.data.videos)) // 单个车辆做查询
    },

    /** 清除整个表单的校验 */
    clearValidate () {
      this.$refs.form.clearValidate()
    },

    /** 获取已存的提示内容 */
    queryDeviceParams (data) {
      queryDeviceParams(data).then(res => {
        if (res.code === 1000) {
          this.form = { ...this.form, ...res.data }
        }
      })
    },
    // 获取车组数据
    getGroupIds (val) {
      this.$refs.form.clearValidate()
      let valStr = val.join(',')
      this.form.companyIds = val.length === 0 ? [] : valStr.split(',')
    },
    // 获取车辆数据
    getVehIds (val) {
      this.$refs.form.clearValidate()
      let vArr = []
      for (let v of val.values()) {
        vArr.push(`v${v}`)
      }
      this.form.vehIds = vArr
    },

    /** 所有input的提交 */
    saveInput () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let parameter = 0
          this.AlarmPromptOption.forEach((item) => {
            if (
              this.form[item] === null ||
              this.form[item] === undefined ||
              this.form[item] === 'undefined' ||
              this.form[item] === ''
            ) {
              parameter = parameter + 1
            }
          })
          if (parameter === this.AlarmPromptOption.length) {
            this.$message.warning('请至少下发一个指令')
            return
          }
          const terminalNos = []
          const vehicleInfos = []
          let ids = this.form.companyIds.concat(this.form.vehIds)

          queryDeviceByIds({
            ids: ids.join(','),
            system: this.deviceType
          }).then((res) => {
            if (res.code === 1000) {
              if (res.data.length === 0) {
                this.$message.warning('车辆没有绑定终端')
                return
              }
              res.data.forEach((element) => {
                let deviceNo = returnVideoArr(element.videos, true)
                terminalNos.push(deviceNo)
                vehicleInfos.push(`${element.cph}#${element.companyName}`)
              })
              this.terminalNos = terminalNos
              this.vehicleInfos = vehicleInfos

              // if (this.terminalNos.length > this.number) {
              //   this.$message.warning(
              //     `最多只能对${this.number}个终端批量下发指令`
              //   )
              // }
              const params = []
              
              const {
                mainServerIP,
                mainServerTcp,
                backupServerIP,
                backupServerTcp,
              } = this.form
              const reqData = {
                deviceNos: terminalNos, // 下发的终端
                terminalParams: {mainServerIP,
                mainServerTcp,
                backupServerIP,
                backupServerTcp,} // 报警提示类型
              }
              setDeviceParams(reqData).then((res) => { })
              if (mainServerIP) params.push(0x0013, mainServerIP)
              if (mainServerTcp) params.push(0x0018, mainServerTcp)
              if (backupServerIP) params.push(0x0017, backupServerIP)
              if (backupServerTcp) params.push(0x0019, backupServerTcp)
              this.$yhsdp.GBSetParameters(
                [terminalNos, this.vehicleInfos],
                params,
                this.deviceType
              )
              this.$emit('close')
            }
          })
        }
      })
    },
    //获取telno
    formatTelNo (videos) {
      return videos ? returnVideoArr(videos, true) : ''
    }
  },
  mounted () {
    if (
      JSON.stringify(this.data) !== '{}' &&
      JSON.stringify(this.data) !== 'null'
    ) {
      this.getData([this.data.id])
    }
    this.deviceType = sessionStorage.getItem('system')
  }
}
</script>
<style lang="scss" scoped>
.terminalParameter {
  .operationForm {
    .hint {
      text-indent: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.el-input-group--append .el-input__inner {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
</style>
