var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "adas"
    },
    [
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _vm._v(" adas报警配置 "),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.commit }
            },
            [_vm._v("发送指令")]
          )
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form terminal-form",
          attrs: {
            model: _vm.addForm,
            inline: true,
            "label-width": "160px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "报警判断速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.alarmSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "alarmSpeed", $$v)
                  },
                  expression: "addForm.alarmSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "仅适用于道路偏离、前向碰撞、车距过近和频繁变更车道报警",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警提示音量：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 8,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.tipsVoice,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "tipsVoice", $$v)
                  },
                  expression: "addForm.tipsVoice"
                }
              }),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0-8，数值越大，音量越大",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主动拍照策略：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.takePicture,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "takePicture", $$v)
                    },
                    expression: "addForm.takePicture"
                  }
                },
                _vm._l(_vm.takeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主动定时拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 3600,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.takeTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "takeTime", $$v)
                  },
                  expression: "addForm.takeTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "主动拍照策略定时拍照时需要填写",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "主动定距拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60000,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.takeDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "takeDistance", $$v)
                  },
                  expression: "addForm.takeDistance"
                }
              }),
              _vm._v("米 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "主动拍照策略定距拍照时需要填写",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单次主动拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.takeNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "takeNum", $$v)
                  },
                  expression: "addForm.takeNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单次主动拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.takeinterval,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "takeinterval", $$v)
                  },
                  expression: "addForm.takeinterval"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "拍照分辨率：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.takeRatio,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "takeRatio", $$v)
                    },
                    expression: "addForm.takeRatio"
                  }
                },
                _vm._l(_vm.ratioList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "该参数也适用于报警触发拍照分辨率",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频录制分辨率：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.takevideoRatio,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "takevideoRatio", $$v)
                    },
                    expression: "addForm.takevideoRatio"
                  }
                },
                _vm._l(_vm.resolutionRatioList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "该参数也适用于报警触发视频分辨率",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.blockLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "blockLeve1", $$v)
                  },
                  expression: "addForm.blockLeve1"
                }
              }),
              _vm._v("障碍物一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.blockLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "blockLeve2", $$v)
                  },
                  expression: "addForm.blockLeve2"
                }
              }),
              _vm._v("障碍物二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "障碍物报警距离阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 50,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.distance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "distance", $$v)
                  },
                  expression: "addForm.distance"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.levelSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "levelSpeed", $$v)
                  },
                  expression: "addForm.levelSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.videoTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "videoTime", $$v)
                  },
                  expression: "addForm.videoTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "障碍物报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.alarmPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "alarmPictrueNum", $$v)
                  },
                  expression: "addForm.alarmPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "障碍物报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.alarmPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "alarmPictrueTime", $$v)
                  },
                  expression: "addForm.alarmPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.freLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "freLeve1", $$v)
                  },
                  expression: "addForm.freLeve1"
                }
              }),
              _vm._v("频繁变更车道一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.freLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "freLeve2", $$v)
                  },
                  expression: "addForm.freLeve2"
                }
              }),
              _vm._v("频繁变更车道二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "频繁变道报警判断周期：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 30,
                  max: 120,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.frePeriod,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "frePeriod", $$v)
                  },
                  expression: "addForm.frePeriod"
                }
              }),
              _vm._v("秒 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "频繁变道报警判断次数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 3,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.freNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "freNum", $$v)
                  },
                  expression: "addForm.freNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.freSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "freSpeed", $$v)
                  },
                  expression: "addForm.freSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.freTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "freTime", $$v)
                  },
                  expression: "addForm.freTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "频繁变道报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.frePictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "frePictrueNum", $$v)
                  },
                  expression: "addForm.frePictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "频繁变道报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.frePictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "frePictrueTime", $$v)
                  },
                  expression: "addForm.frePictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.awayFromLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "awayFromLeve1", $$v)
                  },
                  expression: "addForm.awayFromLeve1"
                }
              }),
              _vm._v("车道偏离一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.awayFromLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "awayFromLeve2", $$v)
                  },
                  expression: "addForm.awayFromLeve2"
                }
              }),
              _vm._v("车道偏离二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.awayFromSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "awayFromSpeed", $$v)
                  },
                  expression: "addForm.awayFromSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.awayFromVideoTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "awayFromVideoTime", $$v)
                  },
                  expression: "addForm.awayFromVideoTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车道偏离报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.afPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "afPictrueNum", $$v)
                  },
                  expression: "addForm.afPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "障碍物报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.afPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "afPictrueTime", $$v)
                  },
                  expression: "addForm.afPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.baLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "baLeve1", $$v)
                  },
                  expression: "addForm.baLeve1"
                }
              }),
              _vm._v("前向碰撞一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.baLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "baLeve2", $$v)
                  },
                  expression: "addForm.baLeve2"
                }
              }),
              _vm._v("前向碰撞二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "前向碰撞报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 50,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.abTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "abTime", $$v)
                  },
                  expression: "addForm.abTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.abSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "abSpeed", $$v)
                  },
                  expression: "addForm.abSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.abVideoTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "abVideoTime", $$v)
                  },
                  expression: "addForm.abVideoTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " 前向碰撞报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.abPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "abPictrueNum", $$v)
                  },
                  expression: "addForm.abPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "前向碰撞报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.abPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "abPictrueTime", $$v)
                  },
                  expression: "addForm.abPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.pLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pLeve1", $$v)
                  },
                  expression: "addForm.pLeve1"
                }
              }),
              _vm._v("行人碰撞一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.pLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pLeve2", $$v)
                  },
                  expression: "addForm.pLeve2"
                }
              }),
              _vm._v("行人碰撞二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行人碰撞报警时间阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 50,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pTime", $$v)
                  },
                  expression: "addForm.pTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pSpeed", $$v)
                  },
                  expression: "addForm.pSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pVideoTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pVideoTime", $$v)
                  },
                  expression: "addForm.pVideoTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行人碰撞报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pPictrueNum", $$v)
                  },
                  expression: "addForm.pPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行人碰撞报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.pPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "pPictrueTime", $$v)
                  },
                  expression: "addForm.pPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.carLeve1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carLeve1", $$v)
                  },
                  expression: "addForm.carLeve1"
                }
              }),
              _vm._v("车距过近一级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.carLeve2,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carLeve2", $$v)
                  },
                  expression: "addForm.carLeve2"
                }
              }),
              _vm._v("车距过近二级报警 ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车距监控报警距离阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 10,
                  max: 50,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.carDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carDistance", $$v)
                  },
                  expression: "addForm.carDistance"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警分级速度阈值：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 220,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.carSpeed,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carSpeed", $$v)
                  },
                  expression: "addForm.carSpeed"
                }
              }),
              _vm._v("km/h "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "高于设置值为二级，否则为一级",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报警前后视频时间：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 60,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.carVideoTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carVideoTime", $$v)
                  },
                  expression: "addForm.carVideoTime"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不录像",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车距过近报警拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.carPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carPictrueNum", $$v)
                  },
                  expression: "addForm.carPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车距过近报警拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.carPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "carPictrueTime", $$v)
                  },
                  expression: "addForm.carPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: " " } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.addForm.roadLeve,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "roadLeve", $$v)
                  },
                  expression: "addForm.roadLeve"
                }
              }),
              _vm._v("道路标识超速报警 ")
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: " " } }),
          _c(
            "el-form-item",
            { attrs: { label: "道路标志识别拍照张数：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 0,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.roadPictrueNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "roadPictrueNum", $$v)
                  },
                  expression: "addForm.roadPictrueNum"
                }
              }),
              _vm._v("秒 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "0 表示不抓拍",
                    placement: "top-start"
                  }
                },
                [_c("i", { staticClass: "el-icon-warning" })]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "道路标志识别拍照间隔：" } },
            [
              _c("el-input-number", {
                attrs: {
                  "controls-position": "right",
                  min: 1,
                  max: 10,
                  placeholder: "请输入"
                },
                model: {
                  value: _vm.addForm.roadPictrueTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "roadPictrueTime", $$v)
                  },
                  expression: "addForm.roadPictrueTime"
                }
              }),
              _vm._v("100ms ")
            ],
            1
          ),
          _vm.arr && _vm.arr.length > 45
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: " " } },
                  [
                    _c("el-switch", {
                      model: {
                        value: _vm.addForm.turnLeve,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnLeve", $$v)
                        },
                        expression: "addForm.turnLeve"
                      }
                    }),
                    _vm._v("碰撞侧翻报警 ")
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: " " } }),
                _c(
                  "el-form-item",
                  { attrs: { label: "碰撞侧翻报警时间阈值：" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        "controls-position": "right",
                        min: 10,
                        max: 50,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.addForm.turnTime,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnTime", $$v)
                        },
                        expression: "addForm.turnTime"
                      }
                    }),
                    _vm._v("100ms ")
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "侧翻报警使能速度阈值：" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        "controls-position": "right",
                        min: 0,
                        max: 220,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.addForm.turnSpeed,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnSpeed", $$v)
                        },
                        expression: "addForm.turnSpeed"
                      }
                    }),
                    _vm._v("km/h "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "高于设置值为二级，否则为一级",
                          placement: "top-start"
                        }
                      },
                      [_c("i", { staticClass: "el-icon-warning" })]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "侧翻报警前后视频时间：" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        "controls-position": "right",
                        min: 0,
                        max: 60,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.addForm.turnVideoTime,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnVideoTime", $$v)
                        },
                        expression: "addForm.turnVideoTime"
                      }
                    }),
                    _vm._v("秒 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "0 表示不录像",
                          placement: "top-start"
                        }
                      },
                      [_c("i", { staticClass: "el-icon-warning" })]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "碰撞侧翻报警拍照张数：" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        "controls-position": "right",
                        min: 0,
                        max: 10,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.addForm.turnPictrueNum,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnPictrueNum", $$v)
                        },
                        expression: "addForm.turnPictrueNum"
                      }
                    }),
                    _vm._v("秒 "),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "0 表示不抓拍",
                          placement: "top-start"
                        }
                      },
                      [_c("i", { staticClass: "el-icon-warning" })]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "碰撞侧翻报警拍照间隔：" } },
                  [
                    _c("el-input-number", {
                      attrs: {
                        "controls-position": "right",
                        min: 1,
                        max: 10,
                        placeholder: "请输入"
                      },
                      model: {
                        value: _vm.addForm.turnPictrueTime,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "turnPictrueTime", $$v)
                        },
                        expression: "addForm.turnPictrueTime"
                      }
                    }),
                    _vm._v("100ms ")
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }