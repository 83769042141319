var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "batchPop" },
    [
      _vm.dropdownMenu.length > 0
        ? _c(
            "el-dropdown",
            { on: { command: _vm.toOperationPage } },
            [
              _c("i", {
                staticClass: "operationMore iconfont its_icon_more",
                attrs: { title: "批量管理" }
              }),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "carTreeDrop",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                _vm._l(_vm.dropdownMenu, function(item, key) {
                  return _c(
                    "el-dropdown-item",
                    { key: key, attrs: { command: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.showDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                title: _vm.title,
                visible: _vm.showDialog,
                width:
                  _vm.title.indexOf("文本信息下发") > -1 ||
                  _vm.title.indexOf("点名") > -1 ||
                  _vm.title.indexOf("禁行设置") > -1
                    ? "30%"
                    : "50%",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.showDialog = $event
                },
                close: _vm.close
              }
            },
            [
              _vm.title.indexOf("点名") > -1
                ? _c("rollCall", {
                    ref: "rollCall",
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("点名") > -1
                ? _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default", size: "small" },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.onSave }
                        },
                        [_vm._v("确定下发")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.title.indexOf("文本信息") > -1
                ? _c("send-information", {
                    attrs: { isBatch: true },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("设置终端参数") > -1 && _vm.system != 1
                ? _c("terminal-parameter", {
                    attrs: { isBatch: true },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("设置终端参数") > -1 && _vm.system == 1
                ? _c("terminal-parameter-taxi", {
                    attrs: { isBatch: true },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("设置服务器参数") > -1
                ? _c("terminal-ip", {
                    attrs: { isBatch: true },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("终端升级") > -1 ||
              _vm.title.indexOf("终端属性") > -1
                ? _c("terminal-upgrade", {
                    attrs: { type: _vm.title.indexOf("终端升级") > -1 ? 1 : 2 },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e(),
              _vm.title.indexOf("批量禁行设置") > -1
                ? _c("carDrivingHibit", {
                    attrs: { isBatch: true },
                    on: { close: _vm.handleCloseDialog }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }