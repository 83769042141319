<template>
  <div class="blind" v-loading="loading">
    <p class="form-title form-title2">
      激烈驾驶报警配置
      <el-button type="primary" size="mini" @click="commit">发送指令</el-button>
    </p>
    <el-form
      :model="addForm"
      :inline="true"
      ref="addForm"
      label-width="190px"
      label-position="left"
      class="demo-form-inline dialog-form terminal-form"
    >
      <el-form-item label=" ">
        <el-switch v-model="addForm.turn0"></el-switch>急加速报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="急加速报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.upTime"
        :min="1"
        :max="10"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="急加速报警重力加速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.mgSpeed"
          :min="1"
          :max="100"
          placeholder="请输入"
        ></el-input-number>1/100g
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn1"></el-switch>急减速报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="急减速报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.downTime"
        :min="1"
        :max="10"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="急减速报警重力加速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.downSpeed"
          :min="1"
          :max="100"
          placeholder="请输入"
        ></el-input-number>1/100g
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn2"></el-switch> 急转弯报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="急转弯报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.tTime"
        :min="1"
        :max="10"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="急转弯报警重力加速度阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.tSpeed"
          :min="1"
          :max="100"
          placeholder="请输入"
        ></el-input-number>1/100g
      </el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn3"></el-switch> 怠速报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="怠速报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.dTime"
        :min="1"
        :max="600"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="怠速报警车速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.dSpeed"
          :min="1"
          :max="30"
          placeholder="请输入"
        ></el-input-number>km/h
      </el-form-item>
      <el-form-item label="怠速报警发动机转速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.dAlarm"
          :min="1"
          :max="2000"
          placeholder="请输入"
        ></el-input-number>RPM
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn4"></el-switch> 异常熄火报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="异常熄火报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.xTime"
        :min="1"
        :max="30"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="异常熄火报警车速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.xSpeed"
          :min="10"
          :max="200"
          placeholder="请输入"
        ></el-input-number>km/h
      </el-form-item>
      <el-form-item label="异常熄火报警发动机转速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.xAlarm"
          :min="1"
          :max="1000"
          placeholder="请输入"
        ></el-input-number>RPM
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn5"></el-switch> 空挡滑行报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="空挡滑行报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.kTime"
        :min="1"
        :max="30"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="空挡滑行报警车速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.kSpeed"
          :min="10"
          :max="200"
          placeholder="请输入"
        ></el-input-number>km/h
      </el-form-item>
      <el-form-item label="空挡滑行报警发动机转速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.kAlarm"
          :min="1"
          :max="2000"
          placeholder="请输入"
        ></el-input-number>RPM
      </el-form-item>
      <el-form-item label=" "></el-form-item>

      <el-form-item label=" ">
        <el-switch v-model="addForm.turn6"></el-switch> 发动机超转报警
      </el-form-item>
      <el-form-item label=" "></el-form-item>
      <el-form-item label="发动机超转报警时间阈值：">
      <el-input-number
        controls-position="right"
        v-model="addForm.fTime"
        :min="1"
        :max="60"
        placeholder="请输入"
      ></el-input-number>秒
    </el-form-item>
    <el-form-item label="发动机超转报警车速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.fSpeed"
          :min="10"
          :max="200"
          placeholder="请输入"
        ></el-input-number>km/h
      </el-form-item>
      <el-form-item label="报警发动机转速阈值：">
        <el-input-number
          controls-position="right"
          v-model="addForm.fAlarm"
          :min="1000"
          :max="6000"
          placeholder="请输入"
        ></el-input-number>RPM
      </el-form-item>
      <el-form-item label=" "></el-form-item>


    </el-form>
    


  </div>
</template>
<script>
export default {
  props: {
    arr: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data () {
    return {
      loading: false,//加载画面
      addForm: {
        turn0: true,
        upTime:0,
        mgSpeed:0,
        turn1: true,
        downTime:0,
        downSpeed:0,
        turn2: true,
        tTime:0,
        tSpeed:0,
        turn3: true,
        dTime:0,
        dSpeed:0,
        dAlarm:0,
        turn4: true,
        xTime:0,
        xSpeed:0,
        xAlarm:0,
        turn5: true,
        kTime:0,
        kSpeed:0,
        kAlarm:0,
        turn6: true,
        fTime:0,
        fSpeed:0,
        fAlarm:0,
      },
    }
  },
  methods: {
    commit () {
      const {
        turn0,
        upTime,
        mgSpeed,
        turn1,
        downTime,
        downSpeed,
        turn2,
        tTime,
        tSpeed,
        turn3,
        dTime,
        dSpeed,
        dAlarm,
        turn4,
        xTime,
        xSpeed,
        xAlarm,
        turn5,
        kTime,
        kSpeed,
        kAlarm,
        turn6,
        fTime,
        fSpeed,
        fAlarm
      } = this.addForm
      const singleArr = [
        turn0?1:0,
        turn1?1:0,
        turn2?1:0,
        turn3?1:0,
        turn4?1:0,
        turn5?1:0,
        turn6?1:0,
        0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0,0,0,0,0,0,
        0,0,0,0,0
      ];//32位
      let singleStr = parseInt(singleArr.reverse().join(''),2);
      const resultData = [
        singleStr,
        upTime,
        mgSpeed,
        0,//3预留位
        downTime,
        downSpeed,
        0,//6预留位
        tTime,
        tSpeed,
        0,//9预留位
        dTime,
        dSpeed,
        dAlarm,
        xTime,
        xSpeed,
        xAlarm,
        kTime,
        kSpeed,
        kAlarm,
        fTime,
        fSpeed,
        fAlarm,
        0
      ]
      let resultObj = [...this.arr];
      resultData.map((item, index) => {
        resultObj[index].value = item;
      })
      this.$emit('commit', resultObj)
    },
    //数据处理
    computerObj (arr) {
      //二进制转换-报警使能
      let bfArr = Number(arr[0].value);
      let bit2 = bfArr.toString(2).split('').reverse();
      let l1 = bit2.length;
      bit2.length = 32;
      let bit32 = bit2.fill('0', l1, 32);
      //二进制转换-报警使能
      this.addForm = {
        turn0: bit32[0] == 1 ? true : false,
        upTime: arr[1].value,
        mgSpeed:arr[2].value,
        turn1: bit32[1] == 1 ? true : false,
        downTime:arr[4].value,
        downSpeed:arr[5].value,
        turn2: bit32[2] == 1 ? true : false,
        tTime:arr[7].value,
        tSpeed:arr[8].value,
        turn3: bit32[3] == 1 ? true : false,
        dTime:arr[10].value,
        dSpeed:arr[11].value,
        dAlarm:arr[12].value,
        turn4: bit32[4] == 1 ? true : false,
        xTime:arr[13].value,
        xSpeed:arr[14].value,
        xAlarm:arr[15].value,
        turn5: bit32[5] == 1 ? true : false,
        kTime:arr[16].value,
        kSpeed:arr[17].value,
        kAlarm:arr[18].value,
        turn6: bit32[6] == 1 ? true : false,
        fTime:arr[19].value,
        fSpeed:arr[20].value,
        fAlarm:arr[21].value,
      }
      this.loading = false
    }
  },
  mounted () {
    if (this.arr && this.arr.length > 0) {
      this.loading = true;
      this.computerObj(this.arr)
    }
  }
}
</script>
<style lang="scss" scoped>
.blind {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .el-button {
        margin-left: 20px;
      }
    }
    .el-form-item__content {
      i {
        margin-left: 2px;
      }
    }
  }
}
</style>